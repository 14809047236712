import { render, staticRenderFns } from "./ContactDetailsView.vue?vue&type=template&id=d8e97e52&scoped=true"
import script from "./ContactDetailsView.vue?vue&type=script&lang=js"
export * from "./ContactDetailsView.vue?vue&type=script&lang=js"
import style0 from "./ContactDetailsView.vue?vue&type=style&index=0&id=d8e97e52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8e97e52",
  null
  
)

export default component.exports