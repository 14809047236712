<template>
  <b-modal
    id="template-modal"
    ref="template-modal"
    :title="templateEdit === null ? $t('message.settings.template.' + (source === 'ticketTemplate' ? 'createTicketTemplate' : source === 'letterTemplate' ? 'createLetterTemplate' : source === 'deepSignTemplate' ? 'textTemplate' : 'createTemplate')) : $t('message.settings.template.editTemplate')"
    hide-footer
    no-close-on-backdrop
    @close="hide()">
    <div class="row">
      <div class="col-sm-12 col-md-12 mb-2">
        <coozzy-form-input
          v-model="template.name"
          :disabled="disabledDeleteBtn"
          :limit="255"
          :name="$t('message.settings.template.name') + '*'"
          :state="$v.template.name.$dirty && $v.template.name.$error ? false : null"
          class="mb-2" />
      </div>
      <div
        v-if="source !== 'ticketTemplate' && source !== 'deepSignTemplate'"
        class="col-sm-12 col-md-12 mb-2">
        <coozzy-form-input
          v-model="template.subject"
          :disabled="disabledDeleteBtn"
          :limit="255"
          :name="$t('message.settings.template.subject') + '*'"
          :state="$v.template.subject.$dirty && $v.template.subject.$error ? false : null"
          class="mb-2" />
      </div>
      <div
        v-if="source === 'ticketTemplate'"
        class="col-12 mb-2">
        <label>{{ $t('message.generic.placeholder') }}</label>
        <p class="ml-2 mb-0">
          <span>#anrede# <br></span>
          <span>#eigentuemer# <br></span>
        </p>
      </div>
      <div class="col-sm-12 col-md-12 mb-2">
        <label for="message">{{
            source === 'letterTemplate' ? $t('message.tableColumnHeadings.letter') : $t('message.settings.template.message')
          }}*</label>
        <editor
          v-if="source === 'emailTemplate'"
          :key="keyRefresh"
          ref="editor-tiny-2"
          v-model="template.message"
          :api-key="editorApi"
          :init="{
            toolbar: 'undo redo | bold italic underline forecolor | fontsize | numlist bullist link table | fullscreen print',
            plugins: 'fullscreen lists code image table link',
            height: '150',
            menubar: '',
            language: currentLanguage,
            elementpath: false,
            invalid_styles: {
              '*': 'font-family',
            },
            automatic_uploads: true,
            license_key: 'gpl',
            content_style: contentStyle,
            font_formats: fontFormats,
            branding: false,
            remove_trailing_brs: false,
            browser_spellcheck: true,
            table_toolbar: '',
            font_size_formats: fontsizeFormats,
            convert_urls: false
          }" />
        <coozzy-form-textarea
          v-else
          v-model="template.message"
          :initial="template.message"
          :rows="15"
          :show-label="false"
          :text="template.message"
          :value-text-area="template.message" />
        <template
          v-if="source === 'emailTemplate'">
          <h5 class="mt-1">
            {{ $t('message.settings.availablePlaceholders') }}
          </h5>
          <ul>
            <li
              v-for="placeholder in availablePlaceholders"
              :key="placeholder">
              {{ placeholder }}
            </li>
          </ul>
        </template>
      </div>
      <div
        v-if="source === 'emailTemplate'"
        class="col-sm-12 col-md-12">
        <label for="file">{{ $t('message.generic.attachments') }}</label>
        <coozzy-form-file-input
          id="file"
          ref="file-input"
          accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
          @change="addFile" />
        <div class="row">
          <div
            v-for="media in mediasList"
            :key="media.id"
            class="media-block col">
            <div
              v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
              :style="{ 'background-image': 'url(' + media.url + ')' }"
              class="uploaded-file my-2">
              <coozzy-button
                class="delete-icon"
                design="transparent"
                @click="deleteMedia(media.id)">
                <coozzy-delete-icon />
              </coozzy-button>
            </div>
            <div
              v-else
              class="file-block p-1 my-2">
              <a
                :href="media.url"
                data-v-09485260=""
                target="_blank">
                {{ media.filename }}
              </a>
              <coozzy-button
                class="delete-icon"
                design="transparent"
                @click="deleteMedia(media.id)">
                <coozzy-delete-icon />
              </coozzy-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col mt-3">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="$bvModal.hide('template-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="templateEdit === null"
          :disabled="disabledDeleteBtn"
          class="float-right mb-0"
          design="green"
          size="small"
          @click="newTemplateMessage">
          {{ $t('message.generic.create') }}
        </coozzy-button>
        <coozzy-button
          v-else
          :disabled="disabledDeleteBtn"
          class="float-right mb-0"
          design="green"
          size="small"
          @click="newTemplateMessage">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import MediaApi from '../../misc/apis/MediaApi'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormFileInput from '../../framework/components/form/fileInput/CoozzyFormFileInput'
import Editor from '@tinymce/tinymce-vue'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'

export default {
  name: 'TemplateModal',
  components: {
    CoozzyDeleteIcon,
    CoozzyFormTextarea,
    CoozzyFormFileInput,
    CoozzyButton,
    CoozzyFormInput,
    Editor
  },
  mixins: [user, media],
  props: {
    templateEdit: {
      type: Object,
      default: null
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disabledDeleteBtn: false,
      template: {
        name: '',
        subject: '',
        message: '',
        attachmentMediaIds: []
      },
      availablePlaceholders: [
        '#anrede#',
        '#obj.str#',
        '#obj.nr#',
        '#obj.plz#',
        '#obj.ort#',
        '#obj.zimmer#',
        '#obj.etage#'
      ],
      mediaUpload: null,
      mediasList: [],
      keyRefresh: 0
    }
  },
  computed: {
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Lato, sans-serif'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}` : defaultFontFamily
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily}; font-size: ${fontSize};} p {margin: 0}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family}, sans-serif;` : defaultFont
      return `${userFont}`
    },
    editorApi() {
      return 'gpl'
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'template-modal') {
        this.$nextTick(function () {
          this.keyRefresh += 1
          if (this.$refs['editor-tiny-2']) {
            setTimeout(() => {
              this.updateEditorContentStyle()
            }, 500) // Add delay to ensure editor is fully initialized
          }
        })
      }
    })
    if (this.templateEdit !== null) {
      this.template = JSON.parse(JSON.stringify(this.templateEdit))
      if (this.template.attachmentMediaIds.length !== 0) {
        for (let index = 0; index < this.template.attachmentMediaIds.length; index++) {
          MediaApi.getMediaByID(this.template.attachmentMediaIds[index])
            .then(response => {
              this.mediasList.push(response.media)
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
            })
        }
      } else {
        this.mediasList = []
      }
    } else {
      this.template = {
        name: '',
        subject: '',
        message: '',
        attachmentMediaIds: []
      }
      this.mediasList = []
    }
  },
  methods: {
    updateEditorContentStyle() {
      if (this.$refs['editor-tiny-2']) {
        const editor = this.$refs['editor-tiny-2'].editor
        if (editor) {
          const content = editor.getContent()
          editor.getBody().style.fontFamily = this.fontSetting.family || 'Lato'
          editor.getBody().style.fontSize = (this.fontSetting.size || 20) + 'pt'
          editor.setContent(content)
        }
      }
    },
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.mediaUpload = files[0]
        await this.sendFile()
      }
      this.$nextTick(() => {
        if (this.$refs['file-input']) {
          this.$refs['file-input'].resetPlaceholder()
        }
      })
    },
    async sendFile() {
      const selectedFile = this.mediaUpload ? [this.mediaUpload] : []
      this.uploadProcessing = true
      const response = await this.uploadMedia(selectedFile)
      if (response.length > 0) {
        const media = response[0]
        this.mediasList.push(media)
        this.template.attachmentMediaIds.push(media.id)
        this.mediaUpload = null
      }
      this.uploadProcessing = false
    },
    newTemplateMessage() {
      this.$v.template.$touch()
      if (!this.$v.template.$invalid) {
        this.disabledDeleteBtn = true
        if (this.templateEdit !== null) {
          MessageApi.updateTemplate(
            this.template,
            this.template.attachmentMediaIds
          ).then(() => {
            this.hide()
            this.disabledDeleteBtn = false
            Vue.toasted.show(this.$t('message.settings.template.templateSavedSuccessfully'), { type: 'success' })
            this.$emit('change-success')
            this.$v.$reset()
          }).catch(e => {
            this.disabledDeleteBtn = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.template'), { type: 'error' })
          })
        } else {
          MessageApi.createTemplate(
            this.$store.getters['user/getAccountId'],
            this.template,
            this.template.attachmentMediaIds,
            this.source === 'letterTemplate' ? 'TEMPLATE_TYPE_LETTER' : this.source === 'ticketTemplate' ? 'TEMPLATE_TYPE_TICKET' : this.source === 'deepSignTemplate' ? 'TEMPLATE_TYPE_DEEP_SIGN' : 'TEMPLATE_TYPE_EMAIL'
          ).then(() => {
            this.$v.$reset()
            this.hide()
            this.disabledDeleteBtn = false
            Vue.toasted.show(this.$t('message.settings.template.templateSavedSuccessfully'), { type: 'success' })
            this.$emit('change-success')
          }).catch(e => {
            this.disabledDeleteBtn = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.template'), { type: 'error' })
          })
        }
      }
    },
    deleteMedia(id) {
      if (this.template.attachmentMediaIds) {
        this.template.attachmentMediaIds = this.template.attachmentMediaIds.filter(e => e !== id)
      }
      if (this.mediasList) {
        this.mediasList = this.mediasList.filter(el => el.id !== id)
      }
    },
    show() {
      this.keyRefresh += 1
      this.$refs['template-modal'].show()
      this.keyRefresh += 1
    },
    hide() {
      this.template = {
        name: '',
        subject: '',
        message: ''
      }
      this.mediasList = []
      this.$emit('on-close')
      this.keyRefresh += 1
      this.$refs['template-modal'].hide()
      this.keyRefresh += 1
    }
  },
  validations() {
    const validation = {
      template: {
        name: {
          required
        },
        message: {
          required
        }
      }
    }
    if (this.source !== 'ticketTemplate' && this.source !== 'deepSignTemplate') {
      validation.template.subject = {
        required
      }
    }
    return validation
  }
}
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    min-height: 7em;
    background: $color-bg-grey-dark;

    a {
      color: $color-text-grey;
      overflow-wrap: break-word;
    }
  }
}

:deep(.modal-dialog) {
  max-width: 1200px !important;
}

</style>
