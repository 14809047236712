// @ts-nocheck
import network from './network'
import networkTS from '@/misc/apis/networkTS'

function prepareTypes(contact) {
  if (!contact.types || contact.types.length === 0) {
    return ['NONE']
  } else if (contact.types[0].value !== undefined) {
    return contact.types.map(type => type.value)
  } else {
    return contact.types
  }
}
function prepareIndustries(contact) {
  if (!contact.industries || contact.industries.length === 0) {
    return ['INDUSTRY_UNDEFINED_VALUE']
  } else if (contact.industries[0].value !== undefined) {
    return contact.industries.map(industrie => industrie.value)
  } else {
    return contact.industries
  }
}

function preparePhoneNumbers(contact) {
  const phoneNumbers = []
  if (contact.phone && contact.phone.home1 !== null && contact.phone.home1 !== '') {
    phoneNumbers.push({
      number: contact.phone.home1,
      type: 'HOME'
    })
  }
  if (contact.phone && contact.phone.home2 !== null && contact.phone.home2 !== '') {
    phoneNumbers.push({
      number: contact.phone.home2,
      type: 'HOME'
    })
  }
  if (contact.phone && contact.phone.work !== null && contact.phone.work !== '') {
    phoneNumbers.push({
      number: contact.phone.work,
      type: 'WORK'
    })
  }
  if (contact.phone && contact.phone.mobile !== null && contact.phone.mobile !== '') {
    phoneNumbers.push({
      number: contact.phone.mobile,
      type: 'MOBILE'
    })
  }
  if (contact.phone && contact.phone.fax !== null && contact.phone.fax !== '') {
    phoneNumbers.push({
      number: contact.phone.fax,
      type: 'FAX'
    })
  }

  return phoneNumbers
}

export default {
  getContacts(accountId) {
    return network.get(`/web/v2/contact/list/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  listEmployees(accountId) {
    return network.get(`/web/v2/contact/listEmployees/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  publishUpdatedContactEvents(ownerIds) {
    return network.post('/web/v2/contact/publishUpdatedEvents', {
      ownerIds: ownerIds
    })
      .then(response => {
        return response.data
      })
  },
  checkDuplicates(emails, firstName, isCompany, name, ownerId, phoneNumbers) {
    console.log(emails, firstName, isCompany, name, ownerId, phoneNumbers)
    return network.post('/web/v2/contact/checkDuplicates', {
      emails: emails,
      firstName: firstName,
      ownerId: ownerId,
      isCompany: isCompany,
      name: name,
      phoneNumbers: phoneNumbers
    })
      .then(response => {
        return response.data
      })
  },
  copyContacts(sourceAccountId, targetTargetId) {
    return network.post('/web/v2/contact/copy', {
      sourceAccountId: sourceAccountId,
      targetTargetId: targetTargetId
    })
      .then(response => {
        return response.data
      })
  },
  contactResolve(contactIds) {
    return networkTS.contactServiceResolve({ contactIds: contactIds })
      .then(response => {
        return response.data
      })
  },
  employeeResolve(ownerId, emails) {
    return network.post('/web/v2/contact/resolveEmployees', {
      ownerId: ownerId,
      emails: emails
    })
      .then(response => {
        return response.data
      })
  },
  resolveByMail(ownerId, emails) {
    return network.post('/web/v2/contact/resolveByMail/' + ownerId, {
      emails: emails
    })
      .then(response => {
        return response.data
      })
  },
  getOwnerContact(accountId) {
    return network.get('/web/v2/contact/getOwnerContact/' + accountId)
      .then(response => {
        return response.data
      })
  },
  linkPersons(incomingContactId, outgoingContactId) {
    return network.post('/web/v2/contact/linkPersons', {
      relations: [{
        incomingContactId: incomingContactId,
        outgoingContactId: outgoingContactId
      }]
    })
      .then(response => {
        return response.data
      })
  },
  unLinkPersons(incomingContactId, outgoingContactId) {
    return network.post('/web/v2/contact/unLinkPersons', {
      relations: [{
        incomingContactId: incomingContactId,
        outgoingContactId: outgoingContactId
      }]
    })
      .then(response => {
        return response.data
      })
  },
  linkCompanies(incomingContactId, outgoingContactId) {
    return network.post('/web/v2/contact/linkCompanies', {
      relations: [{
        incomingContactId: incomingContactId,
        outgoingContactId: outgoingContactId
      }]
    })
      .then(response => {
        return response.data
      })
  },
  unLinkCompanies(incomingContactId, outgoingContactId) {
    return network.post('/web/v2/contact/unLinkCompanies', {
      relations: [{
        incomingContactId: incomingContactId,
        outgoingContactId: outgoingContactId
      }]
    })
      .then(response => {
        return response.data
      })
  },
  addContact(newContact, accountId, contactType) {
    const types = prepareTypes(newContact)
    const industries = prepareIndustries(newContact)
    const phoneNumbers = preparePhoneNumbers(newContact)

    if (contactType === 'company') {
      return network.post('/web/v2/contact/createCompany', {
        ownerId: accountId,
        types: types,
        email: newContact.email,
        name: newContact.name,
        title: newContact.title,
        note: newContact.note,
        address: newContact.address,
        industries: industries,
        phoneNumbers: phoneNumbers,
        documentIds: [],
        website: newContact.website,
        vatNumber: newContact.vatNumber,
        preferredCommunicationChannel: newContact.preferredCommunicationChannel,
        salutationName: newContact.salutationName,
        tags: newContact.tags,
        preferredLanguage: newContact.preferredLanguage,
        includeInSerialEmails: newContact.includeInSerialEmails,
        portalAccess: newContact.portalAccess
      }).then(response => {
        return response.data
      })
    } else {
      return network.post('/web/v2/contact/createPerson', {
        ownerId: accountId,
        types: types,
        email: newContact.email,
        title: newContact.title,
        note: newContact.note,
        gender: newContact.gender,
        firstName: newContact.firstName,
        lastName: newContact.lastName,
        birthName: newContact.birthName,
        employment: newContact.employment,
        industries: industries,
        address: newContact.address,
        ahv: newContact.ahv,
        phoneNumbers: phoneNumbers,
        dateOfBirth: newContact.dateOfBirth,
        dateOfDeath: newContact.dateOfDeath,
        website: newContact.website,
        language: newContact.language,
        birthplace: newContact.birthplace,
        civilStatus: newContact.civilStatus,
        nationality: newContact.nationality,
        residenceStatus: newContact.residenceStatus,
        preferredCommunicationChannel: newContact.preferredCommunicationChannel,
        salutationName: newContact.salutationName,
        tags: newContact.tags,
        residenceStatusValidUntil: newContact.residenceStatusValidUntil,
        preferredLanguage: newContact.preferredLanguage,
        workEmail: newContact.workEmail
      }).then(response => {
        return response.data
      })
    }
  },
  editContact(contact) {
    const types = prepareTypes(contact)
    const phoneNumbers = preparePhoneNumbers(contact)
    const industries = prepareIndustries(contact)

    if (contact.contactPersons) {
      return network.post(`/web/v2/contact/${contact.id}/updateCompany`, {
        ownerId: contact.ownerId,
        types: types,
        email: contact.email,
        title: contact.title,
        note: contact.note,
        name: contact.name,
        address: contact.address,
        industries: industries,
        phoneNumbers: phoneNumbers,
        documentIds: [],
        website: contact.website,
        preferredCommunicationChannel: contact.preferredCommunicationChannel,
        salutationName: contact.salutationName,
        tags: contact.tags,
        vatNumber: contact.vatNumber,
        preferredLanguage: contact.preferredLanguage,
        includeInSerialEmails: contact.includeInSerialEmails,
        portalAccess: contact.portalAccess
      }).then(response => {
        return response.data
      })
    } else {
      return network.post(`/web/v2/contact/${contact.id}/updatePerson`, {
        ownerId: contact.ownerId,
        types: types,
        email: contact.email,
        title: contact.title,
        note: contact.note,
        gender: contact.gender,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.name,
        birthName: contact.birthName,
        employment: contact.employment,
        industries: industries,
        address: contact.address,
        phoneNumbers: phoneNumbers,
        dateOfDeath: contact.dateOfDeath,
        dateOfBirth: contact.dateOfBirth,
        ahv: contact.ahv,
        website: contact.website,
        language: contact.language,
        birthplace: contact.birthplace,
        civilStatus: contact.civilStatus,
        nationality: contact.nationality,
        salutationName: contact.salutationName,
        residenceStatus: contact.residenceStatus,
        preferredCommunicationChannel: contact.preferredCommunicationChannel,
        tags: contact.tags,
        residenceStatusValidUntil: contact.residenceStatusValidUntil,
        preferredLanguage: contact.preferredLanguage,
        workEmail: contact.workEmail
      }).then(response => {
        return response.data
      })
    }
  },
  addContactPerson(newContact, companyContactId) {
    const phoneNumber = []
    if (newContact.phone.home1 !== null && newContact.phone.home1 !== '') {
      phoneNumber.push({
        number: newContact.phone.home1,
        type: 'HOME'
      })
    }
    if (newContact.phone.home2 !== null && newContact.phone.home2 !== '') {
      phoneNumber.push({
        number: newContact.phone.home2,
        type: 'HOME'
      })
    }
    if (newContact.phone.work !== null && newContact.phone.work !== '') {
      phoneNumber.push({
        number: newContact.phone.work,
        type: 'WORK'
      })
    }
    if (newContact.phone.mobile !== null && newContact.phone.mobile !== '') {
      phoneNumber.push({
        number: newContact.phone.mobile,
        type: 'MOBILE'
      })
    }
    if (newContact.phone.fax !== null && newContact.phone.fax !== '') {
      phoneNumber.push({
        number: newContact.phone.fax,
        type: 'FAX'
      })
    }
    const dod = new Date(newContact.dod)
    const dateOfDeath = dod instanceof Date && isFinite(dod)
? {
      day: dod.getDate(),
      month: dod.getMonth() + 1,
      year: dod.getFullYear()
    }
: null
    const dob = new Date(newContact.dob)
    const dateOfBirth = dob instanceof Date && isFinite(dob)
? {
      day: dob.getDate(),
      month: dob.getMonth() + 1,
      year: dob.getFullYear()
    }
: null
    return network.post(`/web/v2/contact/${companyContactId}/addContactPerson`, {
      companyContactId: companyContactId,
      email: newContact.email,
      gender: newContact.gender,
      title: newContact.title,
      note: newContact.note,
      firstName: newContact.firstName,
      lastName: newContact.lastName,
      birthName: newContact.birthName,
      employment: {
        companyName: newContact.companyName,
        companyPosition: newContact.companyPosition
      },
      address: newContact.address,
      phoneNumbers: phoneNumber,
      dateOfDeath: dateOfDeath,
      dateOfBirth: dateOfBirth,
      website: newContact.website,
      preferredLanguage: newContact.preferredLanguage,
      includeInSerialEmails: newContact.includeInSerialEmails,
      portalAccess: newContact.portalAccess
    }).then(response => {
      return response.data
    })
  },
  editContactPerson(newContact, companyContactId, contactPersonId) {
    const phoneNumber = []
    if (newContact.phone) {
      if (newContact.phone.home1 !== null && newContact.phone.home1 !== '') {
        phoneNumber.push({
          number: newContact.phone.home1,
          type: 'HOME'
        })
      }
      if (newContact.phone.home2 !== null && newContact.phone.home2 !== '') {
        phoneNumber.push({
          number: newContact.phone.home2,
          type: 'HOME'
        })
      }
      if (newContact.phone.work !== null && newContact.phone.work !== '') {
        phoneNumber.push({
          number: newContact.phone.work,
          type: 'WORK'
        })
      }
      if (newContact.phone.mobile !== null && newContact.phone.mobile !== '') {
        phoneNumber.push({
          number: newContact.phone.mobile,
          type: 'MOBILE'
        })
      }
      if (newContact.phone.fax !== null && newContact.phone.fax !== '') {
        phoneNumber.push({
          number: newContact.phone.fax,
          type: 'FAX'
        })
      }
    }
    const dod = new Date(newContact.dod)
    const dateOfDeath = dod instanceof Date && isFinite(dod)
? {
      day: dod.getDate(),
      month: dod.getMonth() + 1,
      year: dod.getFullYear()
    }
: null
    const dob = new Date(newContact.dob)
    const dateOfBirth = dob instanceof Date && isFinite(dob)
? {
      day: dob.getDate(),
      month: dob.getMonth() + 1,
      year: dob.getFullYear()
    }
: null
    return network.post(`/web/v2/contact/${companyContactId}/updateContactPerson/${contactPersonId}`, {
      companyContactId: companyContactId,
      contactPersonId: contactPersonId,
      email: newContact.email,
      title: newContact.title,
      note: newContact.note,
      gender: newContact.gender,
      firstName: newContact.firstName,
      lastName: newContact.lastName,
      birthName: newContact.birthName,
      employment: {
        companyName: newContact.companyName,
        companyPosition: newContact.companyPosition
      },
      address: newContact.address,
      phoneNumbers: phoneNumber,
      dateOfDeath: dateOfDeath,
      dateOfBirth: dateOfBirth,
      website: newContact.website,
      preferredLanguage: newContact.preferredLanguage,
      salutationName: newContact.salutationName,
      includeInSerialEmails: newContact.includeInSerialEmails,
      portalAccess: newContact.portalAccess
    }).then(response => {
      return response.data
    })
  },
  deleteContactPerson(companyContactId, contactPersonIds) {
    return network.post(`/web/v2/contact/${companyContactId}/deleteContactPerson`, {
      companyContactId: companyContactId,
      contactPersonIds: contactPersonIds
    }).then(response => {
      return response.data
    })
  },
  activateContact(contactIds) {
    return network.post('/web/v2/contact/activate', {
      ids: contactIds
    }).then(response => {
      return response.data
    })
  },
  deactivateContact(contactIds) {
    return network.post('/web/v2/contact/deactivate', {
      ids: contactIds
    }).then(response => {
      return response.data
    })
  },
  addDocuments(id, documentIds) {
    return network.post(`/web/v2/contact/${id}/addDocuments`, {
      documentIds: documentIds,
      id: id
    }).then(response => {
      return response.data
    })
  },
  deleteDocuments(id, documentIds) {
    return network.delete(`/web/v2/contact/${id}/removeDocuments/${documentIds}`).then(response => {
      return response.data
    })
  },
  addActivity(activity, referenceId) {
    return network.post('/web/v2/contact/activity/create', {
      contactIds: activity.contactIds,
      referenceIds: referenceId,
      employeeContactId: activity.employeeContactId ? activity.employeeContactId : null,
      subject: activity.subject,
      text: activity.text,
      timestamp: activity.timestamp ? Math.round(activity.timestamp.getTime() / 1000) : null,
      documentIds: activity.documentIds
    }).then(response => {
      return response.data
    })
  },
  addActivityV3(activity) {
    return network.post('/web/v3/contact/activity/create', {
      ownerId: activity.ownerId,
      references: activity.references,
      employeeContactIds: activity.employeeContactIds,
      subject: activity.subject,
      text: activity.text,
      source: activity.source,
      timestampStart: activity.timestampStart,
      timestampEnd: activity.timestampEnd,
      mediaIds: activity.mediaIds,
      hasStartTime: activity.hasStartTime,
      hasEndTime: activity.hasEndTime
    }).then(response => {
      return response.data
    })
  },
  updateActivityV3(activity) {
    console.log(activity.employeeContactIds)
    return network.post(`/web/v3/contact/activity/update/${activity.id}`, {
      ownerId: activity.ownerId,
      references: activity.references,
      id: activity.id,
      employeeContactIds: activity.employeeContactIds,
      subject: activity.subject,
      text: activity.text,
      source: activity.source,
      timestampStart: activity.timestampStart,
      timestampEnd: activity.timestampEnd,
      mediaIds: activity.mediaIds,
      hasStartTime: activity.hasStartTime,
      hasEndTime: activity.hasEndTime
    }).then(response => {
      return response.data
    })
  },
  getActivitiesByReferenceIdsV3(referenceIds) {
    return network.post('web/v3/contact/activity/listByReferenceIds', {
      referenceIds: referenceIds
    }).then(response => {
      return response.data
    })
  },
  setAddress(address, ids, validFrom) {
    return network.post('web/v2/contact/setAddress', {
      address: address,
      ids: ids,
      validFrom: validFrom
    }).then(response => {
      return response.data
    })
  },
  updateAddressHistory(address, ids, oldValidFrom, validFrom) {
    return network.post('/web/v2/contact/updateAddressHistory', {
      address: address,
      ids: ids,
      oldValidFrom: oldValidFrom,
      validFrom: validFrom
    }).then(response => {
      return response.data
    })
  },
  getUsedTags(accountId) {
    return network.get(`/web/v2/contact/getUsedTags/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  deleteAddress(id, validFrom) {
    return network.post(`/web/v2/contact/${id}/deleteAddress`, {
      validFrom: validFrom
    }).then(response => {
      return response.data
    })
  }
}
