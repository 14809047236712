import { render, staticRenderFns } from "./TicketDetailsView.vue?vue&type=template&id=dcfb032c&scoped=true"
import script from "./TicketDetailsView.vue?vue&type=script&lang=js"
export * from "./TicketDetailsView.vue?vue&type=script&lang=js"
import style0 from "./TicketDetailsView.vue?vue&type=style&index=0&id=dcfb032c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcfb032c",
  null
  
)

export default component.exports