<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2">
      <coozzy-spinner
        v-if="loading" />
    </div>
    <div
      class="row">
      <div class="col-md-5">
        <h3>Available Rooms</h3>
        <coozzy-card
          class="list-group"
          :style="{ minHeight: '200px' }">
          <div
            v-for="(room, index) in availableRooms"
            :id="index"
            :key="index"
            class="room-container">
            <div
              class="room-title"
              @click="toggleCollapse(index)">
              {{ room.value }}
              <coozzy-button
                class="float-right"
                design="prop-green"
                :disabled="!selectedRoom"
                @click="addAllDevices(room, $event)">
                {{ $t('message.onBoarding.devices.addAll') }}
              </coozzy-button>
            </div>
            <div
              v-if="collapsedRooms[index]"
              class="device-list">
              <draggable
                v-model="room.devices"
                :group="{ name: 'devices'}"
                class="list-group"
                @end="onDrop('available', $event)">
                <div
                  v-for="device in room.devices"
                  :key="device.id || device.internalId"
                  :class="['list-group-item', selectedDevices.includes(device) ? 'selected' : '']"
                  @click="selectDevice(device, 'available')">
                  {{ device.value }}
                </div>
              </draggable>
            </div>
          </div>
        </coozzy-card>
      </div>
      <b-modal
        :id="'editDevice'"
        ref="editDevice"
        :key="internalKey"
        no-close-on-backdrop
        :title="$t('message.onBoarding.buildings.objects.renovation.editDevice.title')"
        hide-footer>
        <div class="col">
          <device
            ref="object-device"
            :target="'object'"
            :from-handover="true"
            :handover-rooms="preSelectedRooms"
            :device="deviceToEdit"
            :expand-by-default="true"
            :owner-id="object?.ownerId"
            @device-update="deviceUpdated" />
        </div>
        <div class="col mt-2">
          <coozzy-button
            size="small"
            class="float-left mb-0 border"
            design="transparent"
            @click="$bvModal.hide('editDevice')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            design="green"
            class="float-right ml-3"
            @click="saveDevice()">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>

      <div class="arrows col-md-2">
        <button
          :disabled="!selectedDevices || selectedDevicesType !== 'available'"
          @click="moveSelectedDevicesToSelected">
          <span>→</span>
        </button>
        <button
          :disabled="!selectedDevices || selectedDevicesType !== 'selected'"
          @click="moveSelectedDevicesToAvailable">
          <span>←</span>
        </button>
      </div>

      <div class="col-md-5">
        <h3>Selected Rooms</h3>
        <coozzy-card
          class="list-group"
          :style="{ minHeight: '200px' }">
          <div
            v-for="room in selectedRooms"
            :id="room.id"
            :key="room.id"
            class="room-container"
            @click="selectSelectedRoom(room)">
            <div
              class="room-title"
              :class="['room-title', selectedRoom && selectedRoom.id === room.id ? 'selected' : '']">
              {{ room.name }}
              <coozzy-button
                class="float-right"
                design="prop-green"
                @click="addDevice(room, $event)">
                {{ $t('message.onBoarding.devices.add') }}
              </coozzy-button>
            </div>
            <div
              v-if="!collapsedRooms[room.id]"
              class="device-list">
              <draggable
                v-model="room.devices"
                group="devices"
                class="list-group"
                @end="onDrop('selected', $event)">
                <div
                  v-for="device in room.devices"
                  :key="device.id"
                  class="list-group-item">
                  {{ device.value }}
                  <coozzy-button
                    design="transparent"
                    class="p-0 ml-1 mb-2  float-right"
                    @click="deleteDevice(device, room)">
                    <coozzy-delete-icon />
                  </coozzy-button>
                  <coozzy-button
                    design="transparent"
                    class="p-0 mb-2 float-right ml-2"
                    @click="editDevice(device, room)">
                    <coozzy-edit-icon />
                  </coozzy-button>
                </div>
              </draggable>
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import Device from '@/building/tabs/components/Device.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'

export default {
  name: 'HandoverDevicesStep',
  components: {
    CoozzyCard,
    CoozzyDeleteIcon,
    Device,
    CoozzyButton,
    CoozzyEditIcon,
    draggable,
    CoozzySpinner
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia],
  props: {
    object: {
      type: Object,
      required: true
    },
    preSelectedRooms: {
      type: Array,
      default: () => []
    },
    devices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: true,
      internalKey: 0,
      availableRooms: [],
      selectedRooms: [],
      selectedEntries: [],
      filteredObjects: [],
      collapsedRooms: [],
      selectedDevices: [],
      usedContacts: [],
      selectedDevicesType: 'available',
      deviceToEdit: null,
      selectedRoom: null,
      selectedEditedDevice: null
    }
  },
  computed: {
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    }
  },
  watch: {
    selectedRooms(newVal) {
      this.$emit('selected-rooms', newVal, this.object)
    },
    devices(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }
      this.checkDevices()
    },
    preSelectedRooms: {
      handler(newVal) {
        if (JSON.stringify(newVal) === JSON.stringify(this.selectedRooms)) {
          return
        }
        this.selectedRooms = []
        newVal.forEach(room => {
          room.devices = []
          const selectedRoom = this.availableRooms.find(r => r.id === room.id)
          if (selectedRoom) {
            this.selectedRooms.push(selectedRoom)
            this.availableRooms = this.availableRooms.filter(r => r.id !== room.id)
            this.selectedRooms = this.selectedRooms.filter(r => r.id.startsWith('room_'))
          } else {
            this.selectedRooms.push({ ...room })
            this.selectedRooms = this.selectedRooms.filter(r => typeof room.id === 'string' && r.id.startsWith('room_'))
          }
          this.checkDevices()
        })
        // remove duplicates
        this.selectedRooms = this.selectedRooms.filter((room, index, self) =>
            index === self.findIndex((t) => (
              t.id === room.id
            ))
        )
      },
      deep: true
    }
  },
  mounted() {
    this.loadAvailableRooms()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    checkDevices() {
      if (this.devices.length > 0) {
        this.devices.forEach(device => {
          const room = this.selectedRooms.find(r => r.id === device.roomId)
          // if room.devices already contains the device, do nothing
          if (room && !room.devices.find(d => d.internalId === device.internalId || d.id === device.id)) {
            device.value = device.type === '' || device.type === null || device.type === 'DEVICE_TYPE_UNDEFINED' ? '-' : this.$t('message.onBoarding.devices.typeValues.' + device.type)
            room.devices.push(device)
          }
        })
      }
    },
    onDrop(type, $event) {
      // get the room where the device was dropped
      const room = this.selectedRooms.find(r => r.id === $event.to.parentNode.parentElement.id)
      if (!this.selectedRoom && room) {
        this.selectedRoom = room
      }
      this.$nextTick(() => {
        if (type === 'available') {
          this.moveSelectedDevicesToSelected(true)
        } else if (type === 'selected') {
          this.moveSelectedDevicesToAvailable(true)
        }
      })
    },
    moveSelectedDevicesToAvailable() {
      if (this.selectedDevices.length > 0 && this.selectedDeviceType === 'selected') {
        this.selectedDevices.forEach(device => {
          const room = this.selectedRooms.find(r => r.devices.includes(device))
          if (room) {
            room.devices = room.devices.filter(d => d.internalId !== device.internalId)
            this.availableRooms.push(device)
          }
        })
        this.clearSelection()
      }
    },
    moveSelectedDevicesToSelected() {
      if (this.selectedDevices.length > 0 && this.selectedDeviceType === 'available') {
        this.selectedDevices.forEach(device => {
          const room = this.availableRooms.find(r => r.devices.includes(device))
          if (room) {
            room.devices = room.devices.filter(d => d.internalId !== device.internalId)
            if (this.selectedRoom?.devices) {
              this.selectedRoom.devices.push(device)
            } else {
              this.selectedRoom.devices = [device]
            }
          }
        })
        this.clearSelection()
      }
    },
    editDevice(device, room) {
      device.roomId = room.id
      this.deviceToEdit = { ...device }
      this.internalKey++
      this.$nextTick(() => {
        this.$refs.editDevice.show()
      })
    },
    updateDevices() {
      this.clearSelection()
    },
    deviceUpdated(device) {
      device.edited = true
      this.selectedEditedDevice = device
    },
    saveDevice() {
      const room = this.selectedRooms.find(r => r.id === this.selectedEditedDevice.roomId)
      if (room) {
        const deviceIndex = room.devices.findIndex(d => d.internalId === this.selectedEditedDevice.internalId)
        if (deviceIndex > -1) {
          this.selectedRooms.find(r => r.id === this.selectedEditedDevice.roomId).devices[deviceIndex] = this.selectedEditedDevice
        }
      }
      this.selectedEditedDevice = null
      this.$refs.editDevice.hide()
    },
    addDevice(room, event) {
      event.stopPropagation()
      const newDevice = {
        internalId: Date.now(),
        ownerId: this.object.ownerId,
        numericId: 99999,
        brand: '',
        referenceId: '',
        installation: null,
        uninstallation: null,
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        type: 'DEVICE_TYPE_UNDEFINED',
        system: undefined,
        facilityPlant: undefined,
        bkpH: undefined,
        lifeSpan: -1,
        amount: -1,
        value: this.$t('message.onBoarding.devices.newDevice'),
        acquisitionCosts: -1,
        unit: 'DEVICE_UNIT_UNDEFINED',
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_NORMAL_WEAR',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: {
          combinedKeys: false,
          combinedKeysNote: '',
          description: '',
          lockingPlanNumber: ''
        }
      }
      room.devices.push(newDevice)
      this.clearSelection()
    },
    deleteDevice(device, room) {
      const selectedRoom = this.selectedRooms.find(r => r.id === room.id)
      if (selectedRoom) {
        selectedRoom.devices = selectedRoom.devices.filter(d => d.internalId !== device.internalId || d.id !== device.id)
      }
      this.clearSelection()
    },
    clearSelection() {
      this.selectedDevices = []
      this.selectedDeviceType = null
    },
    toggleCollapse(index) {
      this.$set(this.collapsedRooms, index, !this.collapsedRooms[index])
    },
    loadAvailableRooms() {
      this.loading = true
      if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
      this.availableRooms = [{
        name: 'kitchen',
        value: this.$t('message.processHandover.rooms.kitchen'),
        devices: [
          { internalId: 1, type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
          { internalId: 2, type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
          { internalId: 3, type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
          { internalId: 4, type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
          { internalId: 5, type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
          { internalId: 6, type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
          { internalId: 7, type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
          { internalId: 8, type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
          { internalId: 9, type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
          { internalId: 10, type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
          { internalId: 11, type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
          { internalId: 12, type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
          { internalId: 13, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
          { internalId: 14, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
          { internalId: 15, type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
          { internalId: 16, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET') },
          { internalId: 17, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET') },
          { internalId: 18, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER') },
          { internalId: 19, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE') },
          { internalId: 20, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER') },
          { internalId: 21, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN') },
          { internalId: 22, type: 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE') },
          { internalId: 23, type: 'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE') },
          { internalId: 24, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB') },
          { internalId: 25, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD') },
          { internalId: 26, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER') },
          { internalId: 27, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN') },
          { internalId: 28, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES') },
          { internalId: 29, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER') },
          { internalId: 30, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES') },
          { internalId: 31, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR') },
          { internalId: 32, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES') },
          { internalId: 33, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER') },
          { internalId: 34, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER') },
          { internalId: 35, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY') }]
      },
        {
          name: 'wetRoom',
          value: this.$t('message.processHandover.rooms.wetRoom'),
          devices: [
            { internalId: 1, type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: 2, type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: 3, type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: 4, type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: 5, type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: 6, type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: 7, type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: 8, type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: 9, type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: 10, type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: 11, type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: 12, type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: 13, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: 14, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: 15, type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: 16, type: 'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE') },
            { internalId: 17, type: 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS') },
            { internalId: 18, type: 'DEVICE_TYPE_SANITARY_WASHBASIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_WASHBASIN') },
            { internalId: 19, type: 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE') },
            { internalId: 20, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS') },
            { internalId: 21, type: 'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE') },
            { internalId: 22, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER') },
            { internalId: 23, type: 'DEVICE_TYPE_SANITARY_TOILET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_TOILET') },
            { internalId: 24, type: 'DEVICE_TYPE_SANITARY_TOILET_COVER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_TOILET_COVER') },
            { internalId: 25, type: 'DEVICE_TYPE_SANITARY_FLUSH_TANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_FLUSH_TANK') },
            { internalId: 26, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER') },
            { internalId: 27, type: 'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN') },
            { internalId: 28, type: 'DEVICE_TYPE_SANITARY_BATHTUB', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_BATHTUB') },
            { internalId: 29, type: 'DEVICE_TYPE_SANITARY_SHOWER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER') },
            { internalId: 30, type: 'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE') },
            { internalId: 31, type: 'DEVICE_TYPE_SANITARY_SHOWER_ROD', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_ROD') },
            { internalId: 32, type: 'DEVICE_TYPE_SANITARY_SHOWER_CABIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_CABIN') }
          ]
},
        {
          name: 'room',
          value: this.$t('message.processHandover.rooms.room'),
          devices: [
            { internalId: '1room', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2room', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3room', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4room', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5room', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6room', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7room', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8room', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9room', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10room', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11room', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12room', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13room', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: '14room', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15room', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '16room', type: 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS') },
            { internalId: '17room', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
            { internalId: '18room', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA') },
            { internalId: '19room', type: 'DEVICE_TYPE_INSULATION_CHIMNEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_INSULATION_CHIMNEY') },
            { internalId: '20room', type: 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SUPERSTRUCTURE_RAILING') }
          ]
        },
        {
          name: 'corridor_entrance',
          value: this.$t('message.processHandover.rooms.corridor_entrance'),
          devices: [
            { internalId: '1_corridor_entrance', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_corridor_entrance', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3_corridor_entrance', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4_corridor_entrance', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6_corridor_entrance', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7_corridor_entrance', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8_corridor_entrance', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9_corridor_entrance', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10_corridor_entrance', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11_corridor_entrance', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12_corridor_entrance', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13_corridor_entrance', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: '14_corridor_entrance', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_corridor_entrance', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '16_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS') },
            { internalId: '17_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
            { internalId: '18_corridor_entrance', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE') }
          ]
},
        {
          name: 'reduit_storage',
          value: this.$t('message.processHandover.rooms.reduit_storage'),
          devices: [
            { internalId: '1_reduit_storage', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_reduit_storage', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3_reduit_storage', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4_reduit_storage', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5_reduit_storage', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6_reduit_storage', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7_reduit_storage', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8_reduit_storage', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9_reduit_storage', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10_reduit_storage', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11_reduit_storage', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12_reduit_storage', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13_reduit_storage', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: '14_reduit_storage', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_reduit_storage', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') }
          ]
        },
        {
          name: 'balcony_seatingArea',
          value: this.$t('message.processHandover.rooms.balcony_seatingArea'),
          devices: [
            { internalId: '1_balcony_seatingArea', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '13_balcony_seatingArea', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: '14_balcony_seatingArea', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_balcony_seatingArea', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '19_balcony_seatingArea', type: 'DEVICE_TYPE_FACADE_PLASTER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FACADE_PLASTER') },
            { internalId: '20_balcony_seatingArea', type: 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN') },
            { internalId: '21_balcony_seatingArea', type: 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS') },
            { internalId: '22_balcony_seatingArea', type: 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP') }
          ]
        },
        {
          name: 'cellar',
          value: this.$t('message.processHandover.rooms.cellar'),
          devices: [
            { internalId: '1_cellar', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_cellar', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '3_cellar', type: 'DEVICE_TYPE_CARPENTER_CELLAR_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CELLAR_DOOR') },
            { internalId: '4_cellar', type: 'DEVICE_TYPE_CARPENTER_SHELF', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_SHELF') },
            { internalId: '5_cellar', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
            { internalId: '6_cellar', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '7_cellar', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '8_cellar', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '9_cellar', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') }
          ]
        }]
    } else if (this.object.category === 'PARKING_SPACE') {
      this.availableRooms = [{
      name: 'parking',
      value: this.$t('message.processHandover.rooms.parking'),
      devices: [
        { internalId: '1_parking', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
        { internalId: '2_parking', type: 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING') },
        { internalId: '3_parking', type: 'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR') },
        { internalId: '4_parking', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
        { internalId: '5_parking', type: 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP') }
      ]
      }]
    } else if (this.object.category === 'SECONDARY_ROOM') {
      this.availableRooms = [{
      name: 'secondaryRoom',
      value: this.$t('message.processHandover.rooms.secondaryRoom'),
      devices: [
        { internalId: '1_secondaryRoom', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
        { internalId: '2_secondaryRoom', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
        { internalId: '3_secondaryRoom', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
        { internalId: '4_secondaryRoom', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
        { internalId: '5_secondaryRoom', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
        { internalId: '6_secondaryRoom', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
        { internalId: '7_secondaryRoom', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
        { internalId: '8_secondaryRoom', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
        { internalId: '9_secondaryRoom', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
        { internalId: '10_secondaryRoom', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH') },
        { internalId: '11_secondaryRoom', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
        { internalId: '12_secondaryRoom', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') }
      ]
      }]
      }
       this.addPropertiesToDevices()
      this.loading = false
    },
    addAllDevices(room, event) {
      if (!this.selectedRoom) return
      room.devices.forEach(device => {
        this.selectedRoom.devices.push(device)
      })
    },
    addPropertiesToDevices() {
      this.availableRooms.forEach(room => {
        room.devices.forEach(device => {
          Object.assign(device, {
            internalId: Date.now() + '_' + device.type + '_' + room.name,
            ownerId: this.object.ownerId,
            numericId: 99999,
            brand: '',
            referenceId: '',
            installation: null,
            uninstallation: null,
            measureUntil: null,
            measure: 'DEVICE_MEASURE_UNDEFINED',
            type: device.type,
            system: undefined,
            facilityPlant: undefined,
            bkpH: undefined,
            lifeSpan: -1,
            amount: -1,
            value: device.value,
            acquisitionCosts: -1,
            unit: 'DEVICE_UNIT_UNDEFINED',
            cataloguePrice: -1,
            maintenance: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastMaintenance: null,
              nextMaintenance: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            inspection: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialControl: null,
              nextOfficialControl: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            revision: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialRevision: null,
              nextOfficialRevision: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            condition: 'DEVICE_CONDITION_NORMAL_WEAR',
            note: '',
            documentIds: [],
            images: [],
            isNewItem: true,
            lockingKey: {
              combinedKeys: false,
              combinedKeysNote: '',
              description: '',
              lockingPlanNumber: ''
            }
          })
        })
      })
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
      }
      this.$emit('selected-object', this.selectedEntries)
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        this.$router.push({ name: 'AdminContactDetailsView', params: { internalId: contactId } })
      }
    },
    selectSelectedRoom(room) {
      this.selectedRoom = room
    },
    getContactName(contactId) {
      const contact = this.usedContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    selectDevice(device, type) {
      const index = this.selectedDevices.findIndex(d => d.internalId === device.internalId)
      if (index === -1) {
        this.selectedDevices.push(device)
      } else {
        this.selectedDevices.splice(index, 1)
      }
      this.selectedDeviceType = type
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 80% !important;
}
.room-container {
  margin-bottom: 20px;
}

.room-title {
  padding: 10px;
  background-color: #e9ecef;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.device-list {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 5px;
}
.arrows {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  margin-top: 10rem;
  align-items: center;
}

.arrows button {
  margin: 5px;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f5f5f5;
  cursor: grab;
}

.add-device-btn {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-device-btn:hover {
  background-color: #218838;
}
.list-group-item.selected, .room-title.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
</style>
