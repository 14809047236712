<template>
  <section class="w-100">
    <div>
      <coozzy-detail-card
        :title="fromHandover === 'moveIn' ? $t('message.processHandover.movingInTenancy') : $t('message.processHandover.movingOutTenancy')">
        <div class="col p-0">
          <div
            v-if="loadingTenancies"
            class="text-center">
            <coozzy-spinner />
          </div>
          <b-table
            v-else
            ref="tenancyContactsTable"
            :fields="fieldsTenantsList"
            :items="listContactTenancies"
            bordered
            class="overflow-auto shadow"
            hover
            responsive="true"
            select-mode="single"
            selectable
            stacked="md"
            @row-clicked="onRowClicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(name)>
              {{ $t('message.generic.name') }}
            </template>
            <template #head(contactPerson)>
              {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
            </template>
            <template #head(type)>
              {{ $t('message.generic.type') }}
            </template>
            <template #head(communication)>
              {{ $t('message.generic.communication') }}
            </template>
            <template #head(action) />

            <!-- Body -->
            <template #cell(name)="data">
              {{
                data.item.name
              }}
            </template>
            <template #cell(contactPerson)="data">
              <coozzy-form-select
                v-if="data.item.contactPersons"
                id="contactPerson"
                :ref="'contactPerson_' + data.item.id"
                v-model="data.item.selectedContactPerson">
                <option :value="null">
                  -
                </option>
                <option
                  v-for="contact of data.item.contactPersons"
                  :key="contact.id"
                  :value="contact.id">
                  {{ contact.name }}
                </option>
              </coozzy-form-select>
              <span v-else>
                -
              </span>
            </template>
            <template #cell(type)="data">
              {{ data.item.type }}
            </template>
            <template #cell(communication)="data">
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-mobile-alt-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-phone-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-headset-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                  </a>
                </div>
              </div>
              <div
                v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                class="row">
                <div class="col-1">
                  <coozzy-fax-icon />
                </div>
                <div class="col">
                  <a
                    :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')"
                    class="link">
                    {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                  </a>
                </div>
              </div>
              <div
                v-if="data.item.email !== ''"
                class="row">
                <div class="col-1">
                  <img
                    alt="Placeholder"
                    class="email-icon-img"
                    src="@/assets/icon/arroba.svg">
                </div>
                <div class="col">
                  <template v-if="data.item.email !== ''">
                    <a
                      class="link"
                      tabindex="0">
                      {{ data.item.email }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.email | displayOptionalValue }}
                  </template>
                </div>
              </div>
              <div
                v-if="data.item.website !== ''"
                class="row">
                <div class="col-1">
                  <coozzy-globe-icon />
                </div>
                <div class="col">
                  <template v-if="data.item.website !== ''">
                    <a
                      :href="getUrlWebsite(data.item.website)"
                      class="link"
                      tabindex="0"
                      target="_blank">
                      {{ data.item.website }}
                    </a>
                  </template>
                  <template v-else>
                    {{ data.item.website | displayOptionalValue }}
                  </template>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <coozzy-button
                size="small"
                class="float-right mb-0 address-button"
                design="green"
                @click="addAddress(data.item.tenancy)">
                {{ $t('message.contact.actions.addAddress') }}
              </coozzy-button>
            </template>
          </b-table>
          <!--          <b-table-->
          <!--            v-else-->
          <!--            id="tenantsTable"-->
          <!--            ref="tenantsTable"-->
          <!--            bordered-->
          <!--            hover-->
          <!--            responsive-->
          <!--            selectable-->
          <!--            select-mode="single"-->
          <!--            :fields="fields"-->
          <!--            :items="tenantList"-->
          <!--            @row-clicked="onRowClicked">-->
          <!--            <template #head(currentTenant)>-->
          <!--              {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.current') }}-->
          <!--            </template>-->
          <!--            <template #head(status)>-->
          <!--              {{ $t('message.tableColumnHeadings.status') }}-->
          <!--            </template>-->
          <!--            <template #head(objects)>-->
          <!--              {{ $t('message.tableColumnHeadings.objects') }}-->
          <!--            </template>-->
          <!--            <template #head(contractNumber)>-->
          <!--              {{ $t('message.tableColumnHeadings.contractNumber') }}-->
          <!--            </template>-->
          <!--            <template #head(type)>-->
          <!--              {{ $t('message.tableColumnHeadings.type') }}-->
          <!--            </template>-->
          <!--            <template #head(netRent)>-->
          <!--              {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}-->
          <!--            </template>-->
          <!--            <template #head(startAt)>-->
          <!--              {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}-->
          <!--            </template>-->
          <!--            <template #head(endAt)>-->
          <!--              {{ $t('message.onBoarding.buildings.objects.tenancies.endAt') }}-->
          <!--            </template>-->
          <!--            &lt;!&ndash; Table content &ndash;&gt;-->
          <!--            <template-->
          <!--              #cell(currentTenant)="data">-->
          <!--              <div v-if="data.item.vacancy === true">-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}-->
          <!--              </div>-->
          <!--              <div v-else-if="data.item.tenant.contactIds.length > 0">-->
          <!--                <template-->
          <!--                  v-for="(contactId, tenantIndex) in data.item.tenant.contactIds">-->
          <!--                  {{ tenantIndex === 0 ? '' : ', ' }}-->
          <!--                  <span-->
          <!--                    :key="tenantIndex"-->
          <!--                    class="currentContact">-->
          <!--                    {{ getContactName(contactId) }}-->
          <!--                  </span>-->
          <!--                </template>-->
          <!--              </div>-->
          <!--              <span v-else>-</span>-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(status)="data">-->
          <!--              <template v-if="data.item.closed === true">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 closed" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="data.item.activated === false">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 not_activated" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.not_activated') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() > (new Date()).getTime()">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 future" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime() && data.item.contract.type === 'LIMITED'">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 temporary" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_temporary') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime()">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 terminated" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_terminated') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="data.item.endingAt && data.item.endingAt.year > 0">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 terminated" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.terminated') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else-if="!data.item.endingAt || data.item.endingAt <= 0">-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 current" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--              <template v-else>-->
          <!--                <div-->
          <!--                  class="color-box mr-2 mt-2 future" />-->
          <!--                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}-->
          <!--                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}-->
          <!--              </template>-->
          <!--            </template>-->
          <!--            <template #cell(contractNumber)="data">-->
          <!--              <div-->
          <!--                class="" />-->
          <!--              {{ data.item.numericId }}-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(type)="data">-->
          <!--              <a-->
          <!--                v-if="getObject(data.item.objectId).category !== 'UNDEFINED_CATEGORY'"-->
          <!--                target="_blank"-->
          <!--                tabindex="0"-->
          <!--                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"-->
          <!--                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">-->
          <!--                {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId).category.toLowerCase()) }}-->
          <!--              </a>-->
          <!--              <template v-else>-->
          <!--                - -->
          <!--              </template>-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(netRent)="data">-->
          <!--              <span-->
          <!--                v-if="data.item.taxable || data.item.opted"-->
          <!--                class="float-right">-->
          <!--                {{ getRentWithTax(data.item, 'netRent') | formatPrice('CHF') }}-->
          <!--                <coozzy-info-circle-icon-->
          <!--                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"-->
          <!--                  class="ml-1" />-->
          <!--              </span>-->
          <!--              <span-->
          <!--                v-else-->
          <!--                class="float-right">-->
          <!--                {{ getAdjustedRentValues(data.item).coldRentPerMonth | formatPrice('CHF') }}-->
          <!--              </span>-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(startAt)="data">-->
          <!--              {{ data.item.startingAt | objectToDate }}-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(endAt)="data">-->
          <!--              {{ data.item.endingAt | objectToDate }}-->
          <!--            </template>-->
          <!--            <template-->
          <!--              #cell(actions)="data">-->
          <!--              <coozzy-button-->
          <!--                size="small"-->
          <!--                class="float-right mb-0 address-button"-->
          <!--                design="green"-->
          <!--                @click="addAddress(data.item)">-->
          <!--                {{ $t('message.contact.actions.addAddress') }}-->
          <!--              </coozzy-button>-->
          <!--            </template>-->
          <!--          </b-table>-->
        </div>
      </coozzy-detail-card>
      <add-address-modal
        v-if="contact"
        :contact="contact" />
      <create-contact-modal
        v-if="contact"
        :key="contact ? contact.id : null"
        :for-owner-id="null"
        :show-search="false"
        :show-request-sources="false"
        :set-interested-party-role="false"
        :show-add-another="true"
        :contact-to-edit="contact"
        @contact-updated="contactUpdated"
        @modal-hide="modalHide" />
    </div>
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
import AddAddressModal from '@/contact/components/AddAddressModal.vue'
import CreateContactModal from '@/contact/components/CreateContactModal.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon.vue'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon.vue'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon.vue'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon.vue'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon.vue'

export default {
  name: 'HandoverTenancyMove',
  components: {
    CoozzyFaxIcon,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    CoozzyFormSelect,
    CoozzyButton,
    CreateContactModal,
    AddAddressModal,
    // CoozzyInfoCircleIcon,
    CoozzySpinner,
    CoozzyDetailCard
  },
  mixins: [tables, routeChecks, user, formatting, referenceInterestRate],
  beforeRouteLeave (to, from, next) {
    console.log('to', to)
    console.log('from', from)
    next()
  },
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    fromHandover: {
      type: String,
      default: ''
    },
    moveTenancies: {
      type: Array,
      default() {
        return []
      }
    },
    objects: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      contact: null,
      fieldsTenantsList: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.interestedParty.reference.landlord.contactPerson'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.generic.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      // fields: [
      //   {
      //     key: 'currentTenant',
      //     label: this.$t('message.onBoarding.buildings.objects.tenancies.tenants.current'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'status',
      //     label: this.$t('message.tableColumnHeadings.status'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'contractNumber',
      //     label: this.$t('message.tableColumnHeadings.contractNumber'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'type',
      //     label: this.$t('message.tableColumnHeadings.type'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'startAt',
      //     label: this.$t('message.onBoarding.buildings.objects.tenancies.startAt'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'endAt',
      //     label: this.$t('message.onBoarding.buildings.objects.tenancies.endAt'),
      //     thClass: 'align-middle',
      //     tdClass: 'align-middle',
      //     selected: true
      //   },
      //   {
      //     key: 'netRent',
      //     label: this.$t('message.onBoarding.buildings.objects.pricing.coldRent'),
      //     thClass: 'align-middle text-right',
      //     tdClass: 'align-middle text-right'
      //   },
      //   {
      //     key: 'actions',
      //     label: this.$t('message.generic.actions'),
      //     thClass: 'align-middle text-right',
      //     tdClass: 'align-middle text-right'
      //   }
      // ],
      listContactTenancies: [],
      buildings: [],
      selectedFields: [],
      filterTenants: [],
      shares: [],
      listContacts: [],
      networkPartners: [],
      showAllTenancies: false,
      loadingTenancies: false,
      ownerAccounts: [],
      tenantList: []
    }
  },
  computed: {
  },
  watch: {
    availableContact: {
      handler() {
        this.listContacts = this.availableContact
        this.loadData()
      },
      deep: true
    }
  },
  mounted() {
    this.loadData()
    if (this.fromHandover === 'moveIn') {
      this.fieldsTenantsList = this.fieldsTenantsList.filter(field => field.key !== 'action')
    }
  },
  methods: {
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type?.toLowerCase() === type?.toLowerCase())) {
          return phoneNumbers.find(element => element.type.toLowerCase() === type.toLowerCase()).number
        }
      }
      return '-'
    },
    loadData() {
      if (this.moveTenancies.length > 0 && this.availableContact.length > 0) {
        this.filterTenants = this.moveTenancies
        // cpntacts that match contactIds of tenant of every tenancy
        const list = []
        this.listContactTenancies = this.moveTenancies.map(tenancy => {
          const contacts = this.availableContact.filter(contact => tenancy.tenant.contactIds.includes(contact.id))
          const object = this.objects.find(object => object.id === tenancy?.objectId) || null
          contacts.forEach(contact => {
            list.push({
              id: contact.id,
              name: contact.name,
              type: object ? object.name : '-',
              email: contact.email,
              contactPersons: 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null,
              phoneNumbers: contact.phoneNumbers,
              website: contact.website,
              tenancy: tenancy
            })
          })
          return list
        }).flat().filter((v, i, a) => a.findIndex(t => (t === v)) === i)
        this.loadingTenancies = false
        this.filterTenantList()
      }
    },
    filterTenantList() {
      const t = this
      const array = this.filterTenants
      array.sort((a, b) => new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime() ? -1 : 1)

      array.sort(function(x, y) {
        return (x.closed === y.closed) ? 0 : !x.closed ? -1 : 0
      })
      array.sort(function(x, y) {
        return (x.activated && !x.closed && new Date(t.$options.filters.objectToDateInput(x.endingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(y.endingAt)).getTime()) ? -1 : 0
      })
      array.sort(function(x) {
        return (x.activated && x.endingAt) ? 0 : -1
      })
      array.sort(function(x, y) {
        return (x.activated === y.activated) ? 0 : !x.activated ? -1 : 0
      })
      this.tenantList = array
    },
    onRowClicked(contact, index, event) {
      if (event.srcElement.classList.contains('address-button')) {
        this.addAddress(contact.tenancy)
      }
      this.editContactClicked(this.availableContact.find(c => c.id === contact.id))
    },
    contactUpdated(contact) {
      this.listContacts.find((element, index) => {
        if (element.id === contact.contact.id) {
          console.log('contact updated')
          this.listContacts[index] = contact.contact
        }
      })
      this.contact = null
      this.$emit('contact-updated', contact)
    },
    editContactClicked(contact) {
      this.contact = contact
      console.log(this.contact)
      this.$nextTick(function () {
        this.$bvModal.show('create-contact-modal')
      })
    },
    addAddress(tenancy) {
      this.availableContact.find(contact => {
        if (contact.id === tenancy.tenant.contactIds[0]) {
          this.contact = contact
        }
      })
      this.$nextTick(function () {
        this.$bvModal.show('add-address-modal')
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    modalHide() {
      this.contact = null
      this.$nextTick(function () {
        this.$bvModal.hide('create-contact-modal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal' + this.type)
    },
    getObject(id) {
      if (this.objects.length > 0) {
        return this.objects.find(obj => obj.id === id)
      }
      return null
    },
    getContactName(contactId) {
      const contact = this.listContacts.find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    getAdjustedRentValues(tenancy) {
      let currentRent = tenancy.rent.adjustments[0]
      const current = this
      const pastAdjustments = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(current.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustments.length > 0) {
        pastAdjustments.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        currentRent = pastAdjustments[0]
      }
      return currentRent
    },
    getTotalRent(tenancy) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const grossRent = (adjustedRentValues.heatingCosts > 0 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts > 0 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent > 0 ? adjustedRentValues.coldRent : 0)
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1
      return (grossRent * dueDates) / 12
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#tenantsTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
</style>
