<template>
  <section class="w-100">
    <div
      v-if="index !== -1 && renderDevices"
      :id="`device-${target}-${index}`"
      v-b-toggle="`collapse-device-${target}-${index}`"
      :class="index === -1 && !device.internalId ? '' : 'pt-3'"
      class="col mb-0">
      <div class="row">
        <div class="col d-flex">
          <h5
            :class="[uninstallation !== null ? 'text-gray' : '']"
            class="cursor-pointer hover width-only-content">
            {{ title }}
            <span v-if="roomId && getRoomName(roomId) !== '-'">({{ getRoomName(roomId) }})</span>
          </h5>
          <a
            class="lh-1 link-activity "
            tabindex="0"
            @click="openActivities(device)"
            @click.middle="openActivitiesMiddle(device)">
            <img
              :src="`/img/ticket/new-window.svg`"
              alt="type"
              class="ml-3 mb-1"
              height="15px">
            {{ $t('message.ticketOverview.activities.title') }}
          </a>
        </div>
        <div
          v-if="!fromHandover"
          class="col mr-5">
          <coozzy-dropdown
            v-if="(isTicketingEnabled && (isEditor || isTicketEditor)) || isEditor"
            :text="$t('message.generic.actions')"
            class="w-20 sort float-right"
            size="sm">
            <coozzy-dropdown
              v-if="ownerId != null && isTicketingEnabled && (isEditor || isTicketEditor)"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              class="w-100 ticket-dropdown-bulk-action enabled"
              design="transparent"
              dropleft
              size="sm"
              @click.prevent>
              <coozzy-dropdown-item
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!isOwnerModule && !isAssetModule"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              @click="readOnly ? goToEditMode() : addDocumentClicked('buildingDevice' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <div
      v-if="index === -1 && !device.internalId && !fromHandover"
      class="row h-0">
      <div
        class="offset-6 col-6 action-object">
        <coozzy-dropdown
          v-if="(isTicketingEnabled && (isEditor || isTicketEditor)) || isEditor"
          :text="$t('message.generic.actions')"
          class="w-20 sort float-right mr-5"
          size="sm">
          <coozzy-dropdown
            v-if="!device.internalId && isTicketingEnabled && (isEditor || isTicketEditor)"
            :disabled="true"
            :text="$t('message.ticketOverview.createTicket')"
            class="w-100 ticket-dropdown-bulk-action enabled"
            design="transparent"
            dropleft
            size="sm"
            @click.prevent>
            <coozzy-dropdown-item
              @click="navigateToCreateOrder()">
              {{ $t('message.ticketOverview.createOrder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isOwnerModule && !isAssetModule"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTask') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
          <coozzy-dropdown-item
            v-if="isEditor"
            @click="readOnly ? goToEditMode() : addDocumentClicked('buildingDevice' + index);$event.stopPropagation()">
            {{ $t('message.manageDocuments.addDocument') }}
          </coozzy-dropdown-item>
        </coozzy-dropdown>
      </div>
    </div>
    <div class="col-12 px-0">
      <b-collapse
        :id="`collapse-device-${target}-${index}`"
        ref="collapse"
        :visible="expandByDefault || openedCollapses.includes(`collapse-device-${target}-${index}`)"
        class="col">
        <template v-if="renderDevices">
          <div class="row details-row-bg-1 py-01rem pr-3">
            <div
              v-if="target !== 'property'"
              class="col-sm-12 col-md-3">
              <label for="room">{{ $t('message.onBoarding.devices.room') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="room"
                v-model="roomId"
                class="select-entries float-right"
                name="room">
                <option value="">
                  -
                </option>
                <option
                  v-for="(item, indexOption) in roomsOption"
                  :key="indexOption"
                  :value="item.id">
                  {{ item.name }}
                </option>
              </coozzy-form-select>
              <div
                v-else>
                <p
                  class="show-data">
                  {{ getRoomName(roomId) }}
                </p>
              </div>
            </div>
          </div>
          <div class="row details-row-bg-2 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <label>{{ $t('message.onBoarding.devices.type') }}</label>
              <vue-autosuggest
                v-if="!readOnly"
                v-model="query"
                :get-suggestion-value="s => s.item.typeText"
                :input-props="typeSuggestInputProps"
                :should-render-suggestions="(size, loading) => size >= 0 && !loading && !closed"
                :suggestions="filteredSuggestions"
                @blur="setClosed(true)"
                @focus="setClosed(false)"
                @selected="typeSuggestSelected"
                @item-changed="typeSuggestSelected">
                <template slot-scope="{suggestion}">
                  {{ suggestion.item.text }}
                </template>
              </vue-autosuggest>
              <span
                v-else
                class="show-data">
                {{
                  type === '' || type === null || type === 'DEVICE_TYPE_UNDEFINED' ? '-' : $t('message.onBoarding.devices.typeValues.' + type)
                }}
              </span>
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="translatedFacilityPlant"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.facilityPlant.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="translatedSystem"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.system')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="bkpH"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.bkpH')" />
            </div>
          </div>
          <div
            v-if="['DEVICE_TYPE_LOCKING_KEY', 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER'].includes(type)"
            class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="description"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.description')" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="serialNumber"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.serialNumber')" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="lockingPlanNumber"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.lockingPlanNumber')" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input-numeric
                    v-model="amount"
                    :is-decimal="false"
                    :is-integer="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.amount')"
                    type="number" />
                </div>
                <div
                  class="col-sm-12 col-md-3 align-content-center">
                  <coozzy-form-checkbox
                    :id="'combinedKeysNote' + device.id || device.internalId"
                    :key="'combinedKeysNote' + device.id || device.internalId"
                    v-model="combinedKeys"
                    :disabled="readOnly"
                    :initial="combinedKeys"
                    class="mt-3">
                    {{ $t('message.onBoarding.devices.lockingKey.combinedKeys') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-if="combinedKeys"
                  class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="combinedKeysNote"
                    :is-read-only="readOnly"
                    :name="$tc('message.onBoarding.devices.lockingKey.combinedKeysNote')" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="manufacturer"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.manufacturer')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="brand"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.brand')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="model"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.model')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="serialNumber"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.serialNumber')" />
            </div>
          </div>

          <div class="row details-row-bg-2 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.installation"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.installation')"
                type="date"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.uninstallation"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.uninstallation')"
                :state="(dates.uninstallation && $v.uninstallation.$dirty && $v.uninstallation.$error) ? false : null"
                type="date"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.warrantyEnd"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.warrantyExpiration')"
                type="date"
                @blur="dateToObject()" />
            </div>
          </div>
          <div class="row details-row-bg-1 py-01rem pr-3">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="acquisitionCosts"
                :check-valide="$v.acquisitionCosts.$dirty && $v.acquisitionCosts.$error ? 'is-invalid' : ''"
                :filter="'formatPrice'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.devices.acquisitionCosts')"
                min="0"
                placeholder="CHF"
                type="number" />
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <coozzy-form-input-numeric
                    v-model="cataloguePrice"
                    :check-valide="$v.cataloguePrice.$dirty && $v.cataloguePrice.$error ? 'is-invalid' : ''"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.cataloguePrice')"
                    min="0"
                    placeholder="CHF"
                    type="number" />
                </div>
                <div
                  v-if="!readOnly"
                  class="col-md-6">
                  <label for="unit">{{ $t('message.onBoarding.devices.unit') }}</label>
                  <coozzy-form-select
                    id="unit"
                    v-model="unit"
                    class="select-entries float-right"
                    name="unit">
                    <option
                      v-for="(item, indexOption) in unitOptions"
                      :key="indexOption"
                      :value="item">
                      {{ $t('message.onBoarding.devices.unitOptions.' + item) }}
                    </option>
                  </coozzy-form-select>
                </div>
                <div
                  v-else
                  class="col-md-6">
                  <label>{{ $t('message.onBoarding.devices.unit') }}</label>
                  <p
                    class="show-data">
                    {{ $t('message.onBoarding.devices.unitOptions.' + unit) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="row">
                <div
                  v-if="!['DEVICE_TYPE_LOCKING_KEY', 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER'].includes(type)"
                  class="col-md-3">
                  <coozzy-form-input-numeric
                    v-model="amount"
                    :is-decimal="false"
                    :is-integer="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.amount')"
                    type="number" />
                </div>
                <div class="col-md-3">
                  <coozzy-form-input-numeric
                    v-model="acquisitionCostSum"
                    :disabled="true"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.acquisitionCostSum')"
                    type="number" />
                </div>
                <!-- <div class="col-md-6">
                  <coozzy-form-input-numeric
                    v-model="guidePriceSum"
                    :is-read-only="readOnly"
                    :disabled="true"
                    :filter="'formatPrice'"
                    type="number"
                    :is-decimal="true"
                    :name="$t('message.onBoarding.devices.guidePriceSum')" />
                </div> -->
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="lifeSpan"
                    :is-read-only="readOnly"
                    :min="0"
                    :name="$t('message.onBoarding.devices.lifeSpan')"
                    type="number"
                    @blur="onBlurLifeSpan()" />
                </div>
                <div class="col-sm-12 col-md-3">
                  <coozzy-form-input
                    v-model="calculatedReplacementSet"
                    :disabled="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.calculatedReplacement')" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="!fromHandover" class="row details-row-bg-2 py-01rem pr-3">
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-3">
              <label for="condition">{{ $t('message.onBoarding.devices.condition') }}</label>
              <coozzy-form-select
                id="condition"
                v-model="condition"
                class="select-entries float-right"
                name="condition">
                <option
                  v-for="(item, indexOption) in conditionOptions"
                  :key="indexOption"
                  :value="item">
                  {{ $t('message.onBoarding.devices.conditionOptions.' + item) }}
                </option>
              </coozzy-form-select>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label for="condition">{{ $t('message.onBoarding.devices.condition') }}</label>
              <p>
                {{ $t('message.onBoarding.devices.conditionOptions.' + condition) }}
              </p>
            </div>
            <div
              v-if="condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED'"
              class="col-3">
              <div class="row">
                <div
                  v-if="!readOnly"
                  class="d-flex col-sm-12 col-md-6 mt-1">
                  <coozzy-form-checkbox
                    :id="device.id || device.internalId + 'measure'"
                    :key="device.id || device.internalId + 'measure'"
                    v-model="measure"
                    :initial="measure === 'DEVICE_MEASURE_IMMEDIATELY'"
                    class="align-self-center mt-3">
                    {{ $t('message.onBoarding.devices.measures') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-else
                  class="col-sm-12 col-md-6">
                  <label>
                    {{ $t('message.onBoarding.devices.measures') }}
                  </label>
                  <span class="show-data">
                    {{
                      measure === 'DEVICE_MEASURE_IMMEDIATELY' ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no')
                    }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6">
                  <coozzy-form-input
                    v-model="dates.measureUntil"
                    :filter="'formatDate'"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.devices.measureUntil') + (readOnly ? '' : '*')"
                    :state="$v.measureUntil.$dirty && $v.measureUntil.$error ? false : null"
                    type="date"
                    @blur="dateToObjectMeasureUntil()" />
                </div>
              </div>
            </div>
            <div
              :class="condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED' ? 'col-9' : 'col-12'">
              <coozzy-form-textarea
                v-model="note"
                :check-valide="(condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') && $v.note.$dirty && $v.note.$error ? 'is-invalid' : ''"
                :initial="note"
                :is-read-only="readOnly">
                {{ $t('message.onBoarding.devices.note') }} {{
                  (condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') && !readOnly ? '*' : ''
                }}
              </coozzy-form-textarea>
            </div>
          </div>
          <div v-if="!fromHandover" class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
        </template>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-inspection
              ref="device-inspection"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div v-if="!fromHandover" class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-maintenance
              ref="device-maintenance"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div v-if="!fromHandover" class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div
          v-if="!fromHandover"
          v-show="renderDevices"
          class="row">
          <div class="col-12 pl-0">
            <device-revision
              ref="device-revision"
              :contacts-list="contactList"
              :device="device"
              :index="index"
              :read-only="readOnly"
              :target="target"
              @device-update="deviceUpdated" />
          </div>
        </div>
        <div
          v-if="renderDevices && !fromHandover"
          class="row">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <h6
              v-b-toggle="`collapse-device-${index}-picture`"
              class="d-inline-block font-weight-bold mt-3 width-only-content">
              {{ $t('message.generic.pictures') }}
            </h6>
          </div>
          <b-collapse
            v-if="renderDevices"
            :id="`collapse-device-${index}-picture`"
            :visible="openedCollapses.includes(`collapse-device-${index}-picture`)"
            class="w-100">
            <coozzy-spinner v-if="selectedDeviceImageMedia === null" />
            <template v-else>
              <div
                v-if="selectedDeviceImageMedia.length === 0"
                class="col-sm-12 col-md-3">
                <h6>{{ $t('message.generic.noPictures') }}</h6>
              </div>
              <draggable
                v-model="selectedDeviceImageMedia"
                :disabled="readOnly"
                class="col-sm-12 col-md-12 row"
                @end="endMoving">
                <div
                  v-for="(image, indexImage) in selectedDeviceImageMedia"
                  :key="image.media.id"
                  class="col-sm-12 col-md-2 mt-3">
                  <div
                    class="uploaded-file square">
                    <div
                      :class="['device-image-remove-container', 'cursor-pointer', 'hover', readOnly ? 'd-none' : '']"
                      @click="removeImageClicked(image.media)">
                      <coozzy-close-icon class="device-image-remove bg" />
                      <coozzy-close-icon class="device-image-remove" />
                    </div>
                    <coozzy-thumbnail
                      :key="image.media.id"
                      v-viewer="Object.assign(viewerOptions, {url: 'original'})"
                      :original="image.media.url"
                      :size-restriction="false"
                      :src="getMediaUrl(image.media)"
                      class="device-image cursor-pointer thumbnail-viewer"
                      @imageClicked="imageClicked(indexImage)" />
                  </div>
                </div>
              </draggable>
              <div
                v-if="!readOnly && selectedDeviceImageMedia.length < 20"
                class="col-sm-12 col-md-2 mt-1">
                <coozzy-spinner v-if="imageUploadProcessing" />
                <coozzy-button
                  class="col-sm-4 col-lg-12 col-md-12"
                  design="prop-green"
                  @click="uploadImageClicked">
                  {{ $t('message.generic.addPicture') }}
                </coozzy-button>
              </div>

              <coozzy-form-file-input
                v-if="!readOnly"
                ref="imageUpload"
                accept="image/png,image/jpeg,image/jpg"
                class="d-none"
                multiple
                @change="addFile" />
            </template>
          </b-collapse>
        </div>
        <div v-if="!fromHandover" class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div v-if="!fromHandover" class="row">
          <div
            v-if="renderDevices"
            class="col-12">
            <h6
              v-b-toggle="`collapse-device-${index}-document`"
              class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
              {{ $t('message.generic.documents') }}
            </h6>
          </div>
        </div>
        <b-collapse
          v-if="renderDevices && !fromHandover"
          :id="`collapse-device-${index}-document`"
          :visible="openedCollapses.includes(`collapse-device-${index}-document`)"
          class="w-100">
          <div
            class="row">
            <div class="col-12 col-md-2">
              <add-document-modal
                :owner-id="device.ownerId"
                :suffix="'buildingDevice' + index"
                @document-created="documentCreated" />
              <coozzy-button
                v-if="!readOnly"
                class="w-100 mt-2"
                design="prop-green"
                @click="addDocumentClicked('buildingDevice' + index)">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
          <div
            class="row pr-3">
            <div
              class="col-12">
              <documents-list
                :documents-list="documents"
                :is-read-only="readOnly"
                @version-added="versionAdded"
                @document-deleted="deleteDocument" />
            </div>
          </div>
        </b-collapse>
        <div
          v-if="renderDevices && !readOnly && !fromHandover"
          class="row pr-3">
          <div class="col-12 mt-3">
            <coozzy-button
              v-if="!readOnly"
              class="float-right"
              design="red"
              size="small"
              @click="removeClicked">
              {{ $t('message.onBoarding.devices.deleteDevice') }}
            </coozzy-button>
            <coozzy-button
              v-if="duplicateButton && !readOnly"
              class="float-right mr-2"
              design="green"
              size="small"
              @click="duplicateClicked">
              {{ $t('message.generic.duplicate') }}
            </coozzy-button>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      v-if="renderDevices"
      :id="'deleteModal'"
      ref="deleteModal"
      :title="$t('message.onBoarding.deleteModals.confirmation')"
      hide-footer
      no-close-on-backdrop>
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyDevice') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="$bvModal.hide('deleteModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import DeviceMaintenance from './DeviceMaintenance'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import DeviceInspection from './DeviceInspection'
import { decimal, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { validation } from '@/mixins/validation'
import { VueAutosuggest } from 'vue-autosuggest'
import MediaApi from '@/misc/apis/MediaApi'
import RoomApi from '@/misc/apis/RoomApi'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { toastError } from '@/mixins/toastError'
import DeviceRevision from './DeviceRevision'
import draggable from 'vuedraggable'
import { routeChecks } from '@/mixins/routeChecks'
import Vue from 'vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import { dateUtils } from '@/mixins/dateUtils'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'

function moreThanInstallation(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.installation) {
    return true
  }

  return new Date(this.$options.filters.objectToDateInput(value)).getTime() >= new Date(this.$options.filters.objectToDateInput(vm.installation)).getTime()
}

export default {
  name: 'Device',
  components: {
    CoozzyThumbnail,
    CoozzyFormCheckbox,
    CoozzyDropdownItem,
    CoozzyDropdown,
    DocumentsList,
    CoozzyFormFileInput,
    CoozzyCloseIcon,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyFormTextarea,
    DeviceInspection,
    CoozzyButton,
    CoozzyFormInput,
    DeviceMaintenance,
    CoozzyFormInputNumeric,
    VueAutosuggest,
    AddDocumentModal,
    DeviceRevision,
    draggable
  },
  mixins: [onboarding, validationMixin, validation, toastError, dateUtils, routeChecks, user, media],
  props: {
    index: {
      type: Number,
      default: -1
    },
    fromHandover: {
      type: Boolean,
      default: false
    },
    handoverRooms: {
      type: Array,
      default: () => {
        return []
      }
    },
    ownerId: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: 'building'
    },
    device: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    fromLocal: {
      type: Boolean,
      default: false
    },
    renderDevices: {
      type: Boolean,
      default: true
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    externalContactList: {
      type: Array,
      default: () => {
        return []
      }
    },
    duplicateButton: {
      type: Boolean,
      default: true
    },
    addNewDocument: {
      type: Boolean,
      default: false
    },
    propertyId: {
      type: String,
      default: 'building'
    }
  },
  data() {
    return {
      calculatedReplacement: '',
      selectedDeviceImageMedia: null,
      imageUpload: [],
      imageUploadProcessing: false,
      query: '',
      documentList: [],
      dates: {
        installation: null,
        uninstallation: null,
        warrantyEnd: null,
        measureUntil: null
      },
      typeSuggestFilteredOptions: [],
      typeValues: [
        'DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES',
        'DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE',
        'DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE',
        'DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER',
        'DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN',
        'DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR',
        'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR',
        'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD',
        'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL',
        'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL',
        'DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE',
        'DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL',
        'DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL',
        'DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE',
        'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR',
        'DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION',
        'DEVICE_TYPE_ROOFING_PENT_ROOF',
        'DEVICE_TYPE_ROOFING_SADDLE_ROOF',
        'DEVICE_TYPE_ROOFING_TERRACE',
        'DEVICE_TYPE_ROOFING_HIPPED_ROOF',
        'DEVICE_TYPE_ROOFING_TENT_ROOF',
        'DEVICE_TYPE_ROOFING_FLAT_ROOF',
        'DEVICE_TYPE_ROOFING_SHEARING_ROOF',
        'DEVICE_TYPE_ROOFING_FRONT_PANEL',
        'DEVICE_TYPE_SEALING_INSULATION_FACADE',
        'DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD',
        'DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES',
        'DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT',
        'DEVICE_TYPE_SEALING_INSULATION_INTERIOR',
        'DEVICE_TYPE_FACADE_PLASTER',
        'DEVICE_TYPE_FACADE_PLASTER_PAINTING',
        'DEVICE_TYPE_OUTER_FINISHES',
        'DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS',
        'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS',
        'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD',
        'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL',
        'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS',
        'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM',
        'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE',
        'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS',
        'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD',
        'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL',
        'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS',
        'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT',
        'DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION',
        'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN',
        'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS',
        'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD',
        'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING',
        'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION',
        'DEVICE_TYPE_LIGHTS_ILLUMINANT',
        'DEVICE_TYPE_LIGHTS',
        'DEVICE_TYPE_LIGHTS_CEILING',
        'DEVICE_TYPE_LIGHTS_CABINET',
        'DEVICE_TYPE_LIGHTS_SPOTS',
        'DEVICE_TYPE_LIGHTS_MIRROR_CABINET',
        'DEVICE_TYPE_TELEPHONE_CENTER',
        'DEVICE_TYPE_TELEPHONE_DISTRIBUTOR',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TELEPHONE_SWITCHBOARD',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TELEPHONE_DISTRIBUTOR',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA',
        'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE',
        'DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY',
        'DEVICE_TYPE_OTHER_ELECTRICITY_OTHER',
        'DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION',
        'DEVICE_TYPE_OTHER_ELECTRICITY',
        'DEVICE_TYPE_OTHER_ELECTRICITY_SWITCH',
        'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET',
        'DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION',
        'DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT',
        'DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING',
        'DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION',
        'DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY',
        'DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING',
        'DEVICE_TYPE_ENERGY_SUPPLY_TANK',
        'DEVICE_TYPE_ENERGY_SUPPLY_STORAGE',
        'DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE',
        'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE',
        'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED',
        'DEVICE_TYPE_HEAT_GENERATION',
        'DEVICE_TYPE_HEAT_GENERATION_BOILER',
        'DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER',
        'DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING',
        'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER',
        'DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES',
        'DEVICE_TYPE_HEAT_GENERATION_BURNER',
        'DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE',
        'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE',
        'DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE',
        'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING',
        'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION',
        'DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER',
        'DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER',
        'DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM',
        'DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER',
        'DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP',
        'DEVICE_TYPE_HEAT_GENERATION_FIREPLACE',
        'DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR',
        'DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS',
        'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT',
        'DEVICE_TYPE_HEAT_GENERATION_RADIATORS',
        'DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS',
        'DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS',
        'DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING',
        'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE',
        'DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING',
        'DEVICE_TYPE_HEAT_DISTRIBUTION',
        'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER',
        'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS',
        'DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR',
        'DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP',
        'DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER',
        'DEVICE_TYPE_VENTILATION_VENTILATION',
        'DEVICE_TYPE_VENTILATION_CONTROLLED_HOME',
        'DEVICE_TYPE_VENTILATION_CHANNELS',
        'DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN',
        'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN',
        'DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM',
        'DEVICE_TYPE_VENTILATION',
        'DEVICE_TYPE_AIR_CONDITIONING_RECOOLING',
        'DEVICE_TYPE_AIR_CONDITIONING',
        'DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS',
        'DEVICE_TYPE_COOLING_CHILLERS',
        'DEVICE_TYPE_COOLING_COLD_RESERVOIR',
        'DEVICE_TYPE_COOLING_COLD_WATER_TANK',
        'DEVICE_TYPE_COOLING_COLD_STORAGE_CELL',
        'DEVICE_TYPE_COOLING',
        'DEVICE_TYPE_SPECIAL_FACILITY',
        'DEVICE_TYPE_INSULATION',
        'DEVICE_TYPE_INSULATION_CHIMNEY',
        'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM',
        'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM',
        'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY',
        'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES',
        'DEVICE_TYPE_SANITARY_BATHTUB',
        'DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC',
        'DEVICE_TYPE_SANITARY_BATHTUB_STEEL',
        'DEVICE_TYPE_SANITARY_RENOVATION',
        'DEVICE_TYPE_SANITARY_CONTROL_FITTINGS',
        'DEVICE_TYPE_SANITARY_WASHBASIN',
        'DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC',
        'DEVICE_TYPE_SANITARY_MIRROR_CABINET',
        'DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC',
        'DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN',
        'DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL',
        'DEVICE_TYPE_SANITARY_BIDET',
        'DEVICE_TYPE_SANITARY_SHOWER',
        'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE',
        'DEVICE_TYPE_SANITARY_SHOWER_ROD',
        'DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL',
        'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER',
        'DEVICE_TYPE_SANITARY_LAVABO',
        'DEVICE_TYPE_SANITARY_URINAL',
        'DEVICE_TYPE_SANITARY_FLUSH_TANK',
        'DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED',
        'DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC',
        'DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC',
        'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL',
        'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC',
        'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT',
        'DEVICE_TYPE_SANITARY_SHOWER_CABIN',
        'DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC',
        'DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS',
        'DEVICE_TYPE_SANITARY_TOILET',
        'DEVICE_TYPE_SANITARY_TOILET_CERAMIC',
        'DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT',
        'DEVICE_TYPE_SANITARY_TOILET_COVER',
        'DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE',
        'DEVICE_TYPE_SPECIAL_SANITARY',
        'DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER',
        'DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION',
        'DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER',
        'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE',
        'DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER',
        'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER',
        'DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER',
        'DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE',
        'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK',
        'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER',
        'DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER',
        'DEVICE_TYPE_SANITARY_PIPING_WARM_WATER',
        'DEVICE_TYPE_SANITARY_PIPING',
        'DEVICE_TYPE_SANITARY_INSULATION',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE',
        'DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN',
        'DEVICE_TYPE_SANITARY_EQUIPMENT',
        'DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL',
        'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC',
        'DEVICE_TYPE_OTHER_SANITARY',
        'DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT',
        'DEVICE_TYPE_ELEVATOR_BED_LIFT',
        'DEVICE_TYPE_ELEVATOR',
        'DEVICE_TYPE_MERCHANDISE_LIFT',
        'DEVICE_TYPE_MOVING_STAIRCASE',
        'DEVICE_TYPE_FACADE_CLEANING',
        'DEVICE_TYPE_GOODS_CONVEYING_OTHER',
        'DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE',
        'DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT',
        'DEVICE_TYPE_GOODS_CONVEYING',
        'DEVICE_TYPE_LIFTING',
        'DEVICE_TYPE_PARKING',
        'DEVICE_TYPE_TRANSPORT_OTHER',
        'DEVICE_TYPE_PLASTERER',
        'DEVICE_TYPE_METAL',
        'DEVICE_TYPE_METAL_CAR_SHELTER',
        'DEVICE_TYPE_METAL_MAILBOX',
        'DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT',
        'DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER',
        'DEVICE_TYPE_CARPENTER_CELLAR_DOOR',
        'DEVICE_TYPE_CARPENTER_APARTMENT_DOOR',
        'DEVICE_TYPE_CARPENTER_DOOR',
        'DEVICE_TYPE_CARPENTER_DOOR_WOODEN',
        'DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL',
        'DEVICE_TYPE_CARPENTER_DOOR_METAL',
        'DEVICE_TYPE_CARPENTER_DOOR_FRAME',
        'DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD',
        'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE',
        'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL',
        'DEVICE_TYPE_CARPENTER_LOCKS',
        'DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR',
        'DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR',
        'DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT',
        'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS',
        'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS',
        'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN',
        'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS',
        'DEVICE_TYPE_CARPENTER_SLIDING_DOOR',
        'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL',
        'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS',
        'DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL',
        'DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR',
        'DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR',
        'DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR',
        'DEVICE_TYPE_CARPENTER_SHELF',
        'DEVICE_TYPE_CARPENTER_SHELF_WOOD',
        'DEVICE_TYPE_CARPENTER_SHELF_METAL',
        'DEVICE_TYPE_CARPENTER_CLOSET',
        'DEVICE_TYPE_CARPENTER_CLOSET_WOOD',
        'DEVICE_TYPE_CARPENTER_CLOSET_METAL',
        'DEVICE_TYPE_CARPENTER',
        'DEVICE_TYPE_WINDOW_DOUBLE_GLAZING',
        'DEVICE_TYPE_WINDOW_INSULATING_GLAZING',
        'DEVICE_TYPE_WINDOW_FITTINGS',
        'DEVICE_TYPE_WINDOW_WINDOW_FRAME',
        'DEVICE_TYPE_WINDOW_SPECIAL_GLAZING',
        'DEVICE_TYPE_WINDOW_WINDOW_SILL',
        'DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD',
        'DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED',
        'DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES',
        'DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE',
        'DEVICE_TYPE_WINDOW',
        'DEVICE_TYPE_WINDOW_TRIPLE',
        'DEVICE_TYPE_LOCKING_LOCK',
        'DEVICE_TYPE_LOCKING_KEY',
        'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER',
        'DEVICE_TYPE_LOCKING',
        'DEVICE_TYPE_INTERIOR_FINISHES',
        'DEVICE_TYPE_ELEMENT_WALL',
        'DEVICE_TYPE_CHROME_STEEP_FINISH',
        'DEVICE_TYPE_OTHER_INTERIOR1',
        'DEVICE_TYPE_FLOORING_COVERING',
        'DEVICE_TYPE_FLOORING_BALL_YARN',
        'DEVICE_TYPE_FLOORING_LAMINATE',
        'DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY',
        'DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY',
        'DEVICE_TYPE_FLOORING_LINOLEUM',
        'DEVICE_TYPE_FLOORING_VINYL',
        'DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET',
        'DEVICE_TYPE_FLOORING_PARQUET',
        'DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE',
        'DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD',
        'DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD',
        'DEVICE_TYPE_FLOORING_PARQUET_FOURNIER',
        'DEVICE_TYPE_FLOORING_PARQUET_SEALING',
        'DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY',
        'DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR',
        'DEVICE_TYPE_FLOORING_TILES',
        'DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES',
        'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT',
        'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD',
        'DEVICE_TYPE_FLOORING_TILES_CERAMIC',
        'DEVICE_TYPE_FLOORING_TILES_STONEWARE',
        'DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE',
        'DEVICE_TYPE_FLOORING_CARPET',
        'DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER',
        'DEVICE_TYPE_FLOORING_CARPET_BALL_YARN',
        'DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT',
        'DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY',
        'DEVICE_TYPE_FLOORING_SKIRTING',
        'DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC',
        'DEVICE_TYPE_FLOORING_SKIRTING_WOOD',
        'DEVICE_TYPE_FLOORING_JOINT',
        'DEVICE_TYPE_FLOORING',
        'DEVICE_TYPE_FLOORING_PVC',
        'DEVICE_TYPE_FLOORING_RUBBER',
        'DEVICE_TYPE_FLOORING_CORK',
        'DEVICE_TYPE_FLOORING_RAW',
        'DEVICE_TYPE_FLOORING_COATED',
        'DEVICE_TYPE_WALL_COVERING_WORK',
        'DEVICE_TYPE_WALL_COVERING_WALLPAPER',
        'DEVICE_TYPE_WALL_WALLPAPER_MEDIUM_QUALITY',
        'DEVICE_TYPE_WALL_WALLPAPER_GOOD_QUALITY',
        'DEVICE_TYPE_WALL_WALLPAPER_GLASS_FIBER',
        'DEVICE_TYPE_WALL_COVERING_PLASTER',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING',
        'DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT',
        'DEVICE_TYPE_WALL_COVERING_ABRASION',
        'DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC',
        'DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT',
        'DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER',
        'DEVICE_TYPE_WALL_COVERING_WOOD_WORKER',
        'DEVICE_TYPE_WALL_WOOD_WORKER_WALL_ROUGH',
        'DEVICE_TYPE_WALL_WOOD_WORKER_LASED',
        'DEVICE_TYPE_WALL_WOOD_WORKER_PAINTED_OPAQUE',
        'DEVICE_TYPE_WALL_WOOD_WORKER_GLAZE',
        'DEVICE_TYPE_WALL_COVERING',
        'DEVICE_TYPE_WALL_COVERING_CERAMIC',
        'DEVICE_TYPE_WALL_COVERING_STONEWORK',
        'DEVICE_TYPE_WALL_COVERING_STONEWARE',
        'DEVICE_TYPE_WALL_COVERING_TILES',
        'DEVICE_TYPE_CEILING_WORK',
        'DEVICE_TYPE_CEILING_WALLPAPER',
        'DEVICE_TYPE_CEILING_PLASTER',
        'DEVICE_TYPE_CEILING_ABRASION',
        'DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC',
        'DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT',
        'DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER',
        'DEVICE_TYPE_CEILING_WOOD_WORKER',
        'DEVICE_TYPE_CEILING',
        'DEVICE_TYPE_CEILING_REGULAR',
        'DEVICE_TYPE_CEILING_REGULAR_METAL',
        'DEVICE_TYPE_CEILING_REGULAR_WOOD',
        'DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING',
        'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER',
        'DEVICE_TYPE_OTHER_INTERIOR2_BLADE',
        'DEVICE_TYPE_OTHER_INTERIOR2_BELT',
        'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER_MOTOR',
        'DEVICE_TYPE_OTHER_INTERIOR2_ELECTRIC',
        'DEVICE_TYPE_OTHER_INTERIOR2_CRANK',
        'DEVICE_TYPE_OTHER_INTERIOR2_BLIND',
        'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX',
        'DEVICE_TYPE_OTHER_INTERIOR2',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_WASHBASIN_BOWL',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE',
        'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE',
        'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SINGLE_LEVER',
        'DEVICE_TYPE_OTHER_INTERIOR2_DOUBLE_LEVER',
        'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SWIVEL',
        'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_PULL_OUT_SPRAY',
        'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_THERMAL_MIXER',
        'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_CHROME_STEEL',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_PLASTIC',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_HOOK',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_CHROME_STEEL',
        'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_PLASTIC',
        'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER',
        'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN',
        'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK',
        'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER',
        'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH',
        'DEVICE_TYPE_GARDENING_FENCE',
        'DEVICE_TYPE_GARDENING_WALLS',
        'DEVICE_TYPE_GARDENING_CEMENT_BOARD',
        'DEVICE_TYPE_GARDENING_ACCESS_PATH',
        'DEVICE_TYPE_GARDENING_LAWN',
        'DEVICE_TYPE_GARDENING',
        'DEVICE_TYPE_FENCING',
        'DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE',
        'DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND',
        'DEVICE_TYPE_SUPERSTRUCTURE',
        'DEVICE_TYPE_SUPERSTRUCTURE_RAILING',
        'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS',
        'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL',
        'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL',
        'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE',
        'DEVICE_TYPE_PLAYGROUND',
        'DEVICE_TYPE_PLAYGROUND_DEVICE',
        'DEVICE_TYPE_GARDEN_OTHER',
        'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER',
        'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER',
        'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA',
        'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER'
      ],
      systemValues: {
        DEVICE_FACILITY_SHELL_CONSTRUCTION1: ['DEVICE_SYSTEM_MASTER_BUILDER_WORK'],
        DEVICE_FACILITY_SHELL_CONSTRUCTION2: ['DEVICE_SYSTEM_WINDOW_DOOR', 'DEVICE_SYSTEM_ROOFING', 'DEVICE_SYSTEM_SEALING_INSULATION', 'DEVICE_SYSTEM_FACADE_PLASTER', 'DEVICE_SYSTEM_OUTER_FINISHES'],
        DEVICE_FACILITY_PLANT_ELECTRICITY: ['DEVICE_SYSTEM_HEAVY_CURRENT_INSTALLATION', 'DEVICE_SYSTEM_LIGHTS', 'DEVICE_SYSTEM_TELEPHONE', 'DEVICE_SYSTEM_LOW_CURRENT_INSTALLATION', 'DEVICE_SYSTEM_OTHER_ELECTRICITY'],
        DEVICE_FACILITY_PLANT_AIR_CONDITIONING: ['DEVICE_SYSTEM_HEATING_TRANSITION_POSITION', 'DEVICE_SYSTEM_ENERGY_SUPPLY', 'DEVICE_SYSTEM_HEAT_GENERATION', 'DEVICE_SYSTEM_HEAT_DISTRIBUTION', 'DEVICE_SYSTEM_VENTILATION', 'DEVICE_SYSTEM_AIR_CONDITIONING', 'DEVICE_SYSTEM_COOLING', 'DEVICE_SYSTEM_SPECIAL_FACILITY', 'DEVICE_SYSTEM_INSULATION', 'DEVICE_SYSTEM_OTHER_HEATING'],
        DEVICE_FACILITY_PLANT_SANITARY: ['DEVICE_SYSTEM_SANITARY', 'DEVICE_SYSTEM_SPECIAL_SANITARY', 'DEVICE_SYSTEM_SANITARY_SUPPLY_DISPOSAL', 'DEVICE_SYSTEM_SANITARY_PIPING', 'DEVICE_SYSTEM_SANITARY_INSULATION', 'DEVICE_SYSTEM_SANITARY_EQUIPMENT', 'DEVICE_SYSTEM_ELECTRIC_PNEUMATIC_BOARD', 'DEVICE_SYSTEM_KITCHEN_EQUIPMENT', 'DEVICE_SYSTEM_OTHER_SANITARY'],
        DEVICE_FACILITY_PLANT_TRANSPORT_WAREHOUSE: ['DEVICE_SYSTEM_ELEVATOR', 'DEVICE_SYSTEM_MOVING_STAIRCASE', 'DEVICE_SYSTEM_FACADE_CLEANING', 'DEVICE_SYSTEM_GOODS_CONVEYING', 'DEVICE_SYSTEM_LIFTING', 'DEVICE_SYSTEM_PARKING', 'DEVICE_SYSTEM_TRANSPORT_OTHER', 'DEVICE_SYSTEM_OTHER_TRANSPORT'],
        DEVICE_FACILITY_PLANT_INTERIOR1: ['DEVICE_SYSTEM_PLASTERER', 'DEVICE_SYSTEM_METAL', 'DEVICE_SYSTEM_CARPENTER', 'DEVICE_SYSTEM_WINDOW', 'DEVICE_SYSTEM_LOCKING', 'DEVICE_SYSTEM_INTERIOR_FINISHES', 'DEVICE_SYSTEM_ELEMENT_WALL', 'DEVICE_SYSTEM_CHROME_STEEL_FACILITY', 'DEVICE_SYSTEM_OTHER_INTERIOR1'],
        DEVICE_FACILITY_PLANT_INTERIOR2: ['DEVICE_SYSTEM_FLOORING', 'DEVICE_SYSTEM_WALL_COVERING', 'DEVICE_SYSTEM_CEILING', 'DEVICE_SYSTEM_INTERIOR_SURFACE_TREATMENTS', 'DEVICE_SYSTEM_OTHER_INTERIOR2'],
        DEVICE_FACILITY_PLANT_GARDEN: ['DEVICE_SYSTEM_GARDEN_TRANSITION_POSITION', 'DEVICE_SYSTEM_GARDENING', 'DEVICE_SYSTEM_FENCING', 'DEVICE_SYSTEM_SUPERSTRUCTURE', 'DEVICE_SYSTEM_PLAYGROUND', 'DEVICE_SYSTEM_GARDEN_OTHER'],
        DEVICE_FACILITY_PLANT_DEVICE: ['DEVICE_SYSTEM_DEVICE_TRANSITION_POSITION']
      },
      facilityPlantValues: [
        'DEVICE_FACILITY_SHELL_CONSTRUCTION1',
        'DEVICE_FACILITY_SHELL_CONSTRUCTION2',
        'DEVICE_FACILITY_PLANT_ELECTRICITY',
        'DEVICE_FACILITY_PLANT_AIR_CONDITIONING',
        'DEVICE_FACILITY_PLANT_SANITARY',
        'DEVICE_FACILITY_PLANT_TRANSPORT_WAREHOUSE',
        'DEVICE_FACILITY_PLANT_INTERIOR1',
        'DEVICE_FACILITY_PLANT_INTERIOR2',
        'DEVICE_FACILITY_PLANT_GARDEN',
        'DEVICE_FACILITY_PLANT_DEVICE'
      ],
      typeSuggestInputProps: {
        id: 'autosuggest__input',
        placeholder: this.$t('message.onBoarding.devices.searchType'),
        class: 'custom-select'
      },
      unitOptions: [
        'DEVICE_UNIT_UNDEFINED',
        'DEVICE_UNIT_L',
        'DEVICE_UNIT_KG',
        'DEVICE_UNIT_M2',
        'DEVICE_UNIT_M3',
        'DEVICE_UNIT_PCS',
        'DEVICE_UNIT_M',
        'DEVICE_UNIT_FLAT_RATE'
      ],
      conditionOptions: [
        'DEVICE_CONDITION_UNDEFINED',
        'DEVICE_CONDITION_PERFECT',
        'DEVICE_CONDITION_NORMAL_WEAR',
        'DEVICE_CONDITION_DEFICIENCY'
      ],
      listRooms: [],
      closed: false,
      viewer: null
    }
  },
  computed: {
    calculatedReplacementSet: {
      get() {
        return this.calculatedReplacement
      },
      set(value) {
        this.calculatedReplacement = value
      }
    },
    roomsOption() {
      if (this.fromHandover) {
        return this.rooms
      } else if (this.target !== 'building') {
        return this.getFilteredObjectRooms(this.device.referenceId)
      } else {
        return this.rooms.filter(x => x.referenceId === this.device.referenceId)
      }
    },
    contactList() {
      const contacts = this.contacts && this.contacts.length > 0 ? this.contacts : this.externalContactList
      return contacts.filter(c => c.types && !c.types.includes('EMPLOYEE'))
    },
    roomId: {
      get() {
        return this.device.roomId !== '' ? this.device.roomId : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.roomId = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    type: {
      get() {
        return this.device.type !== 'DEVICE_TYPE_UNDEFINED' ? this.device.type : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.type = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    system: {
      get() {
        return this.device.system !== 'DEVICE_SYSTEM_UNDEFINED' ? this.device.system : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.system = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    manufacturer: {
      get() {
        return this.device.manufacturer
      },
      set(value) {
        const newDevice = this.device
        newDevice.manufacturer = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    brand: {
      get() {
        return this.device.brand
      },
      set(value) {
        const newDevice = this.device
        newDevice.brand = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    model: {
      get() {
        return this.device.model
      },
      set(value) {
        const newDevice = this.device
        newDevice.model = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    serialNumber: {
      get() {
        return this.device.serialNumber
      },
      set(value) {
        const newDevice = this.device
        newDevice.serialNumber = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    installation: {
      get() {
        return this.device.installation || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.installation = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    uninstallation: {
      get() {
        return this.device.uninstallation || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.uninstallation = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    warrantyEnd: {
      get() {
        return this.device.warrantyEnd || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.warrantyEnd = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    measureUntil: {
      get() {
        return this.device.measureUntil || null
      },
      set(value) {
        const newDevice = this.device
        newDevice.measureUntil = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    measure: {
      get() {
        return this.device.measure !== 'DEVICE_MEASURE_UNDEFINED' ? this.device.measure : null
      },
      set(value) {
        const newDevice = this.device
        if (value === true) {
          newDevice.measure = 'DEVICE_MEASURE_IMMEDIATELY'
        } else {
          newDevice.measure = 'DEVICE_MEASURE_UNDEFINED'
        }
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    facilityPlant: {
      get() {
        return this.device.facilityPlant !== 'DEVICE_FACILITY_PLANT_UNDEFINED' ? this.device.facilityPlant : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.facilityPlant = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    bkpH: {
      get() {
        return this.device.bkpH
      },
      set(value) {
        const newDevice = this.device
        newDevice.bkpH = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    acquisitionCosts: {
      get() {
        return this.device.acquisitionCosts !== -1 ? this.device.acquisitionCosts : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.acquisitionCosts = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    cataloguePrice: {
      get() {
        return this.device.cataloguePrice !== -1 ? this.device.cataloguePrice : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.cataloguePrice = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    guidePriceSum() {
      return (this.amount !== null ? this.amount : 0) *
        (this.cataloguePrice !== null ? this.cataloguePrice : 0)
    },
    acquisitionCostSum() {
      return (this.amount !== null ? this.amount : 0) *
        (this.acquisitionCosts !== null ? this.acquisitionCosts : 0)
    },
    unit: {
      get() {
        return this.device.unit
      },
      set(value) {
        const newDevice = this.device
        newDevice.unit = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    note: {
      get() {
        return this.device.note
      },
      set(value) {
        const newDevice = this.device
        newDevice.note = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    amount: {
      get() {
        return this.device.amount !== -1 ? this.device.amount : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.amount = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    lifeSpan: {
      get() {
        return this.device.lifeSpan !== -1 ? this.device.lifeSpan : null
      },
      set(value) {
        const newDevice = this.device
        newDevice.lifeSpan = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    description: {
      get() {
        return this.device.lockingKey.description !== '' ? this.device.lockingKey.description : ''
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.description = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    lockingPlanNumber: {
      get() {
        return this.device.lockingKey.lockingPlanNumber !== '' ? this.device.lockingKey.lockingPlanNumber : ''
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.lockingPlanNumber = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    combinedKeys: {
      get() {
        return this.device.lockingKey.combinedKeys
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.combinedKeys = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    combinedKeysNote: {
      get() {
        return this.device.lockingKey.combinedKeysNote
      },
      set(value) {
        const newDevice = this.device
        newDevice.lockingKey.combinedKeysNote = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    condition: {
      get() {
        return this.device.condition
      },
      set(value) {
        const newDevice = this.device
        newDevice.condition = value
        this.dispatchDeviceUpdate(newDevice)
      }
    },
    documents() {
      if (this.target !== 'property' && this.fromLocal === false) {
        return this.target === 'building'
          ? this.getFilteredBuildingDeviceDocuments(this.device.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
          : this.getFilteredObjectDeviceDocuments(this.device.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
      } else {
        const doc = this.documentList
        return doc.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
    },
    availableSuggestions() {
      const array = []
      this.facilityPlantValues.forEach(plant => {
        this.systemValues[plant].forEach(sys => {
          const system = sys.substring(14)
          this.typeValues
            .filter(x => {
              // Because system WINDOW and WINDOW_DOOR start with same key we must add a check
              if (system === 'WINDOW') {
                if (x.startsWith('DEVICE_TYPE_WINDOW_DOOR')) {
                  return false
                }
              }
              // Because system SANITARY and 4 devices types start with same key we must add a check
              if (system === 'SANITARY') {
                if (x.startsWith('DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL') ||
                  x.startsWith('DEVICE_TYPE_SANITARY_PIPING') ||
                  x.startsWith('DEVICE_TYPE_SANITARY_INSULATION') ||
                  x.startsWith('DEVICE_TYPE_SANITARY_EQUIPMENT')) {
                  return false
                }
              }
              return x.startsWith(`DEVICE_TYPE_${system}`)
            })
            .forEach(type => {
              array.push({
                bkp: this.getBkp(sys),
                facilityPlant: plant,
                system: sys,
                type: type,
                lifeSpan: this.getLifeSpan(type),
                cataloguePrice: this.getCataloguePrice(type),
                unit: this.getUnit(type),
                typeText: this.$t('message.onBoarding.devices.typeValues.' + type),
                text: this.$t('message.onBoarding.devices.typeValues.' + type) + ' - ' +
                  this.$t('message.onBoarding.devices.systemValues.' + sys) + ' - ' +
                  this.$t('message.onBoarding.devices.facilityPlant.' + plant)
              })
            })
        })
      })
      return array
    },
    filteredSuggestions() {
      return [{
        data: this.availableSuggestions.filter(suggestion => {
          return suggestion.text.toLowerCase().indexOf(this.query.toLowerCase()) > -1
        }).sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      }]
    },
    translatedFacilityPlant() {
      if (this.facilityPlant) {
        return this.$t('message.onBoarding.devices.facilityPlant.' + this.facilityPlant)
      }
      return null
    },
    translatedSystem() {
      if (this.system) {
        return this.$t('message.onBoarding.devices.systemValues.' + this.system)
      }
      return null
    },
    translatedType() {
      return this.$t('message.onBoarding.devices.typeValues.' + this.type)
    },
    title() {
      if (this.type && this.type !== 'DEVICE_TYPE_UNDEFINED') {
        return this.translatedType + (this.uninstallation !== null ? ' - ' + this.$t('message.onBoarding.devices.filter.uninstalled') : '')
      } else {
        return this.$t('message.onBoarding.devices.newDevice')
      }
    },
    rooms() {
      if (this.fromLocal === true || this.fromHandover === true) {
        return this.listRooms
      } else {
        return this.buildingRooms
      }
    }
  },
  watch: {
    currentLanguage: function () {
      if (this.type) {
        this.query = this.translatedType
      }
    },
    measure: function (newValue) {
      const dateUntil = new Date()
      if (newValue) {
        dateUntil.setDate(dateUntil.getDate() + 14)
        this.measureUntil = this.toObject(dateUntil)
        this.dates.measureUntil = this.$options.filters.objectToDateInput(this.measureUntil)
      }
    },
    renderDevices: {
      deep: true,
      handler: function (value) {
        if (value === true && this.selectedDeviceImageMedia === null) {
          this.loadDeviceImages(this.device)
        }
      }
    },
    // device: {
    //   deep: true,
    //   handler: function (newValue, oldValue) {
    //     if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    //       newValue.modified = true
    //       this.$emit('change-device', newValue)
    //     }
    //   }
    // },
    installation: function (newValue) {
      if (this.lifeSpan !== null && newValue !== null) {
        this.fillOutCalculatedReplacement()
      }
    }
  },
  mounted() {
    document.body.onmousedown = function (e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.dates.installation = this.$options.filters.objectToDateInput(this.installation)
    this.dates.uninstallation = this.$options.filters.objectToDateInput(this.uninstallation)
    this.dates.warrantyEnd = this.$options.filters.objectToDateInput(this.warrantyEnd)
    if (this.lifeSpan !== null && this.installation !== null) {
      this.fillOutCalculatedReplacement()
    }
    if (this.measureUntil) {
      this.dates.measureUntil = this.$options.filters.objectToDateInput(this.measureUntil)
    }
    if (this.type) {
      this.query = this.translatedType
    }
    if (this.target === 'property' || this.fromLocal === true) {
      MediaApi.listDocuments(this.device.documentIds)
        .then(documents => {
          this.documentList = documents.documents
        })
    }
    if (this.fromLocal === true) {
      RoomApi.getRoomsByReferenceIds([this.device.referenceId])
        .then(response => {
          this.listRooms = response.rooms
        })
    }
    if (this.fromHandover) {
      this.listRooms = this.handoverRooms
    }
    if (this.renderDevices) {
      this.loadDeviceImages(this.device)
    }
    if (!this.readOnly && this.addNewDocument) {
      this.addDocumentClicked('buildingDevice' + this.index)
    }
    this.$nextTick(() => {
      const element = this.$el.querySelector('.thumbnail-viewer')
      this.viewer = element?.$viewer
    })
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    async addFile(event) {
      let files = await this.getFilesFromEvent(event)
      let notImageFiles = []
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          if (!['image/png', 'image/jpeg', 'image/jpg'].includes(element.type)) {
            notImageFiles.push(index)
          }
          if (notImageFiles.filter(x => x === index).length === 0) {
            this.imageUpload.push(element)
          }
        }
      }

      if (this.imageUpload.length > 0) {
        await this.sendFile()
      }
      if (notImageFiles.length > 0) {
        Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
      }
    },
    async sendFile() {
      this.imageUploadProcessing = true
      const response = await this.uploadMedia(this.imageUpload)
      if (response.length > 0) {
        // Update vuex store
        const device = this.device
        for (const media of response) {
          this.selectedDeviceImageMedia.push({
            media: media,
            mediaId: media.id
          })
          device.images.push({
            mediaId: media.id,
            order: this.selectedDeviceImageMedia.length - 1
          })
        }
        this.dispatchDeviceUpdate(device)
        this.imageUpload = []
        this.imageUploadProcessing = false
      }
      this.$nextTick(() => {
        this.imageUploadProcessing = false
      })
    },
    goToEditMode() {
      let routeLink = null
      localStorage.setItem('deviceIdNewDocument', this.device.id)
      if (this.$route.name?.includes('PropertyDetailsView')) {
        if (JSON.stringify(this.$route.query) === '{}' || this.$route.query?.view === 'property') {
          routeLink = {
            name: this.moduleRoutePrefix + 'PropertyEditingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'property',
              elementSelected: this.elementSelected
            }
          }
        } else if (this.$route.query?.view === 'devices') {
          routeLink = {
            name: this.moduleRoutePrefix + 'PropertyEditingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'devices',
              elementSelected: this.device.id
            }
          }
        }
      } else if (this.$route.name?.includes('BuildingDetailsView')) {
        if (this.$route.query.view === 'building') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'building'
            }
          }
        } else if (this.$route.query.view === 'objects') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'objects',
              elementSelected: this.device.referenceId
            }
          }
        } else if (this.$route.query.view === 'devices') {
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'devices',
              elementSelected: this.device.id
            }
          }
        }
      }
      this.$router.push(routeLink)
    },
    openActivities(device) {
      if (device !== null && device !== undefined) {
        if (this.target !== 'property') {
          if (this.isOwnerModule) {
            this.$router.replace({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            this.$router.replace({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            this.$router.replace({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            this.$router.replace({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        } else if (device.referenceId.startsWith('prop')) {
          this.$emit('selected-device-for-history', device)
          // if (this.isOwnerModule) {
          //   this.$router.replace({
          //     name: 'OwnerPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAdminModule) {
          //   this.$router.replace({
          //     name: 'AdminPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAssetModule) {
          //   this.$router.replace({
          //     name: 'AssetPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // } else if (this.isAccountingModule) {
          //   this.$router.replace({
          //     name: 'AccountingPropertyDetailsView',
          //     params: { id: device.referenceId },
          //     query: {
          //       view: 'history',
          //       elementSelected: device.id
          //     }
          //   })
          // }
        }
      }
    },
    openActivitiesMiddle(device) {
      let routeData = ''
      if (device !== null && device !== undefined) {
        if (this.target !== 'property') {
          if (this.isOwnerModule) {
            routeData = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            routeData = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            routeData = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            routeData = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        } else if (device.referenceId.startsWith('prop')) {
          this.$emit('selected-device-for-history', device)
          if (this.isOwnerModule) {
            routeData = this.$router.resolve({
              name: 'OwnerPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            routeData = this.$router.resolve({
              name: 'AdminPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            routeData = this.$router.resolve({
              name: 'AssetPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            routeData = this.$router.resolve({
              name: 'AccountingPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        }
      }
      window.open(routeData.href, '_blank')
    },
    createTicket(type) {
      const defaultAssignee = this.ownerId
      const list = [this.device.id]
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee,
            ticketType: type
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketCreation',
          query: {
            fromBulk: true,
            ids: list,
            defaultAssignee: defaultAssignee,
            ticketType: type
          }
        })
      }
    },
    getRoomName(roomId) {
      const room = this.rooms.concat(this.objectRooms).find(x => x.id === roomId)
      return room ? room.name : '-'
    },
    onBlurLifeSpan() {
      if (this.lifeSpan !== null && this.installation !== null) {
        this.fillOutCalculatedReplacement()
      }
    },
    fillOutCalculatedReplacement() {
      let date = new Date(this.dates.installation)
      this.calculatedReplacement = date.getFullYear() + parseInt(this.lifeSpan)
    },
    getBkp(system) {
      switch (system) {
        case 'DEVICE_SYSTEM_MASTER_BUILDER_WORK':
          return '211'
        case 'DEVICE_SYSTEM_WINDOW_DOOR':
          return '221'
        case 'DEVICE_SYSTEM_ROOFING':
          return '224'
        case 'DEVICE_SYSTEM_SEALING_INSULATION':
          return '225'
        case 'DEVICE_SYSTEM_FACADE_PLASTER':
          return '226'
        case 'DEVICE_SYSTEM_OUTER_FINISHES':
          return '228'
        case 'DEVICE_SYSTEM_HEAVY_CURRENT_INSTALLATION':
          return '232'
        case 'DEVICE_SYSTEM_LIGHTS':
          return '233'
        case 'DEVICE_SYSTEM_TELEPHONE':
          return '235'
        case 'DEVICE_SYSTEM_LOW_CURRENT_INSTALLATION':
          return '236'
        case 'DEVICE_SYSTEM_OTHER_ELECTRICITY':
          return '239'
        case 'DEVICE_SYSTEM_HEATING_TRANSITION_POSITION':
          return '240'
        case 'DEVICE_SYSTEM_ENERGY_SUPPLY':
          return '241'
        case 'DEVICE_SYSTEM_HEAT_GENERATION':
          return '242'
        case 'DEVICE_SYSTEM_HEAT_DISTRIBUTION':
          return '243'
        case 'DEVICE_SYSTEM_VENTILATION':
          return '244'
        case 'DEVICE_SYSTEM_AIR_CONDITIONING':
          return '245'
        case 'DEVICE_SYSTEM_COOLING':
          return '246'
        case 'DEVICE_SYSTEM_SPECIAL_FACILITY':
          return '247'
        case 'DEVICE_SYSTEM_INSULATION':
          return '248'
        case 'DEVICE_SYSTEM_OTHER_HEATING':
          return '249'
        case 'DEVICE_SYSTEM_SANITARY':
          return '251'
        case 'DEVICE_SYSTEM_SPECIAL_SANITARY':
          return '252'
        case 'DEVICE_SYSTEM_SANITARY_SUPPLY_DISPOSAL':
          return '253'
        case 'DEVICE_SYSTEM_SANITARY_PIPING':
          return '254'
        case 'DEVICE_SYSTEM_SANITARY_INSULATION':
          return '255'
        case 'DEVICE_SYSTEM_SANITARY_EQUIPMENT':
          return '256'
        case 'DEVICE_SYSTEM_ELECTRIC_PNEUMATIC_BOARD':
          return '257'
        case 'DEVICE_SYSTEM_KITCHEN_EQUIPMENT':
          return '258'
        case 'DEVICE_SYSTEM_OTHER_SANITARY':
          return '259'
        case 'DEVICE_SYSTEM_ELEVATOR':
          return '261'
        case 'DEVICE_SYSTEM_MOVING_STAIRCASE':
          return '262'
        case 'DEVICE_SYSTEM_FACADE_CLEANING':
          return '263'
        case 'DEVICE_SYSTEM_GOODS_CONVEYING':
          return '264'
        case 'DEVICE_SYSTEM_LIFTING':
          return '265'
        case 'DEVICE_SYSTEM_PARKING':
          return '266'
        case 'DEVICE_SYSTEM_TRANSPORT_OTHER':
          return '269'
        case 'DEVICE_SYSTEM_PLASTERER':
          return '271'
        case 'DEVICE_SYSTEM_METAL':
          return '272'
        case 'DEVICE_SYSTEM_CARPENTER':
          return '273'
        case 'DEVICE_SYSTEM_WINDOW':
          return '274'
        case 'DEVICE_SYSTEM_LOCKING':
          return '275'
        case 'DEVICE_SYSTEM_INTERIOR_FINISHES':
          return '276'
        case 'DEVICE_SYSTEM_ELEMENT_WALL':
          return '277'
        case 'DEVICE_SYSTEM_CHROME_STEEL_FACILITY':
          return '278'
        case 'DEVICE_SYSTEM_OTHER_INTERIOR1':
          return '279'
        case 'DEVICE_SYSTEM_FLOORING':
          return '281'
        case 'DEVICE_SYSTEM_WALL_COVERING':
          return '282'
        case 'DEVICE_SYSTEM_CEILING':
          return '283'
        case 'DEVICE_SYSTEM_INTERIOR_SURFACE_TREATMENTS':
          return '285'
        case 'DEVICE_SYSTEM_OTHER_INTERIOR2':
          return '289'
        case 'DEVICE_SYSTEM_OTHER_TRANSPORT':
          return '369'
        case 'DEVICE_SYSTEM_GARDEN_TRANSITION_POSITION':
          return '420'
        case 'DEVICE_SYSTEM_GARDENING':
          return '421'
        case 'DEVICE_SYSTEM_FENCING':
          return '422'
        case 'DEVICE_SYSTEM_SUPERSTRUCTURE':
          return '423'
        case 'DEVICE_SYSTEM_PLAYGROUND':
          return '424'
        case 'DEVICE_SYSTEM_GARDEN_OTHER':
          return '429'
        case 'DEVICE_SYSTEM_DEVICE_TRANSITION_POSITION':
          return '930'
      }
    },
    getLifeSpan(type) {
      switch (type) {
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD':
          return 30
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL':
          return 30
        case 'DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL':
          return 25
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL':
          return 30
        case 'DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION':
          return -1
        case 'DEVICE_TYPE_ROOFING_SHEARING_ROOF':
          return 50
        case 'DEVICE_TYPE_ROOFING_PENT_ROOF':
          return 50
        case 'DEVICE_TYPE_ROOFING_SADDLE_ROOF':
          return 50
        case 'DEVICE_TYPE_ROOFING_TERRACE':
          return 20
        case 'DEVICE_TYPE_ROOFING_HIPPED_ROOF':
          return 50
        case 'DEVICE_TYPE_ROOFING_TENT_ROOF':
          return 50
        case 'DEVICE_TYPE_ROOFING_FLAT_ROOF':
          return 30
        case 'DEVICE_TYPE_ROOFING_FRONT_PANEL':
          return 30
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD':
          return 30
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES':
          return 30
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT':
          return 40
        case 'DEVICE_TYPE_SEALING_INSULATION_INTERIOR':
          return -1
        case 'DEVICE_TYPE_FACADE_PLASTER_PAINTING':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD':
          return 25
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL':
          return 30
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM':
          return 25
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE':
          return 30
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD':
          return 30
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL':
          return 40
        case 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION':
          return -1
        case 'DEVICE_TYPE_LIGHTS_ILLUMINANT':
          return -1
        case 'DEVICE_TYPE_LIGHTS':
          return -1
        case 'DEVICE_TYPE_TELEPHONE_CENTER':
          return -1
        case 'DEVICE_TYPE_TELEPHONE_DISTRIBUTOR':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM':
          return 20
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN':
          return 10
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET':
          return 15
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA':
          return 10
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE':
          return 10
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_OTHER':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION':
          return 20
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING':
          return 15
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_TANK':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_STORAGE':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE':
          return 30
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED':
          return 20
        case 'DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE':
          return 50
        case 'DEVICE_TYPE_HEAT_GENERATION':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER':
          return 15
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_BURNER':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE':
          return 40
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING':
          return 25
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_FIREPLACE':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS':
          return 15
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR':
          return 15
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS':
          return 20
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS':
          return 50
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS':
          return 30
        case 'DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING':
          return 30
        case 'DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER':
          return 20
        case 'DEVICE_TYPE_VENTILATION_VENTILATION':
          return 20
        case 'DEVICE_TYPE_VENTILATION_CONTROLLED_HOME':
          return 20
        case 'DEVICE_TYPE_VENTILATION_CHANNELS':
          return 25
        case 'DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN':
          return -1
        case 'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN':
          return -1
        case 'DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM':
          return -1
        case 'DEVICE_TYPE_VENTILATION':
          return -1
        case 'DEVICE_TYPE_AIR_CONDITIONING_RECOOLING':
          return -1
        case 'DEVICE_TYPE_AIR_CONDITIONING':
          return 15
        case 'DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS':
          return 15
        case 'DEVICE_TYPE_COOLING_CHILLERS':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_RESERVOIR':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_WATER_TANK':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_STORAGE_CELL':
          return -1
        case 'DEVICE_TYPE_COOLING':
          return -1
        case 'DEVICE_TYPE_SPECIAL_FACILITY':
          return -1
        case 'DEVICE_TYPE_INSULATION':
          return -1
        case 'DEVICE_TYPE_INSULATION_CHIMNEY':
          return 25
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY':
          return 20
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES':
          return 20
        case 'DEVICE_TYPE_SANITARY_BATHTUB':
          return -1
        case 'DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC':
          return 25
        case 'DEVICE_TYPE_SANITARY_BATHTUB_STEEL':
          return 35
        case 'DEVICE_TYPE_SANITARY_RENOVATION':
          return -1
        case 'DEVICE_TYPE_SANITARY_CONTROL_FITTINGS':
          return 20
        case 'DEVICE_TYPE_SANITARY_WASHBASIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET':
          return -1
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC':
          return 10
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN':
          return 10
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL':
          return 25
        case 'DEVICE_TYPE_SANITARY_BIDET':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER':
          return -1
        case 'DEVICE_TYPE_SANITARY_LAVABO':
          return -1
        case 'DEVICE_TYPE_SANITARY_URINAL':
          return -1
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK':
          return -1
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED':
          return 40
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC':
          return 20
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC':
          return 30
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL':
          return -1
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC':
          return 35
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT':
          return 20
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC':
          return 15
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS':
          return 25
        case 'DEVICE_TYPE_SANITARY_TOILET':
          return 20
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE':
          return 15
        case 'DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER':
          return 20
        case 'DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER':
          return 15
        case 'DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER':
          return 30
        case 'DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER':
          return -1
        case 'DEVICE_TYPE_SANITARY_PIPING_WARM_WATER':
          return -1
        case 'DEVICE_TYPE_SANITARY_PIPING':
          return -1
        case 'DEVICE_TYPE_SANITARY_INSULATION':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR':
          return 20
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE':
          return 10
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT':
          return -1
        case 'DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR':
          return 10
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD':
          return 10
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER':
          return 10
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER':
          return 25
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL':
          return 25
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE':
          return 25
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS':
          return 25
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE':
          return 10
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT':
          return -1
        case 'DEVICE_TYPE_OTHER_SANITARY':
          return -1
        case 'DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT':
          return 30
        case 'DEVICE_TYPE_ELEVATOR_BED_LIFT':
          return 30
        case 'DEVICE_TYPE_ELEVATOR':
          return 30
        case 'DEVICE_TYPE_MERCHANDISE_LIFT':
          return 30
        case 'DEVICE_TYPE_MOVING_STAIRCASE':
          return -1
        case 'DEVICE_TYPE_FACADE_CLEANING':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_OTHER':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING':
          return -1
        case 'DEVICE_TYPE_LIFTING':
          return -1
        case 'DEVICE_TYPE_PARKING':
          return -1
        case 'DEVICE_TYPE_TRANSPORT_OTHER':
          return -1
        case 'DEVICE_TYPE_PLASTERER':
          return -1
        case 'DEVICE_TYPE_METAL':
          return -1
        case 'DEVICE_TYPE_METAL_CAR_SHELTER':
          return -1
        case 'DEVICE_TYPE_METAL_MAILBOX':
          return 20
        case 'DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER':
          return -1
        case 'DEVICE_TYPE_CARPENTER_CELLAR_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_APARTMENT_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOODEN':
          return 30
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL':
          return 25
        case 'DEVICE_TYPE_CARPENTER_DOOR_METAL':
          return 30
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD':
          return 30
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE':
          return 40
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL':
          return 30
        case 'DEVICE_TYPE_CARPENTER_LOCKS':
          return -1
        case 'DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR':
          return 30
        case 'DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR':
          return 30
        case 'DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT':
          return 10
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS':
          return -1
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS':
          return 20
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN':
          return 35
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL':
          return 30
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS':
          return 15
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL':
          return 20
        case 'DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR':
          return 30
        case 'DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR':
          return 15
        case 'DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR':
          return 15
        case 'DEVICE_TYPE_CARPENTER':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOUBLE_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_INSULATING_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_FITTINGS':
          return -1
        case 'DEVICE_TYPE_WINDOW_WINDOW_FRAME':
          return -1
        case 'DEVICE_TYPE_WINDOW_SPECIAL_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL':
          return -1
        case 'DEVICE_TYPE_WINDOW':
          return -1
        case 'DEVICE_TYPE_LOCKING_LOCK':
          return -1
        case 'DEVICE_TYPE_LOCKING_KEY':
          return -1
        case 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER':
          return -1
        case 'DEVICE_TYPE_LOCKING':
          return -1
        case 'DEVICE_TYPE_INTERIOR_FINISHES':
          return -1
        case 'DEVICE_TYPE_ELEMENT_WALL':
          return -1
        case 'DEVICE_TYPE_CHROME_STEEP_FINISH':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR1':
          return -1
        case 'DEVICE_TYPE_FLOORING_COVERING':
          return -1
        case 'DEVICE_TYPE_FLOORING_BALL_YARN':
          return -1
        case 'DEVICE_TYPE_FLOORING_LAMINATE':
          return -1
        case 'DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY':
          return 15
        case 'DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY':
          return 25
        case 'DEVICE_TYPE_FLOORING_VINYL':
          return -1
        case 'DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD':
          return 40
        case 'DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD':
          return 30
        case 'DEVICE_TYPE_FLOORING_PARQUET_FOURNIER':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET_SEALING':
          return -1
        case 'DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY':
          return -1
        case 'DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR':
          return -1
        case 'DEVICE_TYPE_FLOORING_TILES':
          return -1
        case 'DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES':
          return 30
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT':
          return 30
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD':
          return 40
        case 'DEVICE_TYPE_FLOORING_TILES_CERAMIC ':
          return -1
        case 'DEVICE_TYPE_FLOORING_TILES_STONEWARE':
          return 40
        case 'DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE':
          return 40
        case 'DEVICE_TYPE_FLOORING_CARPET':
          return -1
        case 'DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER':
          return 10
        case 'DEVICE_TYPE_FLOORING_CARPET_BALL_YARN':
          return -1
        case 'DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT':
          return 8
        case 'DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY':
          return 10
        case 'DEVICE_TYPE_FLOORING_SKIRTING':
          return -1
        case 'DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC':
          return 15
        case 'DEVICE_TYPE_FLOORING_SKIRTING_WOOD':
          return 25
        case 'DEVICE_TYPE_FLOORING_JOINT':
          return 10
        case 'DEVICE_TYPE_FLOORING':
          return -1
        case 'DEVICE_TYPE_FLOORING_PVC':
          return 20
        case 'DEVICE_TYPE_FLOORING_RUBBER':
          return 20
        case 'DEVICE_TYPE_FLOORING_LINOLEUM':
          return 20
        case 'DEVICE_TYPE_FLOORING_CORK':
          return 15
        case 'DEVICE_TYPE_WALL_COVERING_WORK':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_WALLPAPER':
          return -1
        case 'DEVICE_TYPE_WALL_WALLPAPER_MEDIUM_QUALITY':
          return 10
        case 'DEVICE_TYPE_WALL_WALLPAPER_GOOD_QUALITY':
          return 15
        case 'DEVICE_TYPE_WALL_WALLPAPER_GLASS_FIBER':
          return 20
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR':
          return 8
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL':
          return 15
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS':
          return 40
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC':
          return 25
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING':
          return 25
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT':
          return 20
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC':
          return 30
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT':
          return 25
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER':
          return 20
        case 'DEVICE_TYPE_WALL_COVERING_WOOD_WORKER':
          return -1
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_WALL_ROUGH':
          return 30
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_LASED':
          return 20
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_PAINTED_OPAQUE':
          return 30
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_GLAZE':
          return 20
        case 'DEVICE_TYPE_WALL_COVERING':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_CERAMIC':
          return 30
        case 'DEVICE_TYPE_WALL_COVERING_STONEWORK':
          return 30
        case 'DEVICE_TYPE_WALL_COVERING_STONEWARE':
          return 40
        case 'DEVICE_TYPE_CEILING_WORK':
          return -1
        case 'DEVICE_TYPE_CEILING_WALLPAPER':
          return -1
        case 'DEVICE_TYPE_CEILING_PLASTER':
          return -1
        case 'DEVICE_TYPE_CEILING_ABRASION':
          return -1
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC':
          return 30
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT':
          return 25
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER':
          return 20
        case 'DEVICE_TYPE_CEILING_WOOD_WORKER':
          return -1
        case 'DEVICE_TYPE_CEILING':
          return -1
        case 'DEVICE_TYPE_CEILING_REGULAR':
          return -1
        case 'DEVICE_TYPE_CEILING_REGULAR_METAL':
          return 20
        case 'DEVICE_TYPE_CEILING_REGULAR_WOOD':
          return 40
        case 'DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING':
          return 8
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLADE':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER_MOTOR':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLIND':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD':
          return 10
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE':
          return 6
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH':
          return -1
        case 'DEVICE_TYPE_GARDENING_FENCE':
          return -1
        case 'DEVICE_TYPE_GARDENING_WALLS':
          return 40
        case 'DEVICE_TYPE_GARDENING_CEMENT_BOARD':
          return 30
        case 'DEVICE_TYPE_GARDENING_ACCESS_PATH':
          return 30
        case 'DEVICE_TYPE_GARDENING_LAWN':
          return -1
        case 'DEVICE_TYPE_GARDENING':
          return -1
        case 'DEVICE_TYPE_FENCING':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE':
          return -1
        case 'DEVICE_TYPE_PLAYGROUND':
          return -1
        case 'DEVICE_TYPE_PLAYGROUND_DEVICE':
          return 15
        case 'DEVICE_TYPE_GARDEN_OTHER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER':
          return -1
        case 'DEVICE_TYPE_FLOORING_RAW':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_TILES':
          return -1
        case 'DEVICE_TYPE_WINDOW_TRIPLE':
          return 25
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD':
          return 20
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL_WOOD_PAINTED':
          return 20
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL_TILES':
          return 30
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL_STONE':
          return 30
        case 'DEVICE_TYPE_OUTER_FINISHES':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ELECTRIC':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STOVE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_ROOF_HEATING':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET':
          return 15
        case 'DEVICE_TYPE_LIGHTS_CEILING':
          return 20
        case 'DEVICE_TYPE_LIGHTS_CABINET':
          return 20
        case 'DEVICE_TYPE_LIGHTS_SPOTS':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_HIGH_GLOSS':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET_WOOD':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_HIGH_GLOSS':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET_WOOD':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_CUTLERY':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_BOTTLE_HOLDER':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER_WITH_SLIPS_MAT':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_SEPARATING_SYSTEM':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE_HOLDER':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN':
          return 20
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_CHROME_STEEL':
          return 25
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN_PLASTIC':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE':
          return 20
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SINGLE_LEVER':
          return 20
        case 'DEVICE_TYPE_OTHER_INTERIOR2_DOUBLE_LEVER':
          return 20
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_SWIVEL':
          return 20
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_PULL_OUT_SPRAY':
          return 20
        case 'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOT_PLATES_HOB':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_GAS_HOB':
          return 15
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_BLECH':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRID':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_THERMOMETER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES_GRILL':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER':
          return 10
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES_COMPARTMENT':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY':
          return -1
        case 'DEVICE_TYPE_LIGHTS_MIRROR_CABINET':
          return 20
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_ONE_DOOR':
          return 10
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS_TWO_DOOR':
          return 10
        case 'DEVICE_TYPE_SANITARY_WASHBASIN_CERAMIC':
          return 35
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_ALUMINIUM_FILTER':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_WASHBASIN_BOWL':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_CHROME_STEEL':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_PLASTIC':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER_HOOK':
          return 15
        case 'DEVICE_TYPE_SANITARY_TOILET_CERAMIC':
          return 35
        case 'DEVICE_TYPE_SANITARY_TOILET_CLOSOMAT':
          return 20
        case 'DEVICE_TYPE_SANITARY_TOILET_COVER':
          return -1
        case 'DEVICE_TYPE_SANITARY_TOILET_COVER_SOFT_CLOSE':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_CHROME_STEEL':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER_PLASTIC':
          return 15
        case 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE_THERMAL_MIXER':
          return 20
        case 'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER_ROD':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER_ROD_CHROME_STEEL':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOLDER':
          return -1
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_HIGH_GLOSS':
          return 20
        case 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING':
          return 20
        case 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_SAFETY_GLASS':
          return 25
        case 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_METAL':
          return 30
        case 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CHROME_STEEL':
          return 20
        case 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING_CONCRETE':
          return 30
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM_VIDEO':
          return 20
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_BELL':
          return 20
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_DOOR_OPENER':
          return 20
        case 'DEVICE_TYPE_FLOORING_COATED':
          return -1
        case 'DEVICE_TYPE_FACADE_PLASTER':
          return 40
        case 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_GLASS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_WOOD':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN_METAL':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS_VOLANT':
          return 20
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP':
          return 20
        case 'DEVICE_TYPE_CARPENTER_SHELF':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SHELF_WOOD':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SHELF_METAL':
          return -1
        case 'DEVICE_TYPE_CARPENTER_CLOSET':
          return -1
        case 'DEVICE_TYPE_CARPENTER_CLOSET_WOOD':
          return -1
        case 'DEVICE_TYPE_CARPENTER_CLOSET_METAL':
          return -1
        default:
          return -1
      }
    },
    getCataloguePrice(type) {
      switch (type) {
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE':
          return -1
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR':
          return 800
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD':
          return 1200
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL':
          return 800
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL':
          return 2200
        case 'DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW':
          return 450
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC':
          return 450
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD':
          return 500
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL':
          return 550
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL':
          return 600
        case 'DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION':
          return -1
        case 'DEVICE_TYPE_ROOFING_SHEARING_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_PENT_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_SADDLE_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_TERRACE':
          return -1
        case 'DEVICE_TYPE_ROOFING_HIPPED_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_TENT_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_FLAT_ROOF':
          return -1
        case 'DEVICE_TYPE_ROOFING_FRONT_PANEL':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT':
          return -1
        case 'DEVICE_TYPE_SEALING_INSULATION_INTERIOR':
          return -1
        case 'DEVICE_TYPE_FACADE_PLASTER_PAINTING':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD':
          return 850
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL':
          return 700
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM':
          return 850
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE':
          return 700
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD':
          return 700
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL':
          return 700
        case 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS':
          return -1
        case 'DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING':
          return -1
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION':
          return -1
        case 'DEVICE_TYPE_LIGHTS_ILLUMINANT':
          return -1
        case 'DEVICE_TYPE_LIGHTS':
          return -1
        case 'DEVICE_TYPE_TELEPHONE_CENTER':
          return -1
        case 'DEVICE_TYPE_TELEPHONE_DISTRIBUTOR':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN':
          return 340
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH':
          return 150
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET':
          return 150
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA':
          return -1
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE':
          return 370
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_OTHER':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION':
          return -1
        case 'DEVICE_TYPE_OTHER_ELECTRICITY':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING':
          return -1
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_TANK':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_STORAGE':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED':
          return -1
        case 'DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER':
          return 500
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_BURNER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_FIREPLACE':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR':
          return -1
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS':
          return 1000
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS':
          return -1
        case 'DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS':
          return 1000
        case 'DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING':
          return -1
        case 'DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER':
          return -1
        case 'DEVICE_TYPE_VENTILATION_VENTILATION':
          return -1
        case 'DEVICE_TYPE_VENTILATION_CONTROLLED_HOME':
          return -1
        case 'DEVICE_TYPE_VENTILATION_CHANNELS':
          return -1
        case 'DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN':
          return -1
        case 'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN':
          return -1
        case 'DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM':
          return -1
        case 'DEVICE_TYPE_VENTILATION':
          return -1
        case 'DEVICE_TYPE_AIR_CONDITIONING_RECOOLING':
          return -1
        case 'DEVICE_TYPE_AIR_CONDITIONING':
          return -1
        case 'DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS':
          return 1300
        case 'DEVICE_TYPE_COOLING_CHILLERS':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_RESERVOIR':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_WATER_TANK':
          return -1
        case 'DEVICE_TYPE_COOLING_COLD_STORAGE_CELL':
          return -1
        case 'DEVICE_TYPE_COOLING':
          return -1
        case 'DEVICE_TYPE_SPECIAL_FACILITY':
          return -1
        case 'DEVICE_TYPE_INSULATION':
          return -1
        case 'DEVICE_TYPE_INSULATION_CHIMNEY':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY':
          return -1
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES':
          return -1
        case 'DEVICE_TYPE_SANITARY_BATHTUB':
          return -1
        case 'DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC':
          return 400
        case 'DEVICE_TYPE_SANITARY_BATHTUB_STEEL':
          return 500
        case 'DEVICE_TYPE_SANITARY_RENOVATION':
          return -1
        case 'DEVICE_TYPE_SANITARY_CONTROL_FITTINGS':
          return 400
        case 'DEVICE_TYPE_SANITARY_WASHBASIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET':
          return -1
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC':
          return 500
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN':
          return 600
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL':
          return 1200
        case 'DEVICE_TYPE_SANITARY_BIDET':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER':
          return -1
        case 'DEVICE_TYPE_SANITARY_LAVABO':
          return -1
        case 'DEVICE_TYPE_SANITARY_URINAL':
          return -1
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK':
          return -1
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED':
          return -1
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC':
          return 400
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC':
          return 500
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL':
          return 700
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC':
          return 700
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT':
          return 5000
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC':
          return 1000
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS':
          return 2000
        case 'DEVICE_TYPE_SANITARY_TOILET':
          return 5000
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE':
          return 3000
        case 'DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER':
          return 3000
        case 'DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE':
          return -1
        case 'DEVICE_TYPE_SPECIAL_SANITARY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK':
          return -1
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER':
          return -1
        case 'DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER':
          return -1
        case 'DEVICE_TYPE_SANITARY_PIPING_WARM_WATER':
          return -1
        case 'DEVICE_TYPE_SANITARY_PIPING':
          return -1
        case 'DEVICE_TYPE_SANITARY_INSULATION':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR':
          return 70
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE':
          return 200
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN':
          return -1
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT':
          return -1
        case 'DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR':
          return 1800
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB':
          return 2500
        case 'DEVIC1200E_TYPE_KITCHEN_EQUIPMENT_FREEZER':
          return 1200
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN':
          return 1500
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD':
          return 800
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER':
          return 2200
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER':
          return 2300
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB':
          return 1300
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE':
          return 900
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN':
          return 1800
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL':
          return 2000
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE':
          return 2400
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS':
          return 3000
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN':
          return 1300
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN':
          return 2700
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING':
          return -1
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE':
          return 30
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT':
          return -1
        case 'DEVICE_TYPE_OTHER_SANITARY':
          return -1
        case 'DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT':
          return -1
        case 'DEVICE_TYPE_ELEVATOR_BED_LIFT':
          return -1
        case 'DEVICE_TYPE_ELEVATOR':
          return -1
        case 'DEVICE_TYPE_MERCHANDISE_LIFT':
          return -1
        case 'DEVICE_TYPE_MOVING_STAIRCASE':
          return -1
        case 'DEVICE_TYPE_FACADE_CLEANING':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_OTHER':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT':
          return -1
        case 'DEVICE_TYPE_GOODS_CONVEYING':
          return -1
        case 'DEVICE_TYPE_LIFTING':
          return -1
        case 'DEVICE_TYPE_PARKING':
          return -1
        case 'DEVICE_TYPE_TRANSPORT_OTHER':
          return -1
        case 'DEVICE_TYPE_PLASTERER':
          return -1
        case 'DEVICE_TYPE_METAL':
          return -1
        case 'DEVICE_TYPE_METAL_CAR_SHELTER':
          return -1
        case 'DEVICE_TYPE_METAL_MAILBOX':
          return 300
        case 'DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER':
          return -1
        case 'DEVICE_TYPE_CARPENTER_CELLAR_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_APARTMENT_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOODEN':
          return 1200
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL':
          return 800
        case 'DEVICE_TYPE_CARPENTER_DOOR_METAL':
          return 2200
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME':
          return -1
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD':
          return 550
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE':
          return 800
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL':
          return 300
        case 'DEVICE_TYPE_CARPENTER_LOCKS':
          return -1
        case 'DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR':
          return 220
        case 'DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR':
          return 180
        case 'DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT':
          return 25
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS':
          return -1
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS':
          return -1
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR':
          return -1
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL':
          return 50
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS':
          return 50
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL':
          return 18
        case 'DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR':
          return 800
        case 'DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR':
          return 30
        case 'DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR':
          return 20
        case 'DEVICE_TYPE_CARPENTER':
          return -1
        case 'DEVICE_TYPE_WINDOW_DOUBLE_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_INSULATING_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_FITTINGS':
          return -1
        case 'DEVICE_TYPE_WINDOW_WINDOW_FRAME':
          return -1
        case 'DEVICE_TYPE_WINDOW_SPECIAL_GLAZING':
          return -1
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL':
          return -1
        case 'DEVICE_TYPE_WINDOW':
          return -1
        case 'DEVICE_TYPE_LOCKING_LOCK':
          return -1
        case 'DEVICE_TYPE_LOCKING_KEY':
          return -1
        case 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER':
          return -1
        case 'DEVICE_TYPE_LOCKING':
          return -1
        case 'DEVICE_TYPE_INTERIOR_FINISHES':
          return -1
        case 'DEVICE_TYPE_ELEMENT_WALL':
          return -1
        case 'DEVICE_TYPE_CHROME_STEEP_FINISH':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR1':
          return -1
        case 'DEVICE_TYPE_FLOORING_COVERING':
          return -1
        case 'DEVICE_TYPE_FLOORING_BALL_YARN':
          return -1
        case 'DEVICE_TYPE_FLOORING_LAMINATE':
          return -1
        case 'DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY':
          return 100
        case 'DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY':
          return 110
        case 'DEVICE_TYPE_FLOORING_VINYL':
          return -1
        case 'DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET':
          return -1
        case 'DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE':
          return 90
        case 'DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD':
          return 230
        case 'DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD':
          return 170
        case 'DEVICE_TYPE_FLOORING_PARQUET_FOURNIER':
          return 80
        case 'DEVICE_TYPE_FLOORING_PARQUET_SEALING':
          return 55
        case 'DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY':
          return 125
        case 'DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR':
          return 50
        case 'DEVICE_TYPE_FLOORING_TILES':
          return -1
        case 'DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES':
          return 125
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT':
          return 150
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD':
          return 200
        case 'DEVICE_TYPE_FLOORING_TILES_CERAMIC ':
          return 125
        case 'DEVICE_TYPE_FLOORING_TILES_STONEWARE':
          return 125
        case 'DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE':
          return 170
        case 'DEVICE_TYPE_FLOORING_CARPET':
          return -1
        case 'DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER':
          return 150
        case 'DEVICE_TYPE_FLOORING_CARPET_BALL_YARN':
          return 60
        case 'DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT':
          return 60
        case 'DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY':
          return 80
        case 'DEVICE_TYPE_FLOORING_SKIRTING':
          return -1
        case 'DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC':
          return 15
        case 'DEVICE_TYPE_FLOORING_SKIRTING_WOOD':
          return 20
        case 'DEVICE_TYPE_FLOORING_JOINT':
          return 25
        case 'DEVICE_TYPE_FLOORING':
          return -1
        case 'DEVICE_TYPE_FLOORING_PVC':
          return 80
        case 'DEVICE_TYPE_FLOORING_RUBBER':
          return 120
        case 'DEVICE_TYPE_FLOORING_LINOLEUM':
          return 80
        case 'DEVICE_TYPE_FLOORING_CORK':
          return 130
        case 'DEVICE_TYPE_WALL_COVERING_WORK':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_WALLPAPER':
          return -1
        case 'DEVICE_TYPE_WALL_WALLPAPER_MEDIUM_QUALITY':
          return 30
        case 'DEVICE_TYPE_WALL_WALLPAPER_GOOD_QUALITY':
          return 40
        case 'DEVICE_TYPE_WALL_WALLPAPER_GLASS_FIBER':
          return 15
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR':
          return 10
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL':
          return 18
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC':
          return 11
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT':
          return 13
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER':
          return 18
        case 'DEVICE_TYPE_WALL_COVERING_WOOD_WORKER':
          return -1
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_WALL_ROUGH':
          return 70
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_LASED':
          return 77
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_PAINTED_OPAQUE':
          return 86
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_GLAZE':
          return 35
        case 'DEVICE_TYPE_WALL_COVERING':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_CERAMIC':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_STONEWORK':
          return -1
        case 'DEVICE_TYPE_WALL_COVERING_STONEWARE':
          return -1
        case 'DEVICE_TYPE_CEILING_WORK':
          return -1
        case 'DEVICE_TYPE_CEILING_WALLPAPER':
          return -1
        case 'DEVICE_TYPE_CEILING_PLASTER':
          return -1
        case 'DEVICE_TYPE_CEILING_ABRASION':
          return -1
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC':
          return 11
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT':
          return 13
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER':
          return 18
        case 'DEVICE_TYPE_CEILING_WOOD_WORKER':
          return -1
        case 'DEVICE_TYPE_CEILING':
          return -1
        case 'DEVICE_TYPE_CEILING_REGULAR':
          return -1
        case 'DEVICE_TYPE_CEILING_REGULAR_METAL':
          return 90
        case 'DEVICE_TYPE_CEILING_REGULAR_WOOD':
          return 75
        case 'DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING':
          return 8
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLADE':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BELT':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER_MOTOR':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLIND':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS':
          return -1
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER':
          return 70
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS':
          return 70
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF':
          return 130
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS':
          return 40
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK':
          return 120
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD':
          return 60
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE':
          return 80
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK':
          return -1
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER':
          return -1
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH':
          return -1
        case 'DEVICE_TYPE_GARDENING_FENCE':
          return -1
        case 'DEVICE_TYPE_GARDENING_WALLS':
          return -1
        case 'DEVICE_TYPE_GARDENING_CEMENT_BOARD':
          return 60
        case 'DEVICE_TYPE_GARDENING_ACCESS_PATH':
          return 60
        case 'DEVICE_TYPE_GARDENING_LAWN':
          return -1
        case 'DEVICE_TYPE_GARDENING':
          return -1
        case 'DEVICE_TYPE_FENCING':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND':
          return -1
        case 'DEVICE_TYPE_SUPERSTRUCTURE':
          return -1
        case 'DEVICE_TYPE_PLAYGROUND':
          return -1
        case 'DEVICE_TYPE_PLAYGROUND_DEVICE':
          return -1
        case 'DEVICE_TYPE_GARDEN_OTHER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA':
          return -1
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER':
          return -1
        default:
          return -1
      }
    },
    getUnit(type) {
      switch (type) {
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_TERRACE_DRAIN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_DOOR_AUTOMATIC_SLIDING_DOOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DOWN_PIPES':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_BASIC_LINE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_DRAINAGE_LINE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MASTER_BUILDER_WORK_PUMP_CHAMBER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_SOLID_WOOD':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_WOOD_MATERIAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_EXTERIOR_DOOR_METAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_FIRE_GATE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_ROOF_WINDOW':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_DOUBLE_GLAZING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_PLASTIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_WOOD_METAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_WINDOW_METAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_GATE_DRIVE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_WINDOW_DOOR_DOOR_AUTOMATION':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_SHEARING_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_PENT_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_SADDLE_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_TERRACE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_HIPPED_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_TENT_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_FLAT_ROOF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ROOFING_FRONT_PANEL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_WOOD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_PLATES':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SEALING_INSULATION_FACADE_ETERNIT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SEALING_INSULATION_INTERIOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FACADE_PLASTER_PAINTING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_FOLDING_SHUTTERS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_WOOD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SHUTTERS_METAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_OUTSIDE_ALUMINIUM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SLAT_TOWERS_INSIDE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_WOOD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_BLIND_SHOPS_METAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OUTER_FINISHES_GLARE_PROTECTION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LIGHTING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CO2':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SINA':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SMOKE_VENT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PROOF_OF_SAFETY_SINA':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHGEAR_COMBINATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_PHOTOVOLTAIC':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_LOW_VOLTAGE_SYSTEM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_CABLE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCH':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SWITCHBOARD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_SOCKET':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_UPS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_INFRASTRUCTURE_ELECTRIC_CHARGING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAVY_CURRENT_INSTALLATION_ELECTRIC_CHARGING_STATION':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_LIGHTS_ILLUMINANT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LIGHTS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_TELEPHONE_CENTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_TELEPHONE_DISTRIBUTOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERCOM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_FIBERGLASS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_EMERGENCY_PHONE_LIFT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTRUSION_DETECTION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_ISDN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_CABLE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCHBOARD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SOCKET':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_ANTENNA':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_TV_CABLE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_BATTERY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_OTHER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_ELECTRICITY_LIGHTNING_PROTECTION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_ELECTRICITY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_ROOF_VENTILATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_FILTER_REPLACEMENT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_AIR_CONDITIONING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEATING_TRANSITION_POSITION_VENTILATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_SUPPLY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_LEAKAGE_WARNING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_TANK':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_STORAGE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_INSIDE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_FUEL_TANK_EARTH_LOADED':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_ENERGY_SUPPLY_GAS_LINE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_SINGLE_WATER_BOILER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBIBOILER_HEATING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_BOILER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_BOILER_GAS_APPLIANCES':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_BURNER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_EARTH_PROBE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_ELECTRIC_STORAGE_STOVE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_EXPANSION_DEVICE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_DISTRICT_HEATING_SUBSTATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_COMBINED_BOILER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_HEATING_BOILER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_THERMAL_SOLAR_SYSTEM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_EXCHANGER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_PUMP':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_FIREPLACE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_HEAT_ACCUMULATOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_METER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEAT_QUANTITY_FLOW_METERS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_HEATING_COST_ALLOCATOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_DISTRIBUTION_CIRCULATING_PUMP':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_SOLAR_PANELS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_RADIATORS_HEATWALLS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_TOWEL_RADIATORS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_HEAT_GENERATION_FLOOR_HEATING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_VENTILATION_CONTROL_CENTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_VENTILATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_CONTROLLED_HOME':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_CHANNELS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_SUPPLY_AIR_FAN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION_SMOKE_EXTRACTION_SYSTEM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_VENTILATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_AIR_CONDITIONING_RECOOLING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_AIR_CONDITIONING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_AIR_CONDITIONING_INDIVIDUAL_ROOMS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_COOLING_CHILLERS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_COOLING_COLD_RESERVOIR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_COOLING_COLD_WATER_TANK':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_COOLING_COLD_STORAGE_CELL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_COOLING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_FACILITY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_INSULATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_INSULATION_CHIMNEY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_BUILDING_MANAGEMENT_SYSTEM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_CONTROL_SYSTEM':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_MEASUREMENT_CONTROL_TECHNOLOGY_VALVES':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_BATHTUB':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_BATHTUB_ACRYLIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_BATHTUB_STEEL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_RENOVATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_CONTROL_FITTINGS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_WASHBASIN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_PLASTIC':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_WOODEN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_MIRROR_CABINET_METAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_BIDET':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SHOWER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_LAVABO':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_URINAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_FLUSH_MOUNTED':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_PLASTIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_FLUSH_TANK_CERAMIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CERAMIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_LAVABO_TOILET_BIDET_URINAL_CLOSOMAT':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_PLASTIC':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SHOWER_CABIN_GLASWALLS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_TOILET':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_SOFTENER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_LIMESCALE_PROTECTION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_DEHUMIDIFIER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_MACHINE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_COMBI_WASHER_DRYER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_WASHING_ELECTRICITY_METER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_TUMBLER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SPECIAL_SANITARY_VACUUM_DEGASSING_DEVICE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SPECIAL_SANITARY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SUPPLY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_DISPOSAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_TREATMENT':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_PUMP':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_WATER_DISTRIBUTION_BATTERY':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_SEWAGE_TANK':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_SUPPLY_DISPOSAL_PIPING_COLD_WATER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_PIPING_RAIN_WATER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_PIPING_WARM_WATER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_PIPING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_SANITARY_INSULATION':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT_SHOWER_CABIN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SANITARY_EQUIPMENT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ELECTRIC_PNEUMATIC_BOARD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_INDUCTION_HOB':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_GLASS_CERAMIC_HOB':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MINOR_KITCHEN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FURNITURE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_MICROWAVE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STOVE_WITH_OVEN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_CARBON_FILTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRYER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COMBINATION_FURNITURE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_CHROME_STEEL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GRANITE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_GLASS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_SYNTHETIC_RESIN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER_WOODEN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_SEALING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT_VENTILATION_GRILLE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_KITCHEN_EQUIPMENT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_SANITARY':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ELEVATOR_HANDICAP_LIFT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ELEVATOR_BED_LIFT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ELEVATOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MERCHANDISE_LIFT':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_MOVING_STAIRCASE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FACADE_CLEANING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GOODS_CONVEYING_OTHER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GOODS_CONVEYING_PNEUMATIC_TUBE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GOODS_CONVEYING_FILE_LIFT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GOODS_CONVEYING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LIFTING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_PARKING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_TRANSPORT_OTHER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_PLASTERER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_METAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_METAL_CAR_SHELTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_METAL_MAILBOX':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_METAL_BALCONY_ENLARGEMENT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_SCREED_EXTENSION_LADDER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_CELLAR_DOOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_APARTMENT_DOOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOODEN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR_WOOD_MATERIAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR_METAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_WOOD':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL_STONE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_METAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_LOCKS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_LOCKS_APARTMENT_DOOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_LOCKS_ROOM_DOOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_LOCKS_KITT_JOINT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_CHANNELBOARDS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS_WOODEN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_WOODEN_MATERIAL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_SLIDING_DOOR_ROLLS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_DOOR_FRAME_COLOR_OIL':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CARPENTER_GLASS_INSERT_DOOR':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CARPENTER_FITTINGS_FOR_DOOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CARPENTER_RUBBER_SEALS_DOOR':
          return 'DEVICE_UNIT_M'
        case 'DEVICE_TYPE_CARPENTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_DOUBLE_GLAZING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_INSULATING_GLAZING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_FITTINGS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_WINDOW_FRAME':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_SPECIAL_GLAZING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW_WINDOW_SILL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_WINDOW':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOCKING_LOCK':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_LOCKING_KEY':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_LOCKING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_INTERIOR_FINISHES':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_ELEMENT_WALL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_CHROME_STEEP_FINISH':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR1':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_COVERING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_BALL_YARN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_LAMINATE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_LAMINATE_MEDIUM_QUALITY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_LAMINATE_HIGH_QUALITY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_VINYL':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_DIRT_TRAP_CARPET':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING_PARQUET':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PARQUET_ADHESIVE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PARQUET_HARDWOOD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PARQUET_SOFTWOOD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PARQUET_FOURNIER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PARQUET_SEALING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_FLOOR_UNDERLAY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_INSTALLATION_FLOOR':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_SOUND_PLATES':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_SOFT':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_NATURAL_STONE_HARD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_CERAMIC ':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_STONEWARE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_TILES_ARTIFICIAL_STONE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CARPET':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CARPET_NATURAL_FIBER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CARPET_BALL_YARN':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CARPET_NEEDLE_FELT':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CARPET_MEDIUM_QUALITY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_SKIRTING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_SKIRTING_PLASTIC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_SKIRTING_WOOD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_JOINT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FLOORING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_PVC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_RUBBER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_LINOLEUM':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_FLOORING_CORK':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_WORK':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_WALLPAPER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WALLPAPER_MEDIUM_QUALITY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WALLPAPER_GOOD_QUALITY':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WALLPAPER_GLASS_FIBER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_GLUE_COLOR':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_ACRYL':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_MINERALS':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_PLASTIC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_SILICATE_COATING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_PLASTER_DISPERSION_PAINT':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_PLASTIC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_ROUGH_PUTT':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_ABRASION_WHITE_PLASTER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_WOOD_WORKER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_WALL_ROUGH':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_LASED':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_PAINTED_OPAQUE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_WOOD_WORKER_GLAZE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_CERAMIC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_STONEWORK':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_WALL_COVERING_STONEWARE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_WORK':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_WALLPAPER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_PLASTER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_ABRASION':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_PLASTIC':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_ROUGH_PUTT':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_COVERING_ABRASION_WHITE_PLASTER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_WOOD_WORKER':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_REGULAR':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_REGULAR_METAL':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_CEILING_REGULAR_WOOD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_INTERIOR_SURFACE_TREATMENTS_PAINTING':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLADE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BELT':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER_MOTOR':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_BLIND':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_SOAP_HOLDER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOOTH_GLASS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_GLASS_SHELF':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_TOWEL_BARS':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_HOLDING_RACK':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_CURTAIN_ROD':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PACKAGE':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_GREEN':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_PLASTIC_BLACK':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_OTHER_TRANSPORT_CONTAINER_METAL':
          return 'DEVICE_UNIT_PCS'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_OUTDOOR_PARKING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_PLANTING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_CONTAINER_SITE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_BIKE_SHELTER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_ACCESS_PATH':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING_FENCE':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_GARDENING_WALLS':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_GARDENING_CEMENT_BOARD':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_GARDENING_ACCESS_PATH':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_GARDENING_LAWN':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDENING':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_FENCING':
          return 'DEVICE_UNIT_M2'
        case 'DEVICE_TYPE_SUPERSTRUCTURE_TERRANCE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SUPERSTRUCTURE_BIKE_STAND':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_SUPERSTRUCTURE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_PLAYGROUND':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_PLAYGROUND_DEVICE':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_GARDEN_OTHER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_FIRE_EXTINGUISHER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_LAWN_MOWER':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_SECURITY_CAMERA':
          return 'DEVICE_UNIT_UNDEFINED'
        case 'DEVICE_TYPE_DEVICE_TRANSITION_POSITION_INDOOR_BIKE_SHELTER':
          return 'DEVICE_UNIT_UNDEFINED'
        default:
          return 'DEVICE_UNIT_UNDEFINED'
      }
    },
    typeSuggestSelected(option) {
      if (option) {
        this.facilityPlant = option.item.facilityPlant
        this.system = option.item.system
        this.type = option.item.type
        this.bkpH = option.item.bkp
        this.lifeSpan = option.item.lifeSpan
        this.cataloguePrice = option.item.cataloguePrice
        this.unit = option.item.unit
      }
    },
    setClosed(value) {
      setTimeout(() => {
        this.closed = value
      }, 200)
    },
    deviceUpdated(newDevice) {
      this.$emit('device-update', newDevice)
    },
    dispatchDeviceUpdate(newDevice) {
      if (this.target === 'building') {
        this.$store.dispatch('onboarding/updateBuildingDevice', newDevice)
      } else if (this.fromHandover === false) {
        this.$store.dispatch('onboarding/updateObjectDevice', newDevice)
      }
      this.$emit('device-update', newDevice)
    },
    dateToObject() {
      this.installation = this.toObject(this.dates.installation)
      this.uninstallation = this.toObject(this.dates.uninstallation)
      this.warrantyEnd = this.toObject(this.dates.warrantyEnd)
    },
    dateToObjectMeasureUntil() {
      this.measureUntil = this.toObject(this.dates.measureUntil)
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    removeConfirmed() {
      if (this.target === 'building') {
        this.removeBuildingDevice(this.device)
      } else {
        this.removeObjectDevice(this.device)
      }
      this.$refs.deleteModal.hide()
      this.$emit('removeConfirmed')
    },
    duplicateClicked() {
      if (this.target === 'property' && this.fromLocal === false) {
        this.$emit('duplicated')
      } else if (this.target === 'building' || this.fromLocal === true) {
        this.duplicateBuildingDevice(this.device)
      } else if (this.target === 'object') {
        this.duplicateObjectDevice(this.device)
      }
      this.$emit('duplicateDevice', this.device)
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('deviceIdNewDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      // Update device in vuex store with new documentId
      const device = this.device
      device.documentIds.push(document.id)
      if (this.target === 'building' && this.fromLocal === false) {
        this.$store.dispatch('onboarding/updateBuildingDevice', device)
      } else {
        this.$store.dispatch('onboarding/updateObjectDevice', device)
      }
      this.dispatchDeviceUpdate(device)

      // Add whole new document to vuex store to display it
      if (this.target === 'building' && this.fromLocal === false) {
        this.buildingDeviceDocuments.push(document)
      } else if (this.target === 'property' || this.fromLocal === true) {
        this.documentList.push(document)
      } else {
        this.objectDeviceDocuments.push(document)
      }
    },
    versionAdded(updatedDocument) {
      if (this.target === 'building') {
        this.buildingDeviceDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.buildingDeviceDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      } else if (this.target === 'property') {
        this.documentList.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.documentList.splice(index, 1, updatedDocument)
          }
        }, this)
      } else {
        this.objectDeviceDocuments.forEach((doc, index) => {
          if (doc.id === updatedDocument.id) {
            this.objectDeviceDocuments.splice(index, 1, updatedDocument)
          }
        }, this)
      }
    },
    deleteDocument(document) {
      this.device.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.device.documentIds.splice(index, 1)
          if (this.target === 'building' && this.fromLocal === false) {
            const indexToDelete = this.buildingDeviceDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.buildingDeviceDocuments, indexToDelete)
            }
          } else if (this.target === 'property' || this.fromLocal === true) {
            const indexToDelete = this.documentList.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.documentList, indexToDelete)
            }
            const device = this.device
            if (this.target === 'building') {
              this.$store.dispatch('onboarding/updateBuildingDevice', device)
            } else {
              this.$store.dispatch('onboarding/updateObjectDevice', device)
            }
          } else {
            const indexToDelete = this.objectDeviceDocuments.findIndex(d => d.id === document.id)
            if (indexToDelete !== -1) {
              this.$delete(this.objectDeviceDocuments, indexToDelete)
            }
          }
        }
      }, this)
      this.$store.dispatch('onboarding/updateObjectDevice', this.device)
      this.activateChanged()
    },
    isInvalid() {
      this.$v.$touch()
      let listErrors = []
      if (this.$v.$invalid) {
        if (this.$v.uninstallation.$dirty && this.$v.uninstallation.$error) {
          listErrors.push(this.$t('message.savingErrors.uninstallation'))
        }
      }

      const inspectionInvalid = this.$refs['device-maintenance'].isInvalid()
      const maintenanceInvalid = this.$refs['device-maintenance'].isInvalid()
      const revisionInvalid = this.$refs['device-revision'].isInvalid()
      if (inspectionInvalid[0] || maintenanceInvalid[0] || revisionInvalid[0] || this.$v.$invalid) {
        listErrors = listErrors.concat(this.$refs['device-maintenance'].isInvalid()[1].concat(this.$refs['device-inspection'].isInvalid()[1]))
        if (listErrors.length > 0) {
          this.showManyToast(listErrors)
        }
        this.scrollToError()
        return true
      } else {
        return false
      }
    },
    isCollapseOpen() {
      return this.$refs.collapse.show
    },
    endMoving() {
      for (let index = 0; index < this.selectedDeviceImageMedia.length; index++) {
        const indexElement = this.item?.images?.findIndex(image => image.mediaId === this.selectedDeviceImageMedia[index].mediaId)
        if (this.item && this.item.images && this.item.images[indexElement]) {
          this.item.images[indexElement].order = index + 1
        }
      }
    },
    removeImageClicked(media) {
      this.selectedDeviceImageMedia = this.selectedDeviceImageMedia.filter(m => {
        return m.media.id !== media.id
      })

      const device = this.device
      device.images = device.images.filter(i => {
        return i.mediaId !== media.id
      })
      for (const [index, image] of device.images.entries()) {
        image.order = index
      }
      this.dispatchDeviceUpdate(device)
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }

      return media.url
    },
    imageClicked() {
      this.viewer?.show()
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.imageUpload.$el.children
        btn[0].click()
      }
    },
    async loadDeviceImages(device) {
      this.selectedDeviceImageMedia = null
      // Load media
      const promises = []
      for (const image of device.images) {
        promises.push(MediaApi.getMediaByID(image.mediaId))
      }
      const listmedia = await Promise.all(promises)
      for (let index = 0; index < (listmedia.length); index++) {
        listmedia[index].mediaId = device.images[index].mediaId
        listmedia[index].order = device.images[index].order
      }
      this.selectedDeviceImageMedia = listmedia.filter(med => med.media.mimeType.includes('image')).sort(function (a, b) {
        return a.order - b.order
      })
    },
    navigateToCreateOrder() {
      const currentRoute = {
        name: this.$router.currentRoute.name,
        params: this.$router.currentRoute.params,
        query: this.$router.currentRoute.query
      }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const buildingId = this.building ? this.building.id : this.device.referenceId
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetCreateOrderView',
          query: {
            buildingIds: buildingId,
            deviceIds: this.device.id,
            source: 'device'
          }
        }, () => {
        })
      }
    }
  },
  validations() {
    const validationDevice = {
      acquisitionCosts: {
        decimal
      },
      cataloguePrice: {
        decimal
      },
      uninstallation: {
        moreThanInstallation
      }
    }
    if (this.condition === 'DEVICE_CONDITION_REPAIR_NEEDED' || this.condition === 'DEVICE_CONDITION_REPLACEMENT_NEEDED') {
      validationDevice.measureUntil = {
        required
      }
      validationDevice.note = {
        required
      }
    }
    return validationDevice
  }
}
</script>

<style lang="scss" scoped>
.uploaded-file {
  height: 200px;
}

.device-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
}

.device-image-remove {
  position: absolute;
  z-index: 3;
  width: 16px;
  height: 16px;

  &.bg {
    left: -2px;
    top: -4px;
    width: 20px;
    height: 24px;
    color: white;
  }
}

.device-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-gray {
  color: $color-text-grey-light;
}

.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.action-object {
  top: -2rem;
  right: 0;
}

.h-0 {
  height: 10px;
}
</style>
