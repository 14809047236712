<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2" />
    <div
      class="row">
      <div class="col-md-5">
        <h3>Available Rooms</h3>
        <draggable
          v-model="availableRooms"
          group="rooms"
          class="list-group"
          @end="updateRooms">
          <div
            v-for="room in availableRooms"
            :key="room.id"
            :class="['list-group-item', selectedRoom && selectedRoom.name === room.name ? 'selected' : '']"
            class="list-group-item"
            @click="selectRoom(room, 'available')">
            <span>{{ room.name }}</span>
          </div>
        </draggable>
      </div>
      <div class="arrows col-md-2">
        <button
          :disabled="!selectedRoom || selectedRoomType !== 'available'"
          @click="moveSelectedRoomToSelected">
          <span>→</span>
        </button>
        <button
          :disabled="!selectedRoom || selectedRoomType !== 'selected'"
          @click="moveSelectedRoomToAvailable">
          <span>←</span>
        </button>
      </div>

      <div class="col-md-5">
        <h3>Selected Rooms</h3>
        <draggable
          v-model="selectedRooms"
          group="rooms"
          class="list-group"
          @end="updateRooms">
          <div
            v-for="room in selectedRooms"
            :key="room.id"
            :class="['list-group-item', selectedRoom && selectedRoom.id === room.id ? 'selected' : '']"
            @click="selectRoom(room, 'selected')">
            <coozzy-form-input
              v-if="selectedRoom && selectedRoom.id === room.id && editingRoom"
              v-model="room.name"
              @blur="finishEdit(room)"
              @keyup.enter="finishEdit(room)" />
            <span v-else>{{ room.name }}</span>
            <coozzy-button
              v-if="!(selectedRoom && selectedRoom.id === room.id && editingRoom)"
              design="transparent"
              class="p-0 ml-1 mb-2  float-right"
              @click="deleteRoom(room)">
              <coozzy-delete-icon />
            </coozzy-button>
            <coozzy-button
              v-if="selectedRoom && selectedRoom.id === room.id && editingRoom"
              design="transparent"
              class="p-0 mb-2 ml-2 save-icon"
              @click="finishEdit(room)">
              <coozzy-save-icon />
            </coozzy-button>
            <coozzy-button
              v-else
              design="transparent"
              class="p-0 mb-2 ml-2 float-right"
              @click="editRoom(room)">
              <coozzy-edit-icon />
            </coozzy-button>
          </div>
        </draggable>
        <coozzy-button
          class="col-sm-12 col-lg-4 col-md-4 float-md-right mt-3"
          design="prop-green"
          @click="addRoom">
          {{ $t('message.processHandover.rooms.addRoom') }}
        </coozzy-button>
      </div>
    </div>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import { mapActions, mapGetters } from 'vuex'
import ContactApi from '@/misc/apis/ContactApi'
import draggable from 'vuedraggable'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon.vue'

export default {
  name: 'HandoverRoomsStep',
  components: {
    CoozzySaveIcon,
    CoozzyFormInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyEditIcon,
    draggable
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia],
  props: {
    object: {
      type: Object,
      required: true,
      default: () => {
        return null
      }
    },
    selfRooms: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      deletedRooms: [],
      rooms: [],
      availableRooms: [],
      selectedRooms: [],
      selectedEntries: [],
      filteredObjects: [],
      usedContacts: [],
      selectedRoom: null,
      selectedRoomType: null,
      editingRoom: null
    }
  },
  computed: {
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    }
  },
  watch: {
    selectedRooms(newVal) {
      this.$emit('selected-rooms', newVal, this.object)
    },
    deletedRooms(newVal) {
      this.$emit('deleted-rooms', newVal, this.object)
    },
    selfRooms(newVal, oldVal) {
      if ((oldVal.length === 0 && newVal.length > 0) || JSON.stringify(newVal) !== JSON.stringify(oldVal) || JSON.stringify(newVal) !== JSON.stringify(this.rooms)) {
        this.rooms = newVal
        this.updateSelectedRoom()
      }
    }
  },
  mounted() {
    if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
      this.availableRooms = [
        {
          id: 'kitchen',
          name: this.$t('message.processHandover.rooms.kitchen'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'wetRoom',
          name: this.$t('message.processHandover.rooms.wetRoom'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'room',
          name: this.$t('message.processHandover.rooms.room'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'corridor_entrance',
          name: this.$t('message.processHandover.rooms.corridor_entrance'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'reduit_storage',
          name: this.$t('message.processHandover.rooms.reduit_storage'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'balcony_seatingArea',
          name: this.$t('message.processHandover.rooms.balcony_seatingArea'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'cellar',
          name: this.$t('message.processHandover.rooms.cellar'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        }
      ]
    } else if (this.object.category === 'PARKING_SPACE') {
      this.availableRooms = [{
 id: 'parking',
name: this.$t('message.processHandover.rooms.parking'),
dimensions: {
      area: null,
      height: null,
      length: null,
      volume: null,
      width: null
    },
    documentIds: [],
    imageMediaIds: [],
    note: '',
    ownerId: this.object.ownerId,
    referenceId: this.object.id
}]
    } else if (this.object.category === 'SECONDARY_ROOM') {
      this.availableRooms = [{
 id: 'secondaryRoom',
name: this.$t('message.processHandover.rooms.secondaryRoom'),
dimensions: {
      area: null,
      height: null,
      length: null,
      volume: null,
      width: null
    },
    documentIds: [],
    imageMediaIds: [],
    note: '',
    ownerId: this.object.ownerId,
    referenceId: this.object.id
}]
    }
    this.$emit('selected-rooms', this.selectedRooms, this.object)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    updateSelectedRoom() {
      this.rooms.forEach(room => {
        this.selectedRooms.push(room)
      })
      // remove duplicates
      this.selectedRooms = this.selectedRooms.filter((room, index, self) =>
          index === self.findIndex((t) => (
            t.id === room.id
          ))
      )
    },

    selectRoom(room, type) {
      this.selectedRoom = room
      this.selectedRoomType = type
    },
    moveSelectedRoomToSelected() {
      if (this.selectedRoom && this.selectedRoomType === 'available') {
        this.availableRooms = this.availableRooms.filter(
          (r) => r.id !== this.selectedRoom.id
        )
        this.selectedRooms.push(this.selectedRoom)
        this.clearSelection()
      }
    },
    moveSelectedRoomToAvailable() {
      if (this.selectedRoom && this.selectedRoomType === 'selected') {
        this.selectedRooms = this.selectedRooms.filter(
          (r) => r.id !== this.selectedRoom.id
        )
        this.availableRooms.push(this.selectedRoom)
        this.clearSelection()
      }
    },
    clearSelection() {
      this.selectedRoom = null
      this.selectedRoomType = null
    },
    addRoom() {
      const newRoom = {
        id: Date.now(),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        name: this.$t('message.onBoarding.devices.room'),
        note: '',
        ownerId: this.object.ownerId,
        referenceId: this.object.id
      }
      this.selectedRooms.push(newRoom)
    },
    updateRooms(event) {
      // Optional: handle any updates after drag-and-drop
      console.log('Rooms have been updated', event)
    },
    editRoom(room) {
      this.editingRoom = { ...room }
    },
    finishEdit(room) {
      if (room) {
        if (typeof room.id === 'string' && room.id.startsWith('room_')) {
          room.edited = true
        }
        let roomIndex = this.selectedRooms.findIndex(
          (r) => r.id === room.id
        )
        if (roomIndex !== -1) {
          this.$set(this.selectedRooms, roomIndex, room)
        }
      }
      this.editingRoom = null
    },
    deleteRoom(room) {
      this.deletedRooms.push(this.selectedRooms.find((r) => r.id === room.id))
      this.selectedRooms = this.selectedRooms.filter((r) => r.id !== room.id)
      this.clearSelection()
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
      }
      this.$emit('selected-object', this.selectedEntries)
    },
    getCurrentTenancy(objectId) {
      return this.getFilteredTenancies(objectId).concat(this.sameTenanciesByObject).find(t => t.status === 'TENANCY_STATUS_CURRENT' || t.status === 'TENANCY_STATUS_CURRENT_TERMINATED')
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        this.$router.push({ name: 'AdminContactDetailsView', params: { id: contactId } })
      }
    },
    getContactName(contactId) {
      const contact = this.usedContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getNetRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      return adjustedRentValues.coldRent
    },
    getStartOfContract(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      return this.$options.filters.objectToDate(tenancy.startingAt)
    },
    getTotalRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const total = (adjustedRentValues.heatingCosts !== -1 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts !== -1 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0)
      return total !== 0 ? total : null
    },
    getAdjustedRentValues(tenancy) {
      let selectedTenancy = tenancy.rent
      const current = this
      const pastAdjustements = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(current.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustements.length > 0) {
        pastAdjustements.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        selectedTenancy = pastAdjustements[0]
      }
      return selectedTenancy
    },
    getFullStatusText(obj) {
      if (!obj) {
        return 'n/a'
      }
      const status = this.getStatus(obj)
      return this.$t('message.onBoarding.buildings.objects.status.' + status)
    },
    getFilteredTenancies(objectId) {
      return this.tenancies.concat(this.sameTenanciesByObject).filter(ten => {
        return ten.objectId === objectId
      })
    },
    getStatus(obj) {
      if (!obj) {
        return 'n/a'
      }
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const currentTenancy = this.getFilteredTenancies(obj.id)
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

      if (currentTenancy && currentTenancy.status) {
        return currentTenancy.status.replace('TENANCY_STATUS_', '').toLowerCase()
      } else {
        return 'undefined'
      }
    },
    getFullStatusColorClass(obj) {
      return this.getStatus(obj)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.save-icon {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 9;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrows button {
  margin: 5px;
}

.list-group {
  min-height: 200px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.list-group-item.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
</style>
