<template>
  <section :class="calculateClassHideMailsFromOtherFolders">
    <div>
      <coozzy-page-title
        :title="$t('message.marketingMessages.messages')"
        :count="messageCount"
        :count-text="$t('message.marketingMessages.messages')"
        :action-text="$t('message.generic.writeEmail')"
        :clear-filter="checkfilter"
        @clear-filter="clickOnClear"
        @click="sendEmailModal">
        <template #after-title>
          <div class="col-12 col-md-2 mr-auto">
            <coozzy-button
              design="transparent"
              class="mt-2"
              @click="changeColumnView(!columnView)">
              <coozzy-view-list-icon v-if="columnView" />
              <coozzy-list-icon v-else />
            </coozzy-button>
          </div>
        </template>
      </coozzy-page-title>
      <!-- Filter -->
      <div class="row">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchInputText"
            v-model="querySearch"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-select
            v-model="filterFolder"
            name="numberEntries"
            class="select-entries float-right">
            <option value="FILTER_FOLDER_ALL">
              {{ $t('message.marketingMessages.folderFilter.all') }}
            </option>
            <option value="FILTER_FOLDER_OUTBOUND">
              {{ $t('message.marketingMessages.folderFilter.outbound') }}
            </option>
            <option value="FILTER_FOLDER_INBOUND">
              {{ $t('message.marketingMessages.folderFilter.inbound') }}
            </option>
            <option value="FILTER_FOLDER_SPAM">
              {{ $t('message.marketingMessages.folderFilter.spam') }}
            </option>
            <option value="FILTER_FOLDER_ARCHIVED">
              {{ $t('message.marketingMessages.folderFilter.archive') }}
            </option>
            <option value="FILTER_FOLDER_TRASH">
              {{ $t('message.marketingMessages.folderFilter.trash') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="filterMailboxes"
            :options="mailboxesForCurrentUser"
            :multiple="true"
            :placeholder="$t('message.marketingMessages.mail.mailbox')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :track-by="'outboundEmail'"
            :label="'outboundEmail'"
            :preselect-first="false"
            :taggable="false" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-form-checkbox
            v-model="filterUnreadMsg"
            :initial="filterUnreadMsg"
            :class="'p-0 w-100'"
            name="unreadMessage"
            button>
            {{ $t('message.marketingMessages.unreadMessages') }}
          </coozzy-form-checkbox>
        </div>
      </div>
      <div class="row mt-2 align-items-center">
        <div class="col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="filterFolder !== 'FILTER_FOLDER_TRASH' && filterFolder !== 'FILTER_FOLDER_ARCHIVED'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('archive') : ''">
              {{ $t('message.generic.archive') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="filterFolder !== 'FILTER_FOLDER_TRASH'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('delete') : ''">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="filterFolder === 'FILTER_FOLDER_TRASH' && (hasManageMailsRole || isCompanyAdmin)"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('emptyTrash') : ''">
              {{ $t('message.generic.emptyTrash') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="filterFolder === 'FILTER_FOLDER_TRASH' || filterFolder === 'FILTER_FOLDER_ARCHIVED'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('restore') : ''">
              {{ $t('message.generic.restore') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider
              v-if="filterFolder !== 'FILTER_FOLDER_ARCHIVED' && filterFolder !== 'FILTER_FOLDER_TRASH'" />
            <coozzy-dropdown-item
              v-if="filterFolder !== 'FILTER_FOLDER_ARCHIVED' && filterFolder !== 'FILTER_FOLDER_TRASH'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('read') : ''">
              {{ $t('message.marketingMessages.markAsRead') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="filterFolder !== 'FILTER_FOLDER_ARCHIVED' && filterFolder !== 'FILTER_FOLDER_TRASH'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? changeStatus('unread') : ''">
              {{ $t('message.marketingMessages.markAsUnRead') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && messageCount > nbrPerPage && messageCount <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: messageCount }) : $t('message.generic.selectHiddenEntries', { amount: messageCount }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="!columnView"
          class="col-md-2 col-sm-6 col-12 offset-md-6 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option :value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option :value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option :value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option :value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option :value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        :style="shortContent ? 'height:0px;' : '' "
        class="row mt-2"
        :class="columnView ? 'parent-container' : 'simple-container'">
        <div
          :id="columnView ? 'left' : ''"
          :class="columnView ? 'pr-md-0 leftside' : 'col-12 col-md-12'">
          <div @mousemove="onMouseMove($event)">
            <b-table
              ref="messagesTable"
              class="mt-2"
              primary-key="id"
              :class="columnView ? 'table-column-view' : 'table-row-view'"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :busy="isBusy"
              :fields="dataFields"
              :items="messageList"
              :tbody-tr-class="tableRowClassCustom"
              @scrolled="loadMore"
              @row-clicked="onRowClicked"
              @row-dblclicked="onRowDblClicked"
              @row-middle-clicked="onRowMiddleClicked"
              @row-hovered="onRowHovered"
              @row-unhovered="onRowUnHovered">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(icons) />
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  :disabled="disabledCheckbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(date)>
                {{ $t('message.tableColumnHeadings.date') }}
              </template>
              <template #head(from)>
                {{ $t('message.tableColumnHeadings.from') }}
              </template>
              <template #head(to)>
                {{ $t('message.tableColumnHeadings.to') }}
              </template>
              <template #head(subject)>
                {{ $t('message.tableColumnHeadings.subject') }}
              </template>
              <template #head(message)>
                {{ $t('message.tableColumnHeadings.message') }}
              </template>
              <template #head(receivedOpened)>
                {{ $t('message.tableColumnHeadings.receivedOpened') }}
              </template>

              <!-- Data -->
              <template #cell(checkbox)="data">
                <!-- data needs to be used in order to work properly -->
                <coozzy-form-checkbox
                  :id="'check_' + data.item.id"
                  :disabled="disabledCheckbox"
                  @change="(value) => { itemSelected(data.item.id, value) }" />
              </template>
              <template #cell(actions)="data">
                <coozzy-link-icon
                  v-if="isMailHasReferenceIdTicket(data.item)"
                  :id="'tooltip-target-' + data.item.id"
                  class="icon-link-ticket"
                  @click.native="routeToDetail(isMailHasReferenceIdTicket(data.item))"
                  @click.middle="openMiddleDetail(isMailHasReferenceIdTicket(data.item))" />
                <b-tooltip
                  v-if="isMailHasReferenceIdTicket(data.item)"
                  :target="'tooltip-target-' + data.item.id"
                  triggers="hover">
                  {{ isMailHasReferenceIdTicket(data.item).key }} {{ isMailHasReferenceIdTicket(data.item).title }}
                </b-tooltip>
                <template
                  v-if="hasReminder(data.item.id)">
                  <coozzy-reminder-icon
                    v-if="hasReminder(data.item.id) === 'progress'"
                    class="mr-1 ml-1 reminderIcon"
                    @click.native="forwardToReminder(data.item.id)" />
                  <coozzy-check-mark-icon
                    v-else-if="hasReminder(data.item.id) === 'finish'"
                    v-b-tooltip.hover.html="$t('message.marketingMessages.reminderDone', {date: $options.filters.timestampToDate(getReminder(data.item.id)?.done)})"
                    class="mr-1 ml-1" />
                </template>
                <coozzy-reply-icon
                  v-if="data.item.hasReply"
                  class="mr-1 ml-1" />
                <coozzy-forward-icon
                  v-if="data.item.hasForwarding"
                  class="mr-1 ml-1" />
                <coozzy-confidential-icon
                  v-if="data.item.confidential"
                  class="mr-1 ml-1" />
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="mb-0">
                  {{ data.item.timestamp | timestampToSmallDate }}
                </p>
                <div
                  v-if="filterFolder === 'FILTER_FOLDER_OUTBOUND' || (filterFolder === 'FILTER_FOLDER_ALL' && !data.item.inbound)"
                  class="text-center">
                  <template v-if="data.item.deliverData.status === 'DELIVER_STATUS_DELIVERED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else-if="data.item.deliverData.status === 'DELIVER_STATUS_PENDING'">
                    <coozzy-pending-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon
                      v-b-tooltip.hover.left
                      :title="data.item.deliverData.errorMessage" />
                  </template>
                  /
                  <template v-if="data.item.engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon />
                  </template>
                </div>
              </template>
              <template #cell(infoMessage)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <template v-else-if="getMailConnectedWithContacts(data.item).from.contactId">
                  <router-link :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).from.contactId)">
                    {{ getMailConnectedWithContacts(data.item).from.name }}<br>
                  </router-link>
                </template>
                <template v-else>
                  <span>{{ data.item.from.name }}</span>
                </template>
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="fieldToShow mb-0">
                  <coozzy-caret-up-icon
                    v-if="!data.item.inbound"
                    class="d-inline" />
                  <coozzy-caret-down-icon
                    v-else
                    class="d-inline" />
                  {{ data.item.subject | displayOptionalValue }}
                  <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
                </p>
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="text-msg mb-0 fieldToShow">
                  {{ data.item ? data.item.bodyText || '' : '' }}
                </p>
              </template>

              <template #cell(icons)="data">
                <coozzy-link-icon
                  v-if="isMailHasReferenceIdTicket(data.item)"
                  :id="'tooltip-target-' + data.item.id"
                  class="icon-link-ticket"
                  @click.native="routeToDetail(isMailHasReferenceIdTicket(data.item))"
                  @click.middle="openMiddleDetail(isMailHasReferenceIdTicket(data.item))" />
                <b-tooltip
                  v-if="isMailHasReferenceIdTicket(data.item)"
                  :target="'tooltip-target-' + data.item.id"
                  triggers="hover">
                  {{ isMailHasReferenceIdTicket(data.item).key }} {{ isMailHasReferenceIdTicket(data.item).title }}
                </b-tooltip>
                <template
                  v-if="hasReminder(data.item.id)">
                  <coozzy-reminder-icon
                    v-if="hasReminder(data.item.id) === 'progress'"
                    class="mr-1 ml-1 reminderIcon"
                    @click.native="forwardToReminder(data.item.id)" />
                  <coozzy-check-mark-icon
                    v-else-if="hasReminder(data.item.id) === 'finish'"
                    v-b-tooltip.hover.html="$t('message.marketingMessages.reminderDone', {date: $options.filters.timestampToDate(getReminder(data.item.id)?.done)})"
                    class="mr-1 ml-1" />
                </template>
                <coozzy-reply-icon
                  v-if="data.item.hasReply"
                  class="mr-1 ml-1" />
                <coozzy-forward-icon
                  v-if="data.item.hasForwarding"
                  class="mr-1 ml-1" />
                <coozzy-confidential-icon
                  v-if="data.item.confidential"
                  class="mr-1 ml-1" />
              </template>
              <template #cell(date)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <span v-else>
                  {{ data.item.timestamp | timestampToDateTime }}</span>
              </template>

              <template #cell(from)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <template v-else-if="getMailConnectedWithContacts(data.item).from.contactId">
                  <router-link :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).from.contactId)">
                    {{ getMailConnectedWithContacts(data.item).from.name }}<br>
                  </router-link>
                  <span>
                    {{ data.item.from.email }}
                  </span>
                </template>
                <template v-else>
                  <span>{{ data.item.from.name }}</span><br>
                  <span>{{ data.item.from.email }}</span>
                </template>
              </template>

              <template #cell(to)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <template v-else-if="data.item.to.length && getMailConnectedWithContacts(data.item).to[0].contactId">
                  <router-link :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).to[0].contactId)">
                    {{ getMailConnectedWithContacts(data.item).to[0].name }}<br>
                  </router-link>
                  <span>
                    {{ data.item.to[0].email }}
                  </span>
                  <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                </template>
                <template v-else>
                  <template v-if="data.item.to.length === 0 && data.item.cc.length > 0">
                    <template v-if="data.item.cc.length && getMailConnectedWithContacts(data.item).cc[0].contactId">
                      <router-link :to="getContactDetailRoute(getMailConnectedWithContacts(data.item).cc[0].contactId)">
                        {{ getMailConnectedWithContacts(data.item).cc[0].name }}<br>
                      </router-link>
                      <span>
                        {{ data.item.cc[0].email }}
                      </span>
                      <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <span>{{ data.item.to.length > 0 ? getMailConnectedWithContacts(data.item).to[0].name : '-' }}</span><br>
                    <span>{{ data.item.to.length > 0 ? data.item.to[0].email : '-' }} </span>
                    <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                  </template>
                </template>
              </template>

              <template #cell(subject)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="fieldToShow">
                  <coozzy-caret-up-icon
                    v-if="!data.item.inbound"
                    class="d-inline" />
                  <coozzy-caret-down-icon
                    v-else
                    class="d-inline" />
                  {{ data.item.subject | displayOptionalValue }}
                  <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
                </p>
              </template>

              <template #cell(message)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="text-msg mb-0 fieldToShow">
                  {{ data.item ? data.item.bodyText || '' : '' }}
                </p>
              </template>
              <template #cell(receivedOpened)="data">
                <div
                  v-if="!data.item.inbound"
                  class="text-center">
                  <template v-if="data.item.deliverData.status === 'DELIVER_STATUS_DELIVERED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else-if="data.item.deliverData.status === 'DELIVER_STATUS_PENDING'">
                    <coozzy-pending-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon
                      v-b-tooltip.hover.left
                      :title="data.item.deliverData.errorMessage" />
                  </template>
                  /
                  <template v-if="data.item.engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon />
                  </template>
                </div>
                <div
                  v-else
                  class="text-center">
                  -
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div
          v-if="columnView"
          id="right"
          class="pl-md-0 rightside">
          <coozzy-card
            class="global-card detail-message mb-3 mt-2">
            <message-detail-content
              v-if="selectedMail"
              :key="selectedMail.id + '_' + dynamicId"
              :email="selectedMail"
              :ticket="isMailHasReferenceIdTicket(selectedMail)"
              @restore-mail="mailRestored"
              @reply-mail="replyMail"
              @reply-all-mail="replyAllMail"
              @forward-mail="forwardMail"
              @archive-mail="mailArchived"
              @refresh-list="refreshList"
              @refresh-reminders="refreshReminders"
              @delete-mail="mailDeleted"
              @print-message="printMessage"
              @forward-as-attachement="forwardAsAttachement" />
            <div
              v-else
              class="block-center">
              <coozzy-envelope-icon class="icon" />  <p class="text-icon">
                {{ $t('message.marketingMessages.selectMessage') }}
              </p>
            </div>
          </coozzy-card>
        </div>
        <div
          v-if="!columnView"
          class="col">
          <b-pagination
            v-model="currentPage"
            align="center"
            class="mb-3"
            :total-rows="messageCount"
            :per-page="nbrPerPage"
            @change="paginateTo" />
        </div>
      </div>
    </div>
    <message-details-modal
      v-if="selectedMail"
      ref="mail-detail-modal"
      :key="selectedMail.id + '_' + dynamicId"
      :email="selectedMail"
      :ticket="isMailHasReferenceIdTicket(selectedMail)"
      @restore-mail="mailRestored"
      @reply-mail="replyMail"
      @reply-all-mail="replyAllMail"
      @forward-mail="forwardMail"
      @archive-mail="mailArchived"
      @refresh-list="refreshList"
      @refresh-reminders="refreshReminders"
      @delete-mail="mailDeleted"
      @print-message="printMessage"
      @forward-as-attachement="forwardAsAttachement" />
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')">
      <div
        v-if="disabledDeleteBtn"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="col p-0">
        <p>{{ textConfirmation }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="actionRead === 'read'"
          size="small"
          class="float-right mb-0"
          design="green"
          @click="setPublish()">
          {{ $t('message.marketingMessages.markAsRead') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="actionRead === 'unread'"
          size="small"
          class="float-right mb-0"
          design="green"
          @click="setPublish()">
          {{ $t('message.marketingMessages.markAsUnRead') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="actionRead === 'archive'"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="archiveMails()">
          {{ $t('message.generic.archive') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="actionRead === 'delete'"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="deleteMails()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="actionRead === 'restore'"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="restoreMails()">
          {{ $t('message.generic.restore') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="actionRead === 'emptyTrash'"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="emptyTrash()">
          {{ $t('message.generic.emptyTrash') }}
        </coozzy-button>
      </div>
    </b-modal>
    <div
      v-if="showPopover"
      id="eventPopover"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverMessage"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="row">
            <div class="col-12 mb-1">
              <p><b>{{ selectedPopOverMessage.subject }}</b></p>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style="white-space: pre-line"
                v-html="selectedPopOverMessage.bodyText" />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <reminder-creation-modal
      v-if="reminderForEdit !== null"
      ref="reminder-creation"
      :key="reminderForEdit ? 'modal-creation'+reminderForEdit.id : 'modal-creation'"
      :reminder-detail="reminderForEdit"
      :source="'message'"
      :references-ids="[selectedMail.id || selectedMail.objectID]"
      @update-reminder="refreshReminders()" />
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :key="selectedReminder.id"
      :reminder-detail="selectedReminder"
      @create-reminder-click="createReminderClicked"
      @update-reminder="refreshReminders()" />
  </section>
</template>

<script>
import Vue from 'vue'
import MessageApi from '../misc/apis/MessageApi'
import ContactApi from '../misc/apis/ContactApi'
import TicketApi from '../misc/apis/TicketApi'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyCaretUpIcon from '@/framework/components/icons/CoozzyCaretUpIcon'
import CoozzyCaretDownIcon from '@/framework/components/icons/CoozzyCaretDownIcon'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyPaperClipIcon from '../framework/components/icons/CoozzyPaperClipIcon'
import { user } from '@/mixins/user'
import { contact } from '@/mixins/contact'
import { eventBus, mail } from '@/mixins/mail'
import { tables } from '@/mixins/tables'
import { responsivity } from '@/mixins/responsivity'
import { ContentLoader } from 'vue-content-loader'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyReplyIcon from '@/framework/components/icons/CoozzyReplyIcon'
import CoozzyForwardIcon from '@/framework/components/icons/CoozzyForwardIcon'
import CoozzyCheckMarkIcon from '@/framework/components/icons/CoozzyCheckMarkIcon'
import CoozzyConfidentialIcon from '@/framework/components/icons/CoozzyConfidentialIcon'
import CalendarApi from '@/misc/apis/CalendarApi'
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CoozzyLinkIcon from '@/framework/components/icons/CoozzyLinkIcon.vue'
import CoozzyReminderIcon from '@/framework/components/icons/CoozzyReminderIcon.vue'
import CoozzyViewListIcon from '@/framework/components/icons/CoozzyViewListIcon.vue'
import MessageDetailContent from '@/contact/components/MessageDetailContent.vue'
import MessageDetailsModal from '@/contact/components/MessageDetailsModal.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'
import CoozzyPendingIcon from '@/framework/components/icons/CoozzyPendingIcon.vue'
import Split from 'split.js'
import CoozzyListIcon from '@/framework/components/icons/CoozzyListIcon.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyEnvelopeIcon from '@/framework/components/icons/CoozzyEnvelopeIcon.vue'

export default {
  name: 'MessagesView',
  components: {
    CoozzyEnvelopeIcon,
    CoozzyCard,
    CoozzyListIcon,
    CoozzyPendingIcon,
    CoozzyFailIcon,
    CoozzySuccessIcon,
    CoozzyReminderIcon,
    CoozzyLinkIcon,
    CoozzyForwardIcon,
    CoozzyReplyIcon,
    CoozzyFormInput,
    CoozzyDropdownSpaceDivider,
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyDropdownItem,
    CoozzyCaretDownIcon,
    CoozzyCaretUpIcon,
    CoozzyFormSelect,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyButton,
    CoozzyFormCheckbox,
    CoozzyPaperClipIcon,
    ContentLoader,
    CoozzyCheckMarkIcon,
    ReminderDetailsModal,
    ReminderCreationModal,
    CoozzyConfidentialIcon,
    CoozzyViewListIcon,
    MessageDetailContent,
    MessageDetailsModal
  },
  mixins: [user, contact, mail, tables, responsivity],
  props: {
    s: {
      type: String,
      default: null
    },
    f: {
      type: String,
      default: null
    },
    m: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedEmailId: null,
      abortController: null,
      shortContent: false,
      isBusy: false,
      querySearch: '',
      checkfilter: false,
      filterFolder: 'FILTER_FOLDER_INBOUND',
      filterMailboxes: [],
      disabledDeleteBtn: false,
      actionRead: null,
      emailToReply: null,
      textConfirmation: null,
      selectedMail: null,
      selectedEntries: [],
      messageList: [],
      listTickets: [],
      usedContacts: [],
      nbrPerPage: 10,
      currentPage: 1,
      messageCount: 0,
      appRefreshAction: '',
      loading: false,
      showPopover: false,
      selectedPopOverMessage: null,
      page: {
        left: 0,
        top: 0
      },
      isWaiting: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          tdClass: 'align-middle checkbox-cell checkbox-td',
          view: null
        },
        {
          key: 'infoMessage',
          label: '',
          tdClass: 'align-middle width-full-content',
          thClass: 'align-middle',
          view: true
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle text-center adjustWidth',
          thClass: 'align-middle text-center adjustWidth',
          view: true
        },
        {
          key: 'icons',
          label: '',
          tdClass: 'align-middle text-center adjustWidth',
          thClass: 'align-middle text-center adjustWidth',
          view: false
        },
        {
          key: 'date',
          label: this.$t('message.tableColumnHeadings.date'),
          tdClass: 'align-middle text-left',
          thClass: 'align-middle text-left',
          view: false
        },
        {
          key: 'from',
          label: this.$t('message.tableColumnHeadings.from'),
          tdClass: 'align-middle',
          thClass: 'align-middle',
          view: false
        },
        {
          key: 'to',
          label: this.$t('message.tableColumnHeadings.to'),
          tdClass: 'align-middle',
          thClass: 'align-middle',
          view: false
        },
        {
          key: 'subject',
          label: this.$t('message.tableColumnHeadings.subject'),
          thClass: 'align-middle',
          tdClass: 'fieldToShow',
          view: false
        },
        {
          key: 'message',
          label: this.$t('message.tableColumnHeadings.message'),
          thClass: 'align-middle message-text',
          tdClass: 'fieldToShow',
          view: false
        }
      ],
      fieldForOutbound:
      {
        key: 'receivedOpened',
        label: this.$t('message.tableColumnHeadings.receivedOpened'),
        thClass: 'align-middle',
        tdClass: 'align-middle',
        view: false
      },
      disabledCheckbox: false,
      mailIdDeletedForClosingModal: [],
      filterUnreadMsg: false,
      reminders: [],
      selectedReminder: null,
      reminderForEdit: null,
      dynamicId: '',
      debounceTimeout: null,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      dataFields: [],
      columnView: true,
      mailBoxFilterChanged: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.messages')
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails', 'getMailboxesForUserId', 'getChangingStatusMails']),
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.messageCount : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries : this.selectedEntries
    },
    calculateClassHideMailsFromOtherFolders() {
      if (this.filterFolder === 'FILTER_FOLDER_INBOUND' || this.filterFolder === 'FILTER_FOLDER_OUTBOUND' || this.filterFolder === 'FILTER_FOLDER_ALL') {
        return 'other'
      }
      if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
        return 'archive'
      }
      if (this.filterFolder === 'FILTER_FOLDER_TRASH') {
        return 'trash'
      }
      return ''
    }
  },
  watch: {
    getSentMails: function (newVal) {
      if (newVal.length > 0) {
        this.loadMailboxesForUserId(this.userId)
        if (this.mailboxesForCurrentUser.length > 0) {
          this.filterMessages(1)
        }
      }
    },
    messageList: function () {
      this.getReminders()
      this.getTickets()
      // mark checkbox as true for every email on messageList with the id of email exist on selectedEntries
      this.$nextTick(() => {
        this.markCheckbox()
      })
    },
    checkfilter: function () {
      this.clearCheckbox()
    },
    filterUnreadMsg() {
      this.currentPage = 0
      this.filterMessages(1)
      this.checkfilter = true
    },
    mailboxesForCurrentUser: function () {
      // Restore mailboxes filter with only that mailboxes that are actually allowed by the user
      if (this.m && this.mailboxesForCurrentUser && this.mailboxesForCurrentUser.length) {
        const mailboxIds = this.m.split(',')
        this.filterMailboxes = this.mailboxesForCurrentUser.filter(box => mailboxIds.includes(box.id))
      }
      this.filterMessages(1)
    },
    filterFolder: function (newVal, oldVal) {
      this.checkfilter = true
      this.currentPage = 0
      this.clearCheckbox()
      this.filterMessages(1)
      if (newVal !== oldVal && this.$route.query.f !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            s: this.s,
            f: newVal,
            m: this.m
          }
        })
      }
      if (newVal !== 'FILTER_FOLDER_OUTBOUND' && newVal !== 'FILTER_FOLDER_ALL') {
        this.fields = this.fields.filter(x => x.key !== 'receivedOpened')
      } else {
        this.fields.push(this.fieldForOutbound)
      }
      this.dataFields = this.fields.filter(x => x.view === this.columnView || x.view === null)
    },
    querySearch: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkfilter = true
        this.currentPage = 1
        this.clearCheckbox()
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
        if (this.$route.query.s !== newVal) {
          this.$router.replace({
            name: this.$route.name,
            query: {
              s: newVal,
              f: this.f,
              m: this.m
            }
          })
        }
        this.filterMessages(1)
      }
    },
    filterMailboxes: function (newVal, oldVal) {
      this.mailBoxFilterChanged = true
      this.checkfilter = true
      this.clearCheckbox()
      if (!this.columnView) {
        this.currentPage = 0
        this.handleCheckboxesInsideTable(false)
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      }
      this.filterMessages(1)

      const newString = newVal.map(e => e.id).join(',')
      const oldString = oldVal.map(e => e.id).join(',')

      localStorage.setItem('filterMailboxesMessagesOverview', newString)

      this.loadEmailCounts({
        mailboxIds: newString !== '' ? newString : this.mailboxesForCurrentUser.map(box => box.id).join(),
        force: true
      })
      if (newString !== oldString && this.$route.query.m !== newString) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            s: this.s,
            f: this.f,
            m: newString
          }
        })
      }
    },
    currentPage: function () {
      if (!this.columnView) {
        this.handleCheckboxesInsideTable(false)
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      }
    },
    nbrPerPage: function (value) {
      this.filterMessages(1)
      this.checkfilter = true
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      if (!this.columnView) {
        localStorage.setItem('nbrPerPageMessagesOverview', value)
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = await this.filterAllMessages()
      }
    }
  },
  beforeDestroy() {
    const tableBody = this.$refs.messagesTable.$el.querySelector('.b-table tbody')
    tableBody.removeEventListener('scroll', this.handleScroll)
  },
  created: function () {
    eventBus.$on('newEmailsComing', () => {
      if (!this.mailBoxFilterChanged) {
        this.filterMessages(this.currentPage, true)
      }
    })
  },
  mounted: function () {
    const sizes = localStorage.getItem('splitSizesMessagesView')
      ? JSON.parse(localStorage.getItem('splitSizesMessagesView'))
      : [30, 70]
    Split(['#left', '#right'], {
      sizes: sizes,
      minSize: 100,
      gutterSize: 5,
      cursor: 'col-resize',
      onDragEnd: sizes => {
        localStorage.setItem('splitSizesMessagesView', JSON.stringify(sizes))
      }
    })
    this.resetStatusMails()
    if (localStorage.getItem('is-column-view-message')) {
      this.columnView = localStorage.getItem('is-column-view-message') === 'true'
      if (this.columnView) {
        this.nbrPerPage = 20
      } else {
        this.nbrPerPage = localStorage.getItem('nbrPerPageMessagesOverview') ? parseInt(localStorage.getItem('nbrPerPageMessagesOverview')) : 10
      }
    } else {
      this.columnView = true
      this.nbrPerPage = 20
    }
    this.$nextTick(function () {
      this.loadMailboxesForUserId(this.userId)
      if (this.mailboxesForCurrentUser.length > 0) {
        this.filterMessages(1)
      } else {
        this.isBusy = false
      }
      this.$refs.searchInputText?.setFocusInput()
    })
    this.getReminders()
    this.getTickets()
    // this.countUnreadMails(true)
    // Restore folder filter
    if (this.f && ['FILTER_FOLDER_INBOUND', 'FILTER_FOLDER_OUTBOUND', 'FILTER_FOLDER_ALL', 'FILTER_FOLDER_ARCHIVED', 'FILTER_FOLDER_TRASH', 'FILTER_FOLDER_SPAM'].includes(this.f)) {
      this.filterFolder = this.f
    }
    // Restore mailboxes filter with only that mailboxes that are actually allowed by the user
    if (this.m && this.mailboxesForCurrentUser && this.mailboxesForCurrentUser.length) {
      const mailboxIds = this.m.split(',')
      this.filterMailboxes = this.mailboxesForCurrentUser.filter(box => mailboxIds.includes(box.id))
    }
    if (localStorage.getItem('filterMailboxesMessagesOverview') && localStorage.getItem('filterMailboxesMessagesOverview') !== '' && this.mailboxesForCurrentUser && this.mailboxesForCurrentUser.length) {
      const mailboxIds = localStorage.getItem('filterMailboxesMessagesOverview')?.split(',') || []
      this.filterMailboxes = this.mailboxesForCurrentUser.filter(box => mailboxIds.includes(box.id))
    }
    if (this.s && this.s.length > 0) {
      this.querySearch = this.s
    }
    if (this.filterFolder !== 'FILTER_FOLDER_OUTBOUND' && this.filterFolder !== 'FILTER_FOLDER_ALL') {
      this.fields = this.fields.filter(x => x.key !== 'receivedOpened')
    } else {
      this.fields.push(this.fieldForOutbound)
    }
    this.dataFields = this.fields.filter(x => x.view === this.columnView || x.view === null)
    this.$nextTick(() => {
      const tableBody = this.$refs.messagesTable.$el.querySelector('.b-table tbody')
      tableBody.addEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setEmailToForwardAsAttachment',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailCC',
      'setSendMailOnlyCurrentContact',
      'setSendMailHasContext',
      'setMailsLoadedAt',
      'addReadMail',
      'addUnreadMail',
      'setChangingStatusMails',
      'resetStatusMails',
      'setSubject'
    ]),
    tableRowClassCustom(item, type) {
      let unreadStatus = this.tableRowClass(item, type)
      if (item && this.selectedEmailId === item.id) {
        unreadStatus = unreadStatus + ' b-table-row-selected'
      }
      return unreadStatus
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollHeight - parseInt(scrollTop) - clientHeight < 20) {
        this.loadMore()
      }
    },
    changeColumnView(newVal) {
      this.columnView = newVal
      localStorage.setItem('is-column-view-message', newVal)
      this.dataFields = this.fields.filter(x => x.view === this.columnView || x.view === null)
      if (newVal) {
        this.nbrPerPage = 20
      } else {
        this.nbrPerPage = localStorage.getItem('nbrPerPageMessagesOverview') ? parseInt(localStorage.getItem('nbrPerPageMessagesOverview')) : 10
      }
    },
    ...mapActions('message', ['loadMailboxesForUserId']),
    getReminders() {
      CalendarApi.getRemindersByReferenceIds(this.messageList.map(mail => mail.id), this.$store.getters['user/getAccountId'], true)
        .then(response => {
          this.reminders = response.reminders.filter(x => x.referenceIds.find(y => y && y.includes('mail_')))
        })
    },
    getTickets() {
      const ticketReferenceIds = this.messageList.map(mail => mail.referenceIds).flat().filter(x => x && x.includes('ticket_'))
      if (ticketReferenceIds.length > 0) {
        TicketApi.getTicketByIds(ticketReferenceIds)
          .then(response => {
            this.listTickets = response.tickets
          })
      }
    },
    markCheckbox() {
      this.messageList.forEach(email => {
        if (this.selectedEntries.includes(email.id)) {
          document.getElementById('check_' + email.id).checked = true
        }
      })
    },
    isMailHasReferenceIdTicket(message) {
      return this.listTickets?.find(ticket => message.referenceIds.includes(ticket.id))
    },
    refreshReminders() {
      this.getReminders()
      this.reminderForEdit = null
      this.$refs['mail-detail-modal']?.hide()
    },
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.reminderForEdit = reminder
      } else {
        this.reminderForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['reminder-creation']?.show()
      })
    },
    hasReminder(id) {
      let item = this.reminders.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      if (item.length > 0) {
        item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
        if (item[0].done === '0') {
          return 'progress'
        } else {
          return 'finish'
        }
      }
      return null
    },
    getReminder(id) {
      let item = this.reminders.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
      if (item.length > 0) {
        return item[0]
      }
      return null
    },
    forwardToReminder(id) {
      this.selectedReminder = this.getReminder(id)
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    searchInputText() {
      this.checkfilter = true
      this.clearCheckbox()
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        this.filterMessages(1)
      }, 300)
    },
    getMessageText(bodyText) {
      if (bodyText) {
        return this.getTooltipCorrectString(bodyText).trim()
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedMail = null
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    sendEmailModal() {
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    forwardAsAttachement(mediaList, email) {
      this.setSubject('Fwd: ' + email.subject)
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.setEmailToForwardAsAttachment(mediaList)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    changeStatus(type) {
      if (type === 'read') {
        this.actionRead = 'read'
        this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextMarkRead')
      } else if (type === 'unread') {
        this.actionRead = 'unread'
        this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextMarkUnRead')
      } else if (type === 'archive') {
        this.actionRead = 'archive'
        this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextArchive')
      } else if (type === 'delete') {
        this.actionRead = 'delete'
        this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextDelete')
      } else if (type === 'restore') {
        this.actionRead = 'restore'
        if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
          this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextRestoreFromArchive')
        } else {
          this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextRestore')
        }
      } else if (type === 'emptyTrash') {
        this.actionRead = 'emptyTrash'
        this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextEmptyTrash')
      }
      this.$refs.confirmationModal?.show()
    },
    archiveMails() {
      this.disabledDeleteBtn = true
      this.setChangingStatusMails(this.selectedEntriesIds)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      MessageApi.archiveMails(this.selectedEntriesIds)
        .then(async () => {
          for (const mailId of this.selectedEntriesIds) {
            this.addReadMail({
              mailId: mailId,
              accountId: this.accountId
            })
          }
          this.appRefreshAction = 'archive'
          // remove the deleted items from the messageList
          this.messageList = this.messageList.filter(item => !this.selectedEntriesIds.includes(item.id))
          this.disabledCheckbox = false
          this.disabledDeleteBtn = false
          await this.refreshList()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.archive'), { type: 'error' })
        })
    },
    async filterMessages(page = 1, newEmailsComing = false) {
      if (this.filterMailboxes.length > 0 || this.mailboxesForCurrentUser.length > 0) {
        if ((page === 1 && this.columnView) || !this.columnView) {
          if (!newEmailsComing) {
            this.selectedEntries = []
            this.isBusy = true
          }
        }
        this.disabledCheckbox = false
        const unreadFilter = this.filterUnreadMsg === true ? 'APPLIES' : 'UNDEFINED_VALUE'

        // Cancel the previous request, if there is one
        if (this.abortController) {
          this.abortController.abort()
        }

        // Create a new AbortController
        this.abortController = new AbortController()

        try {
          if (((page === 1 && this.columnView) || !this.columnView) && !newEmailsComing) {
            this.messageCount = 0
            this.messageList = []
          }
          const response = await MessageApi.getListMails(
            this.filterFolder,
            [],
            unreadFilter,
            this.nbrPerPage,
            this.filterMailboxes.length > 0
              ? this.filterMailboxes.map(obj => obj.id)
              : this.mailboxesForCurrentUser.map(mailbox => mailbox.id),
            page,
            this.querySearch,
            'DESC',
            this.sortReceivedAt,
            { signal: this.abortController.signal }
            // Pass the cancel token metadata to the request
          )

          // If the request was cancelled, don't update the messages
          if (this.abortController.signal.aborted) {
            return
          }
          if (response) {
            this.messageCount = response.totalCount
            if (((page === 1 && this.columnView) || !this.columnView) && !newEmailsComing) {
              this.messageList = response.mails
              if (this.$refs.header_checkbox) {
                this.$refs.header_checkbox.checked = false
              }
            } else if (newEmailsComing) {
              // add only the new emails to the messageList and must be ordred by receivedAt
              response?.mails?.forEach(mail => {
                if (!this.messageList.find(item => item.id === mail.id)) {
                  this.messageList.unshift(mail)
                }
              })
            } else {
              response?.mails?.forEach(mail => this.messageList.push(mail))
            }
            this.loadContacts()
            this.setMailsLoadedAt(Date.now())
            this.disabledDeleteBtn = false
            this.mailBoxFilterChanged = false
            if (((page === 1 && this.columnView) || !this.columnView) && !newEmailsComing) {
              this.isBusy = false
              this.refreshDone(newEmailsComing)
            }
            this.$nextTick(() => {
              this.currentPage = page
            })
          }
        } catch (error) {
          console.log(error)
          if (error.name === 'AbortError') {
            console.log('Request cancelled:', error.message)
            if ((page === 1 && this.columnView) || !this.columnView) {
              this.isBusy = true
            }
          } else {
            console.error(error)
            if ((page === 1 && this.columnView) || !this.columnView) {
              this.isBusy = false
            }
            this.refreshDone(newEmailsComing)
            this.disabledDeleteBtn = false
          }
          Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
        }
      }
    },
    async filterAllMessages() {
      const unreadFilter = this.filterUnreadMsg === true ? 'APPLIES' : 'UNDEFINED_VALUE'

      try {
        const response = await MessageApi.listMailIds(
          this.filterFolder,
          [],
          unreadFilter,
          this.filterMailboxes.length > 0
            ? this.filterMailboxes.map(obj => obj.id)
            : this.mailboxesForCurrentUser.map(mailbox => mailbox.id),
          this.querySearch,
          'UNDEFINED_SORT_ORDER'
        )

        if (response) {
          return response.ids
        }
      } catch (error) {
        console.log(error)
      }
    },
    loadContacts() {
      ContactApi.contactResolve(
        this.messageList.map(mail => mail.referenceIds).flat().filter(id => id.includes('contact_'))
      ).then(response => {
        this.usedContacts = response.persons.concat(response.companies)
        this.setContacts(this.usedContacts)
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      }).finally(() => {
        this.isBusy = false
      })
    },
    async deleteMails() {
      this.disabledDeleteBtn = true
      this.setChangingStatusMails(this.selectedEntriesIds)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
        await MessageApi.unarchiveMails(this.selectedEntriesIds)
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
          })
      }
      MessageApi.deleteMails(this.selectedEntriesIds)
        .then(async () => {
          await MessageApi.mailMarkRead(this.selectedEntriesIds, this.accountId)
            .then(() => {
              this.countUnreadMails(true)
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
            })
          this.mailIdDeletedForClosingModal = this.mailIdDeletedForClosingModal.concat(this.selectedEntriesIds)
          this.appRefreshAction = 'delete'
          // remove the deleted items from the messageList
          this.messageList = this.messageList.filter(item => !this.selectedEntriesIds.includes(item.id))
          this.disabledCheckbox = false
          this.disabledDeleteBtn = false
          await this.refreshList()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
        })
    },
    restoreMails() {
      this.disabledDeleteBtn = true
      this.setChangingStatusMails(this.selectedEntriesIds)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
        MessageApi.unarchiveMails(this.selectedEntriesIds)
          .then(async () => {
            this.appRefreshAction = 'restore'
            this.filterMessages()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
          })
      } else {
        MessageApi.restoreMails(this.selectedEntriesIds)
          .then(async () => {
            this.appRefreshAction = 'restore'
            this.filterMessages()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
          })
      }
    },
    async emptyTrash() {
      this.setChangingStatusMails(this.selectedEntriesIds)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      this.disabledDeleteBtn = true
      MessageApi.emptyTrash(this.selectedEntriesIds)
        .then(async () => {
          this.appRefreshAction = 'emptyTrash'
          this.filterMessages()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
        })
    },
    async mailDeleted(id) {
      this.appRefreshAction = 'delete'
      this.disabledCheckbox = true
      this.mailIdDeletedForClosingModal.push(id)
      // remove the deleted items from the messageList
      this.messageList = this.messageList.filter(item => item.id !== id)
      this.refreshDone()
    },
    async mailArchived(id) {
      this.appRefreshAction = 'archive'
      this.disabledCheckbox = true
      // remove the deleted items from the messageList
      this.messageList = this.messageList.filter(item => item.id !== id)
      this.refreshDone()
    },
    async mailRestored(id) {
      this.appRefreshAction = 'restore'
      this.disabledCheckbox = true
      // remove the deleted items from the messageList
      this.messageList = this.messageList.filter(item => item.id !== id)
      this.refreshDone()
    },
    async refreshList() {
      let page = this.currentPage
      if (this.messageList.length === 1) {
        page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      }
      this.filterMessages(page)
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.messagesTable) {
        const allCheckboxesComponents = this.$refs.messagesTable.$children[0].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    onRowClicked(item, index, event) {
      this.selectedEmailId = item.id
      this.$refs.messagesTable.clearSelected()
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item.id, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else if (event.target && event.target.parentElement && event.target.parentElement._prevClass && event.target.parentElement._prevClass.includes('reminderIcon')) {
        this.selectedMail = item
        return false
      } else {
        this.showDetail(item)
      }
    },
    onRowDblClicked(item) {
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      this.selectedMail = item
      this.$nextTick(function () {
        this.$refs['mail-detail-modal']?.show()
      })
    },
    onRowMiddleClicked(item, index, event) {
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      if (event.srcElement.classList.contains('icon-link-ticket')) {
        this.openMiddleDetail(this.isMailHasReferenceIdTicket(item))
      }
    },
    onRowHovered(item) {
      this.selectedPopOverMessage = item
    },
    onRowUnHovered() {
      this.selectedPopOverMessage = null
    },
    onMouseMove(e) {
      this.showPopover = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopover && document.getElementById('eventPopover')) {
        const tooltipWidth = document.getElementById('eventPopover').clientWidth
        const tooltipHeight = document.getElementById('eventPopover').clientHeight
        const windowWidth = window.innerWidth
        const windowHeight = document.body.clientHeight
        if ((windowWidth - 100) >= (tooltipWidth + e.pageX)) {
          this.page.left = e.pageX
        } else {
          this.page.left = e.pageX - tooltipWidth
        }
        if ((windowHeight - 100) >= (tooltipHeight + e.pageY)) {
          this.page.top = e.pageY
        } else {
          this.page.top = e.pageY - tooltipHeight
        }
      }
    },
    paginateTo(page) {
      this.filterMessages(page)
    },
    loadMore() {
      if (!this.isBusy) {
        this.filterMessages(this.currentPage + 1)
      }
    },
    replyMail(email) {
      this.prepareReplyMail(email)
    },
    replyAllMail(email) {
      this.prepareReplyAll(email)
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    printMessage(shortContent) {
      this.shortContent = shortContent
    },
    setPublish() {
      if (this.actionRead === 'read') {
        MessageApi.mailMarkRead(this.selectedEntriesIds, this.accountId)
          .then(() => {
            this.$parent.$refs['coozzy-header'].countUnreadMails()
            this.$refs.confirmationModal.hide()
            for (const mailId of this.selectedEntriesIds) {
              this.addReadMail({
                mailId: mailId,
                accountId: this.accountId
              })
            }
            this.handleCheckboxesInsideTable(false)
            if (this.$refs.header_checkbox) {
              this.$refs.header_checkbox.checked = false
            }
            Vue.toasted.show(this.$t('message.successMessages.mailRead'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.mailRead'), { type: 'error' })
          })
      } else if (this.actionRead === 'unread') {
        MessageApi.mailMarkUnread(this.selectedEntriesIds, this.accountId)
          .then(() => {
            this.$parent.$refs['coozzy-header'].countUnreadMails()
            this.$refs.confirmationModal.hide()
            for (const mailId of this.selectedEntriesIds) {
              this.addUnreadMail({
                mailId: mailId,
                accountId: this.accountId
              })
            }
            this.handleCheckboxesInsideTable(false)
            if (this.$refs.header_checkbox) {
              this.$refs.header_checkbox.checked = false
            }
            Vue.toasted.show(this.$t('message.successMessages.mailUnread'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.mailUnread'), { type: 'error' })
          })
      }
    },
    showDetail(mail) {
      this.selectedMail = mail
      if (!this.columnView) {
        this.$nextTick(function () {
          this.$refs['mail-detail-modal']?.show()
        })
      }
    },
    clickOnClear() {
      this.querySearch = ''
      this.filterFolder = 'FILTER_FOLDER_ALL'
      this.filterMailboxes = []
      this.filterUnreadMsg = false
      this.$nextTick(function () {
        this.checkfilter = false
      })
    },
    refreshDone(newEmailsComing = false) {
      if (this.appRefreshAction === 'delete') {
        if (this.$refs['mail-detail-modal'] && this.mailIdDeletedForClosingModal.includes(this.$refs['mail-detail-modal'].email?.id)) {
          this.mailIdDeletedForClosingModal = []
          this.$nextTick(function () {
            // this.$refs['mail-detail-modal'].loading = false
            this.$refs['mail-detail-modal']?.hide()
          })
        }
        Vue.toasted.show(this.$t('message.successMessages.deleteMail'), { type: 'success' })
      } else if (this.appRefreshAction === 'restore') {
        if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
          Vue.toasted.show(this.$t('message.successMessages.restoreMailFromArchive'), { type: 'success' })
        } else {
          Vue.toasted.show(this.$t('message.successMessages.restoreMail'), { type: 'success' })
        }
      } else if (this.appRefreshAction === 'emptyTrash') {
        Vue.toasted.show(this.$t('message.successMessages.emptyTrashMail'), { type: 'success' })
      } else if (this.appRefreshAction === 'archive') {
        Vue.toasted.show(this.$t('message.successMessages.archiveMail'), { type: 'success' })
      }

      this.disabledCheckbox = false
      this.disabledDeleteBtn = false

      if (this.$refs.confirmationModal) {
        this.$refs.confirmationModal.hide()
      }
      if (!newEmailsComing) {
        this.clearCheckbox()
        this.resetStatusMails()
      }

      this.appRefreshAction = ''
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-checkbox .btn-secondary) {
  height: auto;
  min-height: 31px;
  max-height: 50px;
}
:deep(.message-text) {
  width: 330px;
}
:deep(.adjustWidth) {
  max-width: 90px;
  min-width: 85px;
  padding: 0 !important;
    > div {
    max-width: 90px;
    }
}
p.text-msg {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  line-break: anywhere;
}
.detail-message {
  height: calc(70vh + 32.19px);
  overflow: auto;
  -webkit-box-shadow: 1px 1px 6px #ccc;
  box-shadow: 1px 1px 6px #ccc !important;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 0rem;
  border: 0;
}
.parent-container {
  display: flex;
  flex-direction: row;
}
.simple-container {
  :deep(.gutter-horizontal) {
    display: none;
  }
}
:deep(.gutter-horizontal) {
  width: 2px !important;
  border: 1px solid grey;
  height: calc(70vh + 33.19px);
  margin-top: 7px;
  cursor: e-resize;
}
.leftside,
.rightside {
  flex: 1 1 auto;
  overflow: auto;
}
:deep(.table-column-view table.table) {
  width: 100%;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  .width-full-content {
    width: stretch;
  }
  p.text-msg {
    height: 1.5em;
    font-size: 0.8rem;
  }
  tbody {
    height: 70vh;
    overflow-y: scroll;
    display: block;
  }
  tbody tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  tbody tr.b-table-row-selected {
    position: relative;
  }
  tbody tr.b-table-row-selected::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: $color-primary;
  }

  thead {
    width: calc(100% + 17px); /* Adjust for scrollbar */
    display: table;
  }

  &.b-table-selecting > tbody > .b-table-row-selected td {
    background-color: white;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.075)), to(rgba(0, 0, 0, 0.075)));
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat;
    color: inherit;
  }
}

.other :deep(.archivedMail) {
  display: none;
}

.other :deep(.trashedMail) {
  display: none;
}

.archive :deep(.trashedMail) {
  display: none;
}

.archive :deep(.otherMail) {
  display: none;
}

.trash :deep(.archivedMail) {
  display: none;
}

.trash :deep(.otherMail) {
  display: none;
}

:deep(.deletedMail) {
  display: none;
}
//:deep(.checkbox-td) {
  //max-width: 20px;
//}
.block-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  .text-icon{
    font-size: 20px;
  }
  .icon {
    width: 70px;
    height: auto;
  }
}
.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: 270px !important;
  .employees-calendar{
    width: 100%;
    border-radius: 5px;
    background: #ededed;
    color: black;
    font-size: 14px;
    max-width: 500px;
    h4 {
      font-size: 20px;
    }
    span {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 10;
      -webkit-line-clamp: 10; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
</style>
