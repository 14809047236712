<template>
  <div
    :class="commentItem && commentItem.accountInternal === true ? 'hightlight_internal_note  mr-2' : ''"
    class="p-2 pt-2 pb-2 mb-2">
    <div
      v-if="isReadOnly"
      class="row block">
      <div class="d-none d-md-block col width-60">
        <img
          :alt="commentItem.creator.name"
          :src="commentItem.creator && commentItem.creator.profilePictureUrl ? commentItem.creator.profilePictureUrl : '/avatar.png'"
          class="align-self-center avatar big">
      </div>
      <div class="col pl-md-0">
        <div class="pb-0 d-flex align-items-center">
          <b>{{ commentItem.creator.name }}</b> <span class="ml-3"> <i>{{
            commentItem.createdAt | timestampToDateTime
          }}</i></span>
          <span
            v-if="commentItem.historyEntries.length > 0"
            class="ml-1 text-grey-light"> {{ $t('message.generic.edited') }} </span>
          <span
            v-if="commentItem.accountInternal === true"
            class="ml-2 d-flex align-items-center font-weight-normal text-grey-light internalNoteText">
            <coozzy-padlock-icon
              :active="false"
              name="internalNote" /><span class="pl-1">{{ $t('message.ticketOverview.ticket.internalNote') }}</span></span>
          <coozzy-button
            v-if="commentItem.creator.userId === userId"
            class="only-on-hover pb-0 pt-0 pr-1"
            design="transparent"
            @click="editComment">
            <coozzy-edit-icon />
          </coozzy-button>
          <coozzy-button
            v-if="commentItem.creator.userId === userId"
            class="only-on-hover pb-0 pt-0 pl-0"
            design="transparent"
            @click="$bvModal.show('confirmationModalDeleteComment' + commentItem ? commentItem.id : '')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
        <template
          v-if="commentItem.note">
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          <p
            v-if="showFullText"
            class="mb-2 note-text mt-1">
            {{ commentItem.note }}
          </p>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          <p
            v-else
            class="mb-2 note-text mt-1">
            {{ truncatedText }}
          </p>
          <b-link
            v-if="showMoreButtonVisible"
            @click="toggleShowMore">
            {{ showFullText ? $t('message.generic.showLess') : $t('message.generic.showMore') }}
          </b-link>
        </template>
        <div
          v-if="filteredMediasList.length > 0"
          class="row m-0">
          <div class="col-12">
            <div class="row">
              <div
                v-for="media in filteredMediasList"
                :key="media.media ? media.media.id : media.id"
                class="col-4 px-0">
                <template
                  v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)">
                  <div
                    class="border mr-1">
                    <a
                      class="cardDocs cursor-normal"
                      target="_blank">
                      <div
                        class="row m-0 d-flex card-file"
                        style="display: inline;">
                        <div
                          class="col-2 icon d-flex align-items-center mr-auto"
                          style="display: inherit; margin-right:15px;">
                          <span
                            @click="downloadFiles(media, $event)">
                            <coozzy-file-download-icon
                              class="cursor-pointer"
                              style="max-width:15px;" />
                          </span>
                        </div>
                        <div
                          class="col-10 pl-0 pr-1 cursor-pointer"
                          style="display: inline;"
                          @click="openFile(media, $event)">
                          <p
                            class="m-0 docName d-inherit"
                            style="display: inline;">
                            {{ media.media ? media.media.filename : media.filename || 'unknown_filename' }}</p>
                          <p
                            class="m-0 docSize d-inherit"
                            style="display: none;">{{ Math.max(Math.floor((media.size) / 1024), 1) + ' KB' }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </template>
              </div>
            </div>
            <div class="row img">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-12 col-md-2 square">
                  <div
                    :style="{ 'background-image': 'url(' + media.url + ')' }"
                    class="uploaded-file cursor-pointer"
                    style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                    @click="imageClicked(media)" />
                </div>
              </template>
            </div>
            <div class="row video">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-12 col-md-2 square">
                  <coozzy-thumbnail
                    v-if="getMediaUrl(media) !== null"
                    :key="media.id"
                    :video-src="getMediaUrl(media)"
                    :video-url="media.url"
                    class="uploaded-file cursor-pointer"
                    @imageClicked="openVideo(media)" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      id="block-comment"
      class="row">
      <div class="d-none d-md-block col width-60">
        <img
          :src="avatar ? avatar : '/avatar.png'"
          alt="Avatar"
          class="align-self-center avatar big">
      </div>
      <div
        v-if="!commentInputEnabled"
        class="col pl-md-0">
        <coozzy-form-input
          :placeholder="$t('message.ticketOverview.ticket.newComment')"
          @focus="onFocus" />
      </div>
      <div
        v-else
        class="col pl-md-0">
        <mentionable
          :items="filteredUsers"
          :keys="['@']"
          insert-space
          offset="6"
          placement="bottom"
          @apply="userSelected">
          <coozzy-form-textarea
            id="description"
            ref="newComment"
            v-model="comment"
            :disabled="loading"
            :initial="comment"
            :placeholder="$t('message.ticketOverview.ticket.newComment')"
            :rows="3"
            :show-label="false"
            :text="comment"
            :value-text-area="comment"
            name="new-comment" />
          <template #no-result>
            <div class="dim">
              No result
            </div>
          </template>
        </mentionable>
      </div>
    </div>
    <div class="row">
      <div
        v-if="commentInputEnabled"
        class="col offset-60 mt-1 pl-md-0">
        <div class="row">
          <div class="col-12 col-md-6 containerFileInput">
            <coozzy-form-file-input
              id="file"
              ref="attachmentUpload"
              :disabled="uploading"
              :show-name="false"
              accept="text/plain, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
              ,application/vnd.ms-powerpoint,  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf,   image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx .pdf"
              class=""
              multiple
              @change="addFile" />
          </div>
          <div class="col-12 col-md-6">
            <coozzy-button
              :disabled="loading || loading2"
              class="border float-right mb-0 ml-2"
              size="small"
              @click="cancelComment">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              :disabled="loading || loading2"
              class="float-right mb-0"
              design="green"
              size="small"
              @click="commentItem ? updateComment() : addNewComment()">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
            <div
              v-if="!isPublic && ((commentItem && commentItem.accountInternal === true) || !commentItem)"
              class="d-flex float-right mr-2 mb-0 internalNoteBtn">
              <coozzy-form-checkbox
                ref="checkbox_internal"
                v-model="internalComment"
                :class="commentItem && commentItem.accountInternal === true ? 'disabled' : ''"
                :disabled="commentItem && commentItem.accountInternal === true"
                :initial="internalComment"
                class="pr-1 mr-1">
                <span
                  class="pr-11 d-flex align-items-center font-weight-normal">
                  <coozzy-padlock-icon
                    :active="true"
                    name="internalNote" /><span class="pl-1">{{
                      $t('message.ticketOverview.ticket.internalNote')
                    }}</span></span>
              </coozzy-form-checkbox>
            </div>
          </div>
        </div>
        <div
          v-if="commentInputEnabled && filteredMediasList.length > 0"
          class="row m-0">
          <div class="col-12">
            <div class="row">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                  :key="media.id"
                  class="col-12 p-0 square">
                  <div
                    class="file-block">
                    <a
                      :href="media.url"
                      tabindex="0"
                      target="_blank">
                      {{ media.filename }}
                    </a>
                    <coozzy-button
                      class="delete-icon"
                      design="transparent"
                      @click="deleteMedia($event, media.id)">
                      <coozzy-delete-icon />
                    </coozzy-button>
                  </div>
                </div>
              </template>
            </div>
            <div class="row img">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-12 col-md-2 square">
                  <div
                    :style="{ 'background-image': 'url(' + media.url + ')' }"
                    class="uploaded-file cursor-pointer"
                    style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                    @click="imageClicked(media)">
                    <coozzy-button
                      class="delete-icon image"
                      design="transparent"
                      @click="deleteMedia($event, media.id)">
                      <coozzy-delete-icon />
                    </coozzy-button>
                  </div>
                </div>
              </template>
            </div>
            <div class="row video">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-12 col-md-2 square">
                  <coozzy-thumbnail
                    v-if="getMediaUrl(media) !== null"
                    :key="media.id"
                    :video-src="getMediaUrl(media)"
                    :video-url="media.url"
                    class="uploaded-file cursor-pointer"
                    @imageClicked="openVideo(media)" />
                  <coozzy-button
                    class="delete-icon video"
                    design="transparent"
                    @click="deleteMedia($event, media.id)">
                    <coozzy-delete-icon />
                  </coozzy-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-if="commentItem"
        :id="'confirmationModalDeleteComment' + commentItem ? commentItem.id : ''"
        :ref="'confirmationModalDeleteComment'+ commentItem ? commentItem.id : ''"
        :title="$t('message.ticketOverview.ticket.deleteCommentConfirmation.title')"
        hide-footer
        no-close-on-backdrop>
        <template>
          <div class="col p-0">
            <p>{{ $t('message.ticketOverview.ticket.deleteCommentConfirmation.text') }}</p>
          </div>
          <div class="col p-0">
            <coozzy-button
              class="mb-0 border"
              design="transparent"
              size="small"
              @click="$bvModal.hide('confirmationModalDeleteComment' + commentItem ? commentItem.id : '')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              class="float-right mb-0"
              design="green"
              size="small"
              @click="deleteComment()">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        v-if="selectedObjectVideo !== ''"
        ref="show-video"
        hide-footer
        hide-header>
        <div class="row mb-2">
          <div class="col">
            <b-embed
              controls
              type="video">
              <source
                :src="selectedObjectVideo"
                type="video/webm">
              <source
                :src="selectedObjectVideo"
                type="video/mp4">
            </b-embed>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import NoteApi from '@/misc/apis/NoteApi'
import Vue from 'vue'
import { user } from '@/mixins/user'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import MediaApi from '@/misc/apis/MediaApi'
import CoozzyPadlockIcon from '@/framework/components/icons/CoozzyPadlockIcon'
import { media } from '@/mixins/media'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import { saveAs } from 'file-saver'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { Mentionable } from 'vue-mention'

export default {
  name: 'Comment',
  components: {
    CoozzyFileDownloadIcon,
    CoozzyFormCheckbox,
    CoozzyFormFileInput,
    CoozzyThumbnail,
    CoozzyDeleteIcon,
    CoozzyEditIcon,
    CoozzyFormInput,
    CoozzyButton,
    CoozzyFormTextarea,
    CoozzyPadlockIcon,
    Mentionable
  },
  mixins: [user, media],
  props: {
    referenceId: {
      type: String,
      default: ''
    },
    oldComment: {
      type: String,
      default: ''
    },
    commentItem: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    },
    contactList: {
      type: Array,
      default: () => []
    },
    employeeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showFullText: false,
      maxLines: 10,
      loading: false,
      loading2: false,
      comment: this.oldComment !== '' ? this.oldComment : '',
      commentInputEnabled: false,
      isReadOnly: this.readOnly,
      mediasList: [],
      attachment: [],
      galleryImages: [],
      selectedObjectVideo: '',
      mediaUpdated: false,
      internalComment: false,
      uploading: false,
      listMentionedUsers: []
    }
  },
  computed: {
    avatar() {
      const profile = this.$store.getters['user/getCurrentUser']
      if (profile) {
        if (profile.picture.includes('gravatar')) {
          return '/avatar.png'
        }
        return profile.picture
      } else {
        return '/avatar.png'
      }
    },
    filteredMediasList() {
      const array = this.mediasList
      array.sort((val1, val2) => {
        return parseInt(val2.uploadedAt) - parseInt(val1.uploadedAt)
      })
      return array
    },
    filteredUsers() {
      const array = []
      this.employeeList.filter(e => !e.deleted && !e.blocked).forEach(user => {
        const obj = {
          id: user.id,
          accountId: user.accountId,
          value: user.profile?.firstName + ' ' + user.profile?.lastName
        }
        array.push(obj)
      })
      return array
    },
    truncatedText() {
      const lines = this.commentItem.note.split('\n')
      if (lines.length > this.maxLines) {
        return lines.slice(0, this.maxLines).join('\n')
      } else {
        return this.commentItem.note
      }
    },
    showMoreButtonVisible() {
      const lines = this.commentItem.note.split('\n')
      return lines.length > this.maxLines
    }
  },
  watch: {
    oldComment: function (newVal) {
      if (newVal === '') {
        this.comment = newVal
        this.$emit('comment-changed', false)
      }
    },
    comment: function (newVal) {
      if (newVal !== '') {
        this.$emit('comment-changed', true)
        this.$emit('comment-value', newVal)
      }
    }
  },
  mounted() {
    if (this.commentItem) {
      this.internalComment = this.commentItem.accountInternal
      if (this.commentItem.mediaIds.length > 0) {
        MediaApi.getListMedia(this.commentItem.mediaIds)
          .then(response => {
            for (const media of response.media) {
              this.mediasList.push(media)
            }
          })
          .catch(() => {
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      }
      if (this.commentItem.mentions.length > 0) {
        this.commentItem.mentions.forEach(mention => {
          const user = this.employeeList.find(e => e.id === mention.userId)
          if (user) {
            const obj = {
              id: mention.userId,
              accountId: mention.accountId,
              value: user.profile?.firstName + ' ' + user.profile?.lastName
            }
            this.listMentionedUsers.push(obj)
          }
        })
      }
    }
  },
  methods: {
    toggleShowMore() {
      this.showFullText = !this.showFullText
    },
    downloadFiles(media, e) {
      e.preventDefault()
      const url = media.media ? media.media.url : media.url
      const fileName = media.media ? media.media.filename : media.filename
      saveAs(url, fileName)
    },
    openFile(media, e) {
      e.preventDefault()
      const mimeType = media.media ? media.media.mimeType : media.mimeType
      if (mimeType.includes('pdf')) {
        const url = media.media ? media.media.url : media.url
        window.open(url, '_blank')
      } else {
        this.downloadFiles(media, e)
      }
    },
    userSelected(user) {
      this.listMentionedUsers.push(user)
    },
    openVideo(media) {
      this.selectedObjectVideo = media.url
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }
      return media.url
    },
    onFocus() {
      this.commentInputEnabled = true
      this.$nextTick(function () {
        this.$el.querySelector('#block-comment textarea').focus()
      })
    },
    cancelComment() {
      if (this.commentItem) {
        this.isReadOnly = true
      } else {
        this.attachment = []
        this.mediasList = []
      }
      this.loading2 = false
      this.commentInputEnabled = false
      this.internalComment = false
      this.comment = ''
      this.$emit('comment-changed', false)
    },
    addNewComment() {
      if (this.comment !== '' || this.mediasList.length > 0) {
        this.loading = true
        if (this.$store.getters['user/getCurrentUser']) {
          const mentions = []
          this.listMentionedUsers.forEach(user => {
            if (this.comment.includes(user.value) && !mentions.find(x => x.userId === user.id)) {
              mentions.push({
                userId: user.id,
                accountId: user.accountId
              })
            }
          })
          NoteApi.newComment(this.accountId, [this.referenceId], this.comment, this.internalComment, this.mediasList.map(x => x.id), mentions)
            .then(async response => {
              this.$emit('comment-added', response.note)
              this.comment = ''
              this.attachment = []
              this.mediasList = []
              this.commentInputEnabled = false
              this.internalComment = false
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.note'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          const name = localStorage.getItem('unregisteredName' + this.email) ? localStorage.getItem('unregisteredName' + this.email) : ''
          NoteApi.newCommentUnregistered(this.$route?.query?.email || '', name, [this.referenceId], this.comment, this.mediasList.map(x => x.id))
            .then(async response => {
              this.$emit('comment-added', response.note)
              this.comment = ''
              this.attachment = []
              this.mediasList = []
              this.commentInputEnabled = false
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.note'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    editComment() {
      this.isReadOnly = false
      this.commentInputEnabled = true
      this.comment = this.commentItem.note
    },
    updateComment() {
      this.loading = true
      if (this.commentItem.note !== this.comment || this.mediaUpdated === true) {
        const mentions = []
        this.listMentionedUsers.forEach(user => {
          if (this.comment.includes(user.value) && !mentions.find(x => x.userId === user.id)) {
            mentions.push({
              userId: user.id,
              accountId: user.accountId
            })
          }
        })
        NoteApi.updateComment(this.commentItem.id, this.comment, this.mediasList.map(x => x.id), mentions)
          .then(response => {
            this.$emit('comment-updated', response.note)
            this.comment = ''
            this.isReadOnly = true
            this.commentInputEnabled = false
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.note'), { type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.comment = ''
        this.isReadOnly = true
        this.commentInputEnabled = false
        this.loading = false
      }
    },
    deleteMedia(e, id) {
      e.stopPropagation()
      if (this.mediasList) {
        this.mediasList = this.mediasList.filter(el => el.id !== id)
        this.mediaUpdated = true
      }
    },
    uploadClicked() {
      if (!this.isReadOnly) {
        const btn = this.$refs.attachmentUpload.$el.children
        btn[0].click()
      }
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    async addFile(event) {
      this.attachment = await this.getFilesFromEvent(event)
      this.loading2 = true
      if (this.attachment.length > 0) {
        await this.sendFile()
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
    },
    async sendFile() {
      this.uploading = true
      const response = await this.uploadMedia(this.attachment)
      if (response.length > 0) {
        for (const media of response) {
          this.mediasList.push(media)
        }
        this.$nextTick(() => {
          this.attachment = []
          this.uploading = false
          this.loading2 = false
          this.mediaUpdated = true
          this.$nextTick(() => {
            this.$refs?.attachmentUpload?.resetPlaceholder()
          })
        })
      } else {
        this.uploading = false
        this.loading2 = false
      }
    },
    deleteComment() {
      NoteApi.deleteComment(this.commentItem.id)
        .then(() => {
          this.$emit('comment-deleted', this.commentItem)
          this.$refs['confirmationModalDeleteComment' + this.commentItem ? this.commentItem.id : ''].hide()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.note'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.block {
  .only-on-hover {
    visibility: hidden;
    line-height: 0;
  }

  &:hover .only-on-hover {
    visibility: visible;
  }
}

.uploaded-file {
  max-height: 100px;
  background-position: center center;
  background-size: cover;
  min-height: 5em;
}

.delete-icon.image {
  position: absolute;
  right: 15px;
}

.delete-icon.video {
  position: absolute;
  right: 15px;
  top: 5px;
}

.file-block {
  display: flex;
  align-items: center;
}

.width-60 {
  min-width: 60px;
  max-width: 60px;
}

.spinner_personnalised {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.15em !important;
}

.subtitle {
  color: #878786;
  font-weight: 500;
}

.img .media-block:first-child,
.video .media-block:first-child {
  padding-left: 0 !important;
}

.img .media-block:last-child,
.video .media-block:last-child {
  padding-right: 0 !important;
}

.note-text {
  white-space: pre-line;
  word-break: break-word;
}

@media (min-width: 768px) {
  .uploaded-file {
    max-height: 100px;
    min-height: 3em;
  }
  .offset-60 {
    padding-left: 60px !important;
  }
}

@media (min-width: 992px) {
  .uploaded-file {
    max-height: 110px;
    min-height: 3.5em;
  }
}

@media (min-width: 1200px) {
  .uploaded-file {
    max-height: 120px;
    min-height: 5em;
  }
}

@media (min-width: 1600px) {
  .uploaded-file {
    max-height: 130px;
    min-height: 6em;
  }
}

@media (min-width: 1900px) {
  .uploaded-file {
    max-height: 130px;
    min-height: 7em;
  }
}

.containerFileInput {
  position: relative;
}

:deep(.containerFileInput .custom-file-input:focus~.custom-file-label) {
  border-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer;
}

:deep(.containerFileInput .custom-file-input~.custom-file-label) {
  border-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer;
  background: transparent !important;
}

:deep(.containerFileInput .custom-file-input ~ .custom-file-label[data-browse]::after) {
  display: none;
}

.internalNoteBtn {
  height: 31px;
  align-items: center;
  font-size: 0.875rem;

  svg {
    margin-bottom: 1px;
  }
}

.internalNoteText {
  height: 21px;
  align-items: center;
  font-size: 0.875rem;

  svg {
    margin-bottom: 2px;
  }
}

.hightlight_internal_note {
  background-color: #e6eff9;
}

:deep(.internalNote .disabled) {
  opacity: 0.6;
}

:deep(.internalNoteBtn .custom-control-label::before), :deep(.internalNoteBtn .custom-control-label::after),
:deep(.internalNoteText .custom-control-label::before), :deep(.internalNoteText .custom-control-label::after) {
  left: -1.25rem;
  top: 0.1rem;
}
</style>
