<template>
  <section>
    <b-modal
      id="modal-send-mail"
      ref="modal-send-mail"
      :modal-class="['has-confirmation-modal', minimized ? 'minimized-block' : 'full-block', minus ? 'minus-block' : '']"
      no-close-on-backdrop
      no-close-on-esc>
      <template #modal-header>
        <h5
          v-if="minus"
          class="modal-title">
          <span v-if="subject !== ''">
            {{ subject.length > 20 ? subject.substring(0, 20) + '...' : subject }}
          </span>
          <span v-else>
            {{ $t('message.generic.email') }}
          </span>
        </h5>
        <h5
          v-else
          class="modal-title">
          {{ $t('message.marketingMessages.sendMail') }}
          <span
            v-if="sendMailData.secondaryTitle && sendMailData.toAllTo"
            class="secondary-title ml-2">{{ $t('message.marketingMessages.secondOneMailTitle') }}</span>
          <span
            v-else-if="sendMailData.secondaryTitle"
            class="secondary-title ml-2">{{
              $t('message.marketingMessages.secondTitle')
            }}</span>
        </h5>
        <div class="d-flex">
          <coozzy-button
            design="transparent"
            @click="minusEvent">
            <coozzy-minus-icon name="minus" />
          </coozzy-button>
          <coozzy-button
            v-if="!minimized"
            design="transparent"
            @click="changeMinimized">
            <coozzy-minimize-icon name="minimize" />
          </coozzy-button>
          <coozzy-button
            v-if="minimized"
            class="ml-2"
            design="transparent"
            size="small"
            @click="changeMinimized">
            <coozzy-full-screen-icon name="full-screen" />
          </coozzy-button>
          <b-button
            class="close ml-2"
            size="sm"
            @click="discardChanges">
            x
          </b-button>
        </div>
      </template>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="row">
        <div class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.marketingMessages.mail.mailbox') }}*</label>
          <coozzy-form-select
            v-model="from"
            :state="$v.from.$dirty && $v.from.$error ? false : null">
            <option
              v-for="mailbox in getMailboxesForUserId(userId)"
              :key="mailbox.id"
              :value="mailbox">
              {{ mailbox.outboundEmail }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          v-if="sendMailData.onlyCurrentContact"
          class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.marketingMessages.mail.to') }}*</label>
          <coozzy-search-multiselect
            v-model="to"
            :check-valide="$v.to.$dirty && $v.to.$error ? 'is-invalid' : ''"
            :clear-on-select="false"
            :close-on-select="false"
            :custom-label="nameWithEmail"
            :is-email="true"
            :multiple="true"
            :options="toList"
            :placeholder="''"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="true"
            :with-address="false"
            label="email"
            track-by="id" />
        </div>
        <div
          v-else
          class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.marketingMessages.mail.to') }}*</label>
          <ais-instant-search
            :routing="routing"
            :search-client="searchClient"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :filters="filterQuery"
              :hitsPerPage="15" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <coozzy-search-multiselect
                  id="to-multiselect"
                  v-model="to"
                  :check-valide="$v.to.$dirty && $v.to.$error ? 'is-invalid' : ''"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :custom-label="nameWithEmail"
                  :email-with-name="true"
                  :internal-search="false"
                  :is-email="true"
                  :multiple="true"
                  :options="indicesToSuggestions(indices[0].hits).filter(obj => !bcc.map(obj => obj.email).includes(obj.email) && !cc.map(obj => obj.email).includes(obj.email))"
                  :placeholder="''"
                  :preselect-first="false"
                  :preserve-search="true"
                  :taggable="true"
                  :track-by="'email'"
                  :with-address="false"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
        <div
          v-if="showRedInfoBar"
          class="col-sm-12 col-md-12 mb-2">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="red-error-bar"
            v-html="$t('message.marketingMessages.mail.dedicatedErrorMessage')" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div
          v-if="!sendMailData.useBcc || sendMailData.toAllTo"
          class="col-sm-12 col-md-12 mb-2">
          <label>CC</label>
          <ais-instant-search
            :routing="routing"
            :search-client="searchClient"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :filters="filterQuery"
              :hitsPerPage="15" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <coozzy-search-multiselect
                  v-model="cc"
                  :check-valide="$v.cc.$dirty && $v.cc.$error ? 'is-invalid' : ''"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :custom-label="nameWithEmail"
                  :email-with-name="true"
                  :internal-search="false"
                  :is-email="true"
                  :multiple="true"
                  :options="indicesToSuggestions(indices[0].hits).filter(obj => !to.map(obj => obj.email).includes(obj.email) && !bcc.map(obj => obj.email).includes(obj.email))"
                  :placeholder="''"
                  :preselect-first="false"
                  :preserve-search="true"
                  :taggable="true"
                  :track-by="'email'"
                  :with-address="false"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
        <div
          v-if="(!sendMailData.useBcc || sendMailData.toAllTo) && !sendMailData.hasContext"
          class="col-sm-12 col-md-12 mb-2">
          <label>BCC</label>
          <ais-instant-search
            :routing="routing"
            :search-client="searchClient"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :filters="filterQuery"
              :hitsPerPage="15" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <coozzy-search-multiselect
                  v-model="bcc"
                  :check-valide="$v.bcc.$dirty && $v.bcc.$error ? 'is-invalid' : ''"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :custom-label="nameWithEmail"
                  :email-with-name="true"
                  :internal-search="false"
                  :is-email="true"
                  :multiple="true"
                  :options="indicesToSuggestions(indices[0].hits).filter(obj => !to.map(obj => obj.email).includes(obj.email) && !cc.map(obj => obj.email).includes(obj.email))"
                  :placeholder="''"
                  :preselect-first="false"
                  :preserve-search="true"
                  :taggable="true"
                  :track-by="'email'"
                  :with-address="false"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
          <a @click="changeBcc">
            {{ $t('message.marketingMessages.mail.removeBCC') }}
          </a>
        </div>
        <div
          v-else-if="!sendMailData.useBcc || sendMailData.toAllTo"
          class="col-sm-12 col-md-12 mb-2">
          <a @click="changeBcc">
            {{ $t('message.marketingMessages.mail.insertBCC') }}
          </a>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.settings.template.title') }}</label>
          <coozzy-form-select v-model="templateId">
            <option :value="null" />
            <option
              v-for="template in sortedTemplate"
              :key="template.id"
              :value="template">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <coozzy-form-input
            v-model="subject"
            :name="$t('message.marketingMessages.mail.subject') + '*'"
            :state="$v.subject.$dirty && $v.subject.$error ? false : null" />
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label for="description">{{ $t('message.marketingMessages.mail.body') }}</label>
          <coozzy-alert
            v-if="(renderWithObjectId === '' || contactsWithObject.length > 1) && (bodyHtml.search('#anrede#') !== -1 || subject.search('#anrede#') !== -1 || bodyHtml.search('#obj') !== -1 || subject.search('#obj') !== -1)"
            variant="blue">
            {{ $t('message.marketingMessages.mail.placeholderMessage') }}
          </coozzy-alert>
          <editor
            v-if="showEditor"
            :key="keyRefresh"
            ref="editor-tiny"
            v-model="bodyHtml"
            :api-key="editorApi"
            :init="{
              toolbar: 'undo redo | bold italic underline forecolor | fontsize | numlist bullist link table | fullscreen print',
              plugins: 'fullscreen lists code image table link',
              height: '370',
              menubar: '',
              language: currentLanguage,
              elementpath: false,
              invalid_styles: {
                '*': 'font-family'
              },
              automatic_uploads: true,
              license_key: 'gpl',
              content_style: contentStyle,
              font_formats: fontFormats,
              branding: false,
              remove_trailing_brs: false,
              browser_spellcheck: true,
              table_toolbar: '',
              font_size_formats: fontsizeFormats,
              convert_urls: false,
              paste_as_text: true,
              paste_remove_styles: true,
              paste_remove_spans: true
            }"
            @onInit="onEditorInit" />
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <section class="mt-2 mb-2">
            <div
              v-b-toggle="'collapse-placeholder'"
              class="collapse-title">
              <label class="cursor-pointer width-only-content">
                {{ $t('message.generic.placeholder') }}
              </label>
            </div>
            <b-collapse
              :id="'collapse-placeholder'"
              :visible="false"
              class="col">
              <p class="mb-0">
                <span>#anrede# <br></span>
                <span v-if="showAllPlacholders">
                  #obj.str# <br>
                  #obj.nr# <br>
                  #obj.plz# <br>
                  #obj.ort# <br>
                  #obj.zimmer# <br>
                  #obj.etage#
                </span>
              </p>
            </b-collapse>
          </section>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.calendar.event.attachement') }}</label>
          <coozzy-form-file-input
            id="file"
            ref="coozzy-file-input"
            :disabled="uploading"
            accept="text/plain, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
,application/vnd.ms-powerpoint,  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf,   image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx .pdf"
            multiple
            @change="addFile"
            @dragover.prevent />
          <div
            v-if="uploading"
            class="row">
            <div class="col text-center">
              <coozzy-spinner class="m-3" />
            </div>
          </div>
          <div
            v-else
            class="row">
            <div
              v-for="media in mediasList"
              :key="media.id"
              class="media-block col-12">
              <div class="file-block p-1 mt-2 mb-0">
                <a
                  :href="media.url"
                  target="_blank">
                  {{ media.filename }}
                </a>
                <coozzy-button
                  class="delete-icon"
                  design="transparent"
                  @click="deleteMedia(media.id)">
                  <coozzy-delete-icon />
                </coozzy-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          :disabled="loading"
          class="float-right ml-3"
          design="green"
          @click="checkAttachment(false)">
          {{ $t('message.marketingMessages.sendMail') }}
        </coozzy-button>
        <coozzy-button
          v-if="!sendMailData.secondaryTitle"
          :disabled="loading"
          class="float-right ml-3"
          design="green"
          @click="checkAttachment(true)">
          {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
        </coozzy-button>
        <div class="d-flex float-right confidential-checkbox">
          <coozzy-form-checkbox
            v-model="confidentialEmail"
            :disabled="!isMailboxMainUser"
            :initial="confidentialEmail"
            class="ml-3 mr-1 pr-0"
            name="confidentialEmail" />
          <span class="align-self-center">
            {{ $t('message.settings.confidentialEmails.confidential') }}
          </span>
        </div>
        <coozzy-button
          :disabled="loading"
          design="default"
          @click="discardChanges">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
    <list-contact-without-email-modal
      v-if="sendMailData.contactsWithoutEmail.length > 0"
      :all-contacts="allContacts"
      :contacts="sendMailData.contactsWithoutEmail" />
    <b-modal
      :id="'confirmationDiscardEmailModal'"
      ref="confirmationDiscardEmailModal"
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop
      size="md">
      <div class="col p-0">
        <p>{{ $t('message.marketingMessages.confirmModal.confirmationDiscardChanges') }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          class="mb-0 border"
          design="transparent"
          size="small"
          @click="$bvModal.hide('confirmationDiscardEmailModal')">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="closeAll()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
        <coozzy-button
          v-if="!sendMailData.secondaryTitle"
          class="float-right mb-0 mr-2"
          design="green"
          size="small"
          @click="checkAttachment(true)">
          {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import MessageApi from '@/misc/apis/MessageApi'
import ContactApi from '@/misc/apis/ContactApi'
import MediaApi from '@/misc/apis/MediaApi'
import { algolia } from '@/mixins/algolia'
import ListContactWithoutEmailModal from '@/contact/components/ListContactWithoutEmailModal'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { user } from '@/mixins/user'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import { responsivity } from '@/mixins/responsivity'
import Editor from '@tinymce/tinymce-vue'
import { media } from '@/mixins/media'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert.vue'
import { mail } from '@/mixins/mail'
import CoozzyMinimizeIcon from '@/framework/components/icons/CoozzyMinimizeIcon.vue'
import CoozzyFullScreenIcon from '@/framework/components/icons/CoozzyFullScreenIcon.vue'
import CoozzyMinusIcon from '@/framework/components/icons/CoozzyMinusIcon.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

function isValidEmail(value) {
  if (!value || value < 0) {
    return true
  }
  let status = false
  value.forEach(element => {
    if (!this.validateEmail(element.email) && !status) {
      status = true
    }
  })

  return !status
}

export default {
  name: 'SendMailModal',
  components: {
    CoozzyMinusIcon,
    CoozzyFullScreenIcon,
    CoozzyMinimizeIcon,
    CoozzyFormCheckbox,
    CoozzyAlert,
    ListContactWithoutEmailModal,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyFormFileInput,
    CoozzySearchMultiselect,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyFormInput,
    editor: Editor
  },
  mixins: [user, algolia, responsivity, media, mail],
  data() {
    return {
      minus: false,
      minimized: false,
      discradDraft: false,
      isDraft: false,
      templateId: null,
      bodyHtml: '',
      subject: '',
      from: null,
      to: [],
      attachment: [],
      loading: false,
      keyRefresh: 0,
      replyToMailId: '',
      medias: [],
      mediasList: [],
      isFirst: true,
      cc: [],
      bcc: [],
      uploading: false,
      lastMailboxId: null,
      hasDedicated: false,
      showRedInfoBar: false,
      isReplay: false,
      isForward: false,
      confirmDiscardShown: false,
      showEditor: false,
      allContacts: [],
      showAllPlacholders: false,
      confidentialEmail: false,
      totalFilesSize: 0,
      maxFilesSize: 25000000
    }
  },
  computed: {
    ...mapState('message', ['sendMailData', 'templates', 'confidentialMailDomain']),
    ...mapGetters('message', ['getMailboxesForUserId', 'getSignatureForUserId', 'getSentMails', 'getMailboxEntries', 'getShowAllPlaceholders']),
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Lato, sans-serif'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}` : defaultFontFamily
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily}; font-size: ${fontSize};} p {margin: 0}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family};` : defaultFont
      return `${userFont}`
    },
    signatureForCurrentUser() {
      let signa = ''
      if (this.getSignatureForUserId(this.userId, this.from?.signatureId) !== '') {
        signa = this.getSignatureForUserId(this.userId, this.from?.signatureId)
      } else if (this.getSignatureForUserId(this.from?.mainUserId, this.from?.signatureId)) {
        signa = this.getSignatureForUserId(this.from?.mainUserId, this.from?.signatureId)
      } else {
        signa = this.getSignatureForUserId(null, this.from?.signatureId)
      }
      return '<section id="signature" contenteditable="true">' + signa + '</section>'
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_EMAIL').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    filterQuery() {
      return 'isOwner:false AND active:true AND hasEmail:true AND isOnlyInterestedParty:false AND numericId >= 40000'
    },
    toList() {
      let contactPersons = []
      for (const contact of this.sendMailData.contacts) {
        if (contact.contactPersons && contact.contactPersons.length > 0) {
          contactPersons = contactPersons.concat(contact.contactPersons)
        }
      }

      return [...new Set(this.sendMailData.contacts.concat(contactPersons).filter(hit => {
        return hit.email !== ''
      }))]
    },
    renderWithObjectId() {
      return this.sendMailData.renderWithObjectId
    },
    contactsWithObject() {
      return this.sendMailData.contactsWithObject
    },
    editorApi() {
      return 'gpl'
    },
    isMailboxMainUser() {
      return this.from?.mainUserId === this.userId
    }
  },
  watch: {
    getSentMails: function () {
      if (this.lastMailboxId) {
        localStorage.setItem('lastMailboxId', this.lastMailboxId)
      }
    },
    getMailboxEntries: function (newVal) {
      if (newVal.length > 0 && newVal[0].mailboxes) {
        newVal[0].mailboxes.forEach(item => {
          newVal[0].mailboxes.map(x => x.signatureId).forEach(element => {
            this.loadSignatureForUserId({
              accountId: item.ownerId,
              userId: this.userId,
              signatureId: element
            })
          })
        })
      }
    },
    getShowAllPlaceholders: function (newVal) {
      this.showAllPlacholders = newVal
    },
    signatureForCurrentUser: function (newSignature) {
      const signature = this.bodyHtml.slice(this.bodyHtml.indexOf('<section id="signature" contenteditable="true">'), this.bodyHtml.indexOf('</section>') + 10)
      if (!this.sendMailData.isDraft) {
        this.bodyHtml = this.bodyHtml.replaceAll(signature, newSignature)
      }
    },
    from: {
      handler: function (val) {
        if (val?.outboundEmail) {
          // this.loadSignatureForUserId({ accountId: this.accountId, userId: this.userId, signatureId: val.signatureId })
          MessageApi.hasDomainDedicatedIp(val.outboundEmail)
            .then(async response => {
              this.hasDedicated = response.hasDedicated
              this.changeColorsToTags()
            }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
          })
        }
        this.checkConfidentialMail()
      }
    },
    to: {
      handler: function (value) {
        if (value.length > 0) {
          // if (this.templateId !== null) {
          //   this.renderTemplate()
          // }
          this.changeColorsToTags()
          this.checkConfidentialMail()
        }
      }
    },
    cc: {
      handler: function () {
        this.checkConfidentialMail()
      }
    },
    bcc: {
      handler: function () {
        this.checkConfidentialMail()
      }
    },
    templateId: function (val) {
      if (val !== null) {
        this.loadTemplate()
      } else {
        this.bodyHtml = '<div id="content"><br /></div>' + this.getSignatureForUserId(this.userId, this.from?.signatureId) +
          (this.sendMailData.emailToReply && this.sendMailData.emailToReply.bodyHtml ? '\n\n' + this.sendMailData.emailToReply.bodyHtml : '')
        this.subject = ''
        this.medias = []
        this.mediasList = []
      }
    }
  },
  mounted: function () {
    this.loadInstantSearch()
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-send-mail') {
        this.$store.dispatch('user/setSendMailModalOpen', true)
        this.$v.$reset()
        window.addEventListener('keydown', this.checkInput)
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
            this.hide()
          })
        }
        if (this.sendMailData?.isDraft) {
          this.isDraft = this.sendMailData.isDraft
        }
        if (this.sendMailData.attachments?.length > 0) {
          this.medias = this.sendMailData.attachments.map(media => media.id)
          this.mediasList = this.sendMailData.attachments
        }
        if (this.sendMailData.bodyHtml) {
          this.bodyHtml = this.sendMailData.bodyHtml
        }
        if (this.sendMailData?.attachmentMediaIds?.length > 0) {
          const promises = this.sendMailData.attachmentMediaIds.map(media => MediaApi.getMediaByID(media))

          Promise.all(promises)
            .then((response) => {
              this.mediasList = response.map(obj => obj.media)
              this.medias = response.map(obj => obj.media.id)
            })
        }
        if (this.sendMailData.bcc.length > 0) {
          this.sendMailData.hasContext = false
          this.bcc = this.sendMailData.bcc
        }
        this.prepareSenderAndReceiver()
        if (this.sendMailData.emailToReply) {
          this.isReplay = true
          this.isForward = false
          this.prepareMailWithReply()
        }
        this.allContacts = JSON.parse(JSON.stringify(this.sendMailData.preselectedContact))
        if (this.sendMailData.emailToForward) {
          this.isReplay = false
          this.isForward = true
          this.prepareMailToForward()
        }

        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          this.keyRefresh += 1
          this.showEditor = true
        })
        this.$nextTick(function () {
          // prevent Bootstrap from hijacking TinyMCE modal focus when clicking in input of link's modal (link feature)
          document.getElementById('modal-send-mail___BV_modal_content_')?.removeAttribute('tabindex')
        })
      }
      if (modalId === 'confirmationDiscardEmailModal') {
        this.confirmDiscardShown = true
      }

      setTimeout(() => {
        if (this.$refs['editor-tiny']) {
          this.updateEditorContentStyle()
        }
      }, 500)
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-send-mail') {
        this.$store.dispatch('user/setSendMailModalOpen', false)
        this.minus = false
        this.minimized = false
        this.isForward = false
        this.isReplay = false
        window.removeEventListener('keydown', this.checkInput)
        if (!this.discradDraft) {
          this.resetSendMailData(false)
        }
        this.replyToMailId = ''
        this.from = null
        this.to = []
        this.cc = []
        this.bcc = []
        this.subject = ''
        this.bodyHtml = ''
        this.medias = []
        this.mediasList = []
        this.attachment = []
        if (this.signatureForCurrentUser) {
          this.bodyHtml = '<div id="content"><br /></div>' + this.signatureForCurrentUser
        }
        this.templateId = null
        this.$nextTick(function () {
          this.showEditor = false
        })
        this.setShowAllPlaceholders(false)
      }
      if (modalId === 'listContactsWithoutEmailModal') {
        this.isForward = false
        this.isReplay = false
        this.resetSendMailData()
        this.replyToMailId = ''
        this.from = null
        this.to = []
        this.cc = []
        this.bcc = []
        this.subject = ''
        this.bodyHtml = ''
        this.medias = []
        this.mediasList = []
        this.attachment = []
        if (this.signatureForCurrentUser) {
          this.bodyHtml = '<div id="content"><br /></div>' + this.signatureForCurrentUser
        }
        this.templateId = null
      }
      if (modalId === 'confirmationDiscardEmailModal') {
        this.confirmDiscardShown = false
      }
    })

    this.loadTemplateList(this.accountId)
    this.loadConfidentialMailDomain(this.accountId)
    this.loadSignatureForUserId({
      accountId: this.accountId,
      userId: this.userId,
      signature: this.from?.signature
    })
    if (this.signatureForCurrentUser && !this.sendMailData?.isDraft) {
      this.bodyHtml = '<div id="content"><br /></div>' + this.signatureForCurrentUser
    }
  },
  methods: {
    ...mapMutations('message', ['resetSendMailData', 'addSentMail', 'setShowAllPlaceholders']),
    ...mapActions('message', ['loadTemplateList', 'loadMailboxesForUserId', 'loadSignatureForUserId', 'loadConfidentialMailDomain']),
    onEditorInit() {
      if (this.$refs['editor-tiny']) {
        const editor = this.$refs['editor-tiny'].editor
        this.$nextTick(() => {
          if (editor) {
            editor.focus()
          }
        })
      }
    },
    updateEditorContentStyle() {
      if (this.$refs['editor-tiny']) {
        const editor = this.$refs['editor-tiny'].editor
        if (editor.getBody()) {
          editor.getBody().style.fontFamily = this.fontSetting.family || 'Lato'
          editor.getBody().style.fontSize = (this.fontSetting.size || 10) + 'pt'
        }
      }
    },
    checkConfidentialMail() {
      if (this.sendMailData?.emailToReply?.confidential) {
        this.confidentialEmail = true
        return
      }
      if (this.confidentialMailDomain.length > 0) {
        let isConfidential = false
        if (this.from) {
          isConfidential = this.from.confidential
        }
        this.confidentialMailDomain.forEach((element) => {
          if (isConfidential) {
            return
          }
          isConfidential = this.to.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element))) || this.cc.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element))) || this.bcc.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element)))
        })
        this.confidentialEmail = isConfidential
      }
    },
    discardChanges(e) {
      if (this.isDraft) {
        this.checkAttachment(true)
      } else {
        e.preventDefault()
        this.$refs.confirmationDiscardEmailModal?.show()
      }
    },
    checkInput(event) {
      if (event.key === 'Escape') {
        if (this.confirmDiscardShown === true) {
          this.$refs.confirmationDiscardEmailModal?.hide()
        } else {
          this.discardChanges(event)
        }
      }
    },
    closeAll() {
      this.$bvModal.hide('confirmationDiscardEmailModal')
      this.$v.$reset()
      this.$bvModal.hide('modal-send-mail')
    },
    nameWithEmail(item) {
      return item.name + ' ' + '<' + item.email + '>'
    },
    changeBcc() {
      this.sendMailData.hasContext = !this.sendMailData.hasContext
    },
    prepareSenderAndReceiver() {
      if (this.getMailboxesForUserId(this.userId).length > 0) {
        // Find main mailbox for current user
        const mailboxes = this.getMailboxesForUserId(this.userId)
        const lastMailboxId = localStorage.getItem('lastMailboxId')
        if (mailboxes.find(mailbox => mailbox.mainUserId === this.userId)) {
          this.from = mailboxes.find(mailbox => mailbox.mainUserId === this.userId) || null
        } else {
          const storedMailbox = mailboxes.find(mailbox => mailbox.id === lastMailboxId)
          this.from = (storedMailbox && storedMailbox.active) ? storedMailbox : null
        }
        let contactsToReply = []

        // If we reply we need to use the former mailbox as FROM and use the counterpart as TO
        if (this.sendMailData.emailToReply) {
          const originalFromEmail = this.sendMailData.emailToReply.from.email
          const originalToEmails = this.sendMailData.emailToReply.to.map(to => to.email)
          const isFrom = mailboxes.find(mailbox => mailbox.mainUserId === this.userId) || mailboxes.find(mailbox => mailbox.outboundEmail === originalFromEmail)
          const isTo = mailboxes.find(mailbox => originalToEmails.includes(mailbox.outboundEmail))

          if (isFrom) {
            this.from = isFrom
            contactsToReply = this.sendMailData.emailToReply.to
          } else if (isTo) {
            this.from = isTo
            contactsToReply = [this.sendMailData.emailToReply.from]
          }
        }

        // Either use the contacts to reply, the externally defined preselectedContact or the very first contact option
        if (this.sendMailData?.emailToReply?.replyTo) {
          this.to = [{
            name: '',
            email: this.sendMailData.emailToReply.replyTo
          }]
        } else if (this.sendMailData.contacts.length > 0) {
          const list = []
          this.sendMailData.contacts.forEach(element => {
            const obj1 = JSON.parse(JSON.stringify(element))
            list.push(obj1)
            const obj2 = JSON.parse(JSON.stringify(element))
            if (obj2.workEmail && obj2.workEmail.use === true && obj2.workEmail.email !== '') {
              obj2.email = obj2.workEmail.email
              list.push(obj2)
            }
          })
          this.to = list
        } else if (contactsToReply.length > 0) {
          this.to = contactsToReply
        } else if (this.sendMailData.preselectedContact) {
          if (Array.isArray(this.sendMailData.preselectedContact) && this.sendMailData.preselectedContact.length > 1) {
            this.sendMailData.preselectedContact.forEach(element => {
              if (element.email !== '') {
                this.to.push(element)
              }
            })
          } else {
            if (Array.isArray(this.sendMailData.preselectedContact)) {
              this.sendMailData.preselectedContact.forEach(element => {
                if (element.email !== '') {
                  this.to.push(element)
                }
              })
            } else {
              if (this.sendMailData.preselectedContact.email !== '') {
                this.to.push(this.sendMailData.preselectedContact)
              }
            }
          }
        }
        if (this.sendMailData.cc.length > 0) {
          this.cc = this.sendMailData.cc
        }
        if (this.sendMailData.subject !== null) {
          this.subject = this.sendMailData.subject
        }
        if (this.sendMailData.additionalText) {
          this.bodyHtml = '<div id="content"><br />' + this.sendMailData.additionalText + '</div>' + this.signatureForCurrentUser
        }
        if (this.sendMailData && this.sendMailData.emailToReply && this.sendMailData.emailToReply.isForwarding) {
          this.to = []
        }
        // Reset the changed modal state after initial setup
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      }
    },
    getWeekday(date) {
      const day = new Date(date * 1000)
      let locale = 'en-EN'
      switch (this.$store.getters['internationalization/getCurrentLanguage']) {
        case 'de':
          locale = 'de-DE'
          break
        case 'it':
          locale = 'it-IT'
          break
      }
      return day.toLocaleDateString(locale, { weekday: 'long' })
    },
    prepareMailWithReply() {
      // Fill out subject and message fields if we reply to a mail and set the referenceId
      if (this.sendMailData.emailToReply) {
        let to = ''
        let cc = ''
        this.sendMailData.emailToReply?.to.forEach(element => {
          to = to + `${element.name}&lt;${element.email}&gt; `
        })
        if (this.sendMailData.emailToReply?.cc.length > 0) {
          this.sendMailData.emailToReply?.cc.forEach(element => {
            cc = cc + `${element.name}&lt;${element.email}&gt; `
          })
          cc = `<br><b>CC</b>: ${cc}`
        }
        this.subject = 'Re: ' + this.sendMailData.emailToReply?.subject
        this.bodyHtml = `<div id="content"><br /></div><section id="signature" contenteditable="true">${this.getSignatureForUserId(this.userId, this.from?.signatureId)}</section>\n\n<br>` +
          `<br><b>${this.$t('message.marketingMessages.mail.from')}</b>: ${this.sendMailData.emailToReply?.from.name}&lt;${this.sendMailData.emailToReply?.from.email}&gt;` +
          `<br><b>${this.$t('message.marketingMessages.mail.sent')}</b>: ${this.getWeekday(this.sendMailData.emailToReply?.timestamp)}, ${this.$options.filters.timestampToDateTime(this.sendMailData.emailToReply?.timestamp)}` +
          `<br><b>${this.$t('message.marketingMessages.mail.to')}</b>: ${to}` + cc +
          `<br><b>${this.$t('message.marketingMessages.mail.subject')}</b>: ${this.sendMailData.emailToReply?.subject}\n<br>${this.sendMailData.emailToReply.bodyHtml}`
        if (this.sendMailData.emailToReply.objectID) {
          this.replyToMailId = this.sendMailData.emailToReply.objectID
        } else if (this.sendMailData.emailToReply.id) {
          this.replyToMailId = this.sendMailData.emailToReply.id
        }
        if (this.sendMailData.emailToReply.attachmentMediaIds && this.sendMailData.emailToReply.attachmentMediaIds.length > 0 && this.sendMailData.emailToReply.hasForwarding) {
          const promises = this.sendMailData.emailToReply.attachmentMediaIds.map(media => MediaApi.getMediaByID(media))

          Promise.all(promises)
            .then((response) => {
              this.mediasList = response.map(obj => obj.media)
              this.medias = response.map(obj => obj.media.id)
            })
        }
      }
    },
    prepareMailToForward() {
      // Fill out subject and message fields if we reply to a mail and set the referenceId
      if (this.sendMailData.emailToForward) {
        let to = ''
        let cc = ''
        this.sendMailData.emailToForward?.to.forEach(element => {
          to = to + `${element.name}&lt;${element.email}&gt; `
        })
        if (this.sendMailData.emailToForward?.cc.length > 0) {
          this.sendMailData.emailToForward?.cc.forEach(element => {
            cc = cc + `${element.name}&lt;${element.email}&gt; `
          })
          cc = `<br><b>CC</b>: ${cc}`
        }
        this.subject = 'Fwd: ' + this.sendMailData.emailToForward?.subject
        this.bodyHtml = `<div id="content"><br /></div><section id="signature" contenteditable="true">${this.getSignatureForUserId(this.userId, this.from?.signatureId)}</section>\n\n<br>` +
          `<br><b>${this.$t('message.marketingMessages.mail.from')}</b>: ${this.sendMailData.emailToForward?.from.name}&lt;${this.sendMailData.emailToForward?.from.email}&gt;` +
          `<br><b>${this.$t('message.marketingMessages.mail.sent')}</b>: ${this.getWeekday(this.sendMailData.emailToForward?.timestamp)}, ${this.$options.filters.timestampToDateTime(this.sendMailData.emailToForward?.timestamp)}` +
          `<br><b>${this.$t('message.marketingMessages.mail.to')}</b>: ${to}` + cc +
          `<br><b>${this.$t('message.marketingMessages.mail.subject')}</b>: ${this.sendMailData.emailToForward?.subject}\n<br>${this.sendMailData.emailToForward.bodyHtml}`
        if (this.sendMailData.emailToForward.objectID) {
          this.replyToMailId = this.sendMailData.emailToForward.objectID
        } else if (this.sendMailData.emailToForward.id) {
          this.replyToMailId = this.sendMailData.emailToForward.id
        }
        if (this.sendMailData.emailToForward.attachmentMediaIds?.length && this.sendMailData.emailToForward.hasForwarding) {
          const promises = this.sendMailData.emailToForward.attachmentMediaIds.map(media => MediaApi.getMediaByID(media))

          Promise.all(promises)
            .then((response) => {
              this.mediasList = response.map(obj => obj.media)
              this.medias = response.map(obj => obj.media.id)
            })
        }
      }
    },
    hide() {
      setTimeout(() => {
        this.$refs['modal-send-mail'].hide()
      }, 100)
    },
    loadTemplate(objectId) {
      let contactId = ''
      if (this.to && this.to.length > 0) {
        // Find contact to render the template with
        contactId = this.to[0].id || this.to[0].objectID
        if (!contactId) {
          const contact = this.sendMailData.contacts.find(c => c.email === this.to[0].email)
          if (contact) {
            contactId = contact.id || contact.objectID
          }
        }
      }
      let currentObjectId = ''
      if (this.to?.length === 1 && this.contactsWithObject.length > 0) {
        currentObjectId = this.contactsWithObject.find(c => c.contacts.some(contactId => contactId === this.to[0].id || contactId === this.to[0].objectID))?.objectId
      } else {
        currentObjectId = objectId === '' && this.renderWithObjectId !== '' && this.contactsWithObject.length === 1 ? this.renderWithObjectId : this.renderWithObjectId !== '' ? this.renderWithObjectId : objectId !== '' ? objectId : ''
      }
      MessageApi.renderTemplate(this.templateId.id, this.to.length === 1 && contactId !== '' ? contactId : '', currentObjectId)
        .then(response => {
          this.bodyHtml = '<div id="content">' + response.text + '<br /></div>' + (this.sendMailData.additionalText ? `\n\n${this.sendMailData.additionalText}\n\n` : '\n\n') + '<br><br>' + this.getSignatureForUserId(this.userId, this.from?.signatureId) + (this.sendMailData.emailToReply ? `\n\n${this.$t('message.marketingMessages.history')}\n${this.sendMailData.emailToReply.bodyHtml}` : '')
          this.subject = response.subject
          if (response.attachmentMediaIds.length > 0) {
            const promises = response.attachmentMediaIds.map(mediaId => MediaApi.getMediaByID(mediaId))

            Promise.all(promises)
              .then((response) => {
                this.mediasList = response.map(obj => obj.media)
                this.medias = response.map(obj => obj.media.id)
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
              })
          }
          // if (this.to.length > 0) {
          //   this.renderTemplate(objectId)
          // }
        })
    },
    // renderTemplate(objectId = '') {
    //   let contactId = ''
    //   if (this.to && this.to.length > 0) {
    //     // Find contact to render the template with
    //     contactId = this.to[0].id || this.to[0].objectID
    //     if (!contactId) {
    //       const contact = this.sendMailData.contacts.find(c => c.email === this.to[0].email)
    //       if (contact) {
    //         contactId = contact.id || contact.objectID
    //       }
    //     }
    //   }
    //
    //   // Reset media
    //   this.mediasList = []
    //   this.medias = []
    //   const currentObjectId = objectId === '' && this.renderWithObjectId !== '' && this.contactsWithObject.length === 1 ? this.renderWithObjectId : this.renderWithObjectId !== '' ? this.renderWithObjectId : objectId !== '' ? objectId : ''
    //
    //   MessageApi.renderText(this.to.length === 1 && contactId !== '' ? contactId : '', currentObjectId, this.bodyHtml, this.subject)
    //     .then(response => {
    //       this.bodyHtml = response.text
    //
    //       if (!this.sendMailData.emailToReply) {
    //         this.subject = response.title
    //       }
    //
    //       const template = this.templates.find(element => element.id === this.templateId.id)
    //       const promises = template.attachmentMediaIds.map(mediaId => MediaApi.getMediaByID(mediaId))
    //
    //       Promise.all(promises)
    //         .then((response) => {
    //           this.mediasList = response.map(obj => obj.media)
    //           this.medias = response.map(obj => obj.media.id)
    //         })
    //         .catch(e => {
    //           console.log(e)
    //           Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
    //         })
    //     })
    //     .catch(e => {
    //       console.log(e)
    //       Vue.toasted.show(this.$t('message.loadingErrors.renderTemplate'), { type: 'error' })
    //     })
    // },
    async applyCSSToAllElements() {
      const fontSize = this.fontSetting ? this.fontSetting.size + 'pt' : '11pt'
      // Create a temporary DOM element to manipulate the HTML content
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = this.bodyHtml

      // Find the #content div
      const contentDiv = tempDiv.querySelector('#content')

      if (contentDiv) {
        // Use querySelectorAll to select all elements within the div
        contentDiv.querySelectorAll('*').forEach((element) => {
          // Skip <br> elements
          if (element.tagName.toLowerCase() === 'br') return
          // Check if the element already has a style attribute
          if (element.hasAttribute('style')) {
            // Append style to the existing style attribute
            element.setAttribute('style', `${element.getAttribute('style')} font-size: ${fontSize}; margin: 0;`)
          } else {
            // Add style attribute if it doesn't exist
            element.setAttribute('style', `font-size: ${fontSize}; margin: 0;`)
          }
        })
      }
      this.bodyHtml = tempDiv.innerHTML
    },
    async sendMail(asDraft = false) {
      this.lastMailboxId = this.from.id
      if (asDraft) {
        MessageApi.saveDraft(
          this.convertToPlainWithBreakingLines(this.bodyHtml),
          this.bodyHtml,
          {
            email: this.from.outboundEmail,
            name: this.from.name
          },
          this.subject,
          this.to.map((to) => {
            return {
              email: to.email,
              name: to.name
            }
          }),
          this.isDraft ? this.sendMailData.id : null,
          this.to.filter(to => to.id || to.objectID).map(to => to.id || to.objectID)
            .concat(this.cc.filter(cc => cc.id || cc.objectID).map(cc => cc.id || cc.objectID))
            .concat(this.sendMailData.referenceIds)
            .concat(this.sendMailData.emailToReply ? this.sendMailData.emailToReply.referenceIds.filter(e => !e.startsWith('contact_')) : [])
            .concat(this.from.contactId),
          this.medias,
          this.from.id,
          new Date().getTime(),
          this.cc.map((cc) => {
            return {
              email: cc.email,
              name: cc.name
            }
          }),
          this.bcc.map((bcc) => {
            return {
              email: bcc.email,
              name: bcc.name
            }
          })
        ).then(response => {
          Vue.toasted.show(this.$t('message.successMessages.mailSavedDraft'), { type: 'success' })
          this.$v.$reset()
          this.addSentMail(response.mail)
          this.hide()
          this.closeAll()
          this.$parent.$refs['coozzy-header'].countDraftMails(true)
          if (this.sendMailData.contactsWithoutEmail.length > 0) {
            setTimeout(() => {
              this.$bvModal.show('listContactsWithoutEmailModal')
            }, 200)
          }
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.mailSavedDraft'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      } else {
        await this.applyCSSToAllElements()
        if (!this.sendMailData.toAllTo && (this.sendMailData.useBcc || this.bodyHtml.search('#anrede#') !== -1 || this.subject.search('#anrede#') !== -1 || this.bodyHtml.search('#obj') !== -1 || this.subject.search('#obj') !== -1)) {
          let textEntries = []
          const newTo = this.getUniqueListBy(this.to, 'email')
          for (let index = 0; index < newTo.length; index++) {
            // Find contact to render the template with
            let contactId = ''
            contactId = newTo[index].id || newTo[index].objectID
            if (!contactId) {
              const contact = this.sendMailData.contacts.find(c => c.email === newTo[0].email)
              if (contact) {
                contactId = contact.id || contact.objectID
              }
            }
            if (this.contactsWithObject.length > 0) {
              let entry = []
              for (let j = 0; j < this.contactsWithObject.length; j++) {
                if (this.contactsWithObject[j].contacts.find(x => x === newTo[index].id)) {
                  entry.push({
                    contactId: newTo[index].id,
                    objectId: this.contactsWithObject[j].objectId
                  })
                }
              }
              if (entry.length > 0) {
                textEntries = textEntries.concat(entry)
              } else {
                textEntries.push({
                  contactId: newTo[index].id || newTo[index].objectID,
                  objectId: this.sendMailData.renderWithObjectId || ''
                })
              }
            } else {
              textEntries.push({
                contactId: newTo[index].id || newTo[index].objectID,
                objectId: this.sendMailData.renderWithObjectId || ''
              })
            }
          }
          const renderTextResponse = await MessageApi.bulkRenderText(textEntries, this.bodyHtml, this.subject)
          const fromContact = await ContactApi.resolveByMail(this.accountId, [this.from.outboundEmail])
          if ((fromContact.companies.concat(fromContact.persons)).length === 1) {
            this.from.contactId = (fromContact.companies.concat(fromContact.persons))[0]?.id
          }
          const entries = []
          for (let index = 0; index < newTo.length; index++) {
            let el = []
            if (newTo[index].id || newTo[index].objectID) {
              el = renderTextResponse.entries.filter(x => x.contactId === newTo[index].id || x.contactId === newTo[index].objectID)
            } else {
              el = renderTextResponse.entries.filter(x => x.contactId === '')
            }
            let referenceIds = []
            if (this.sendMailData.bccReferenceIdsByContacts) {
              referenceIds = this.sendMailData.bccReferenceIdsByContacts[newTo[index].id || newTo[index].objectID] || []
            } else {
              referenceIds = (this.sendMailData.bccReferenceIdsByContacts?.length > 0 ? this.sendMailData.bccReferenceIdsByContacts[newTo[index].id || newTo[index].objectID] : []).concat(this.sendMailData.referenceIds)
            }
            let text = ''
            if (el.length > 0) {
              el.forEach(element => {
                text = text + element.text + '<br>'
              })
            }
            entries.push({
              bodyText: this.convertToPlainWithBreakingLines(text || this.bodyHtml),
              bodyHtml: text || this.bodyHtml,
              from: {
                email: this.from.outboundEmail,
                name: this.from.name
              },
              subject: el?.length > 0 ? el[0]?.title : this.subject,
              to: [{
                email: newTo[index].email,
                name: newTo[index].name
              }],
              ownerId: this.accountId,
              referenceIds: ([newTo[index].id || newTo[index].objectID].concat(referenceIds).concat(this.cc.map((cc) => cc.id || cc.objectID)).concat(this.bcc.map((bcc) => bcc.id || bcc.objectID)).concat(this.from.contactId)).filter(x => x),
              attachmentMediaIds: this.medias,
              replyToMailId: this.replyToMailId,
              cc: this.cc.map((cc) => {
                return {
                  email: cc.email,
                  name: cc.name
                }
              }),
              bcc: this.bcc.map((bcc) => {
                return {
                  email: bcc.email,
                  name: bcc.name
                }
              }),
              confidential: this.confidentialEmail
            })
          }
          const promises = []
          // Use 10 (20 if over 100 recipients) bulk send requests in parallel if needed and split up mails evenly on all requests
          const loopVar = Math.max(1, Math.trunc(entries.length / (entries.length > 100 ? 20 : 10)))
          let start = 0
          let end = 0
          for (let index = 0; index < parseInt((entries.length / loopVar) + 1); index++) {
            start = end
            end = Math.min(entries.length, start + loopVar)
            promises.push(MessageApi.bulkSendMail(entries.slice(start, end)))
          }
          Promise.all(promises)
            .then(async (mails) => {
              if (this.sendMailData.isDraft) {
                await this.deleteDrafts([this.sendMailData.id])
              }
              Vue.toasted.show(this.$t('message.successMessages.mailSend'), { type: 'success' })
              this.$v.$reset()
              const mailsList = [...new Set([].concat(...mails.map((o) => o.mail)))]
              mailsList.forEach(element => {
                this.addSentMail(element.mail)
              })
              this.hide()
              if (this.sendMailData.contactsWithoutEmail.length > 0) {
                setTimeout(() => {
                  this.$bvModal.show('listContactsWithoutEmailModal')
                }, 200)
              }
            }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
          }).finally(() => {
            this.loading = false
          })
        } else {
          const fromContact = await ContactApi.resolveByMail(this.accountId, [this.from.outboundEmail])
          if ((fromContact.companies.concat(fromContact.persons)).length === 1) {
            this.from.contactId = (fromContact.companies.concat(fromContact.persons))[0]?.id
          }
          MessageApi.sendMail(
            this.convertToPlainWithBreakingLines(this.bodyHtml),
            this.bodyHtml,
            {
              email: this.from.outboundEmail,
              name: this.from.name
            },
            this.subject,
            this.getUniqueListBy(this.to, 'email').map((to) => {
              return {
                email: to.email,
                name: to.name
              }
            }),
            this.getUniqueListBy(this.to, 'email').filter(to => to.id || to.objectID).map(to => to.id || to.objectID)
              .concat(this.cc.filter(cc => cc.id || cc.objectID).map(cc => cc.id || cc.objectID))
              .concat(this.sendMailData.referenceIds)
              .concat(this.sendMailData.emailToReply ? this.sendMailData.emailToReply.referenceIds.filter(e => !e.startsWith('contact_')) : [])
              .concat(this.from.contactId),
            this.medias,
            this.accountId,
            this.replyToMailId,
            this.cc.map((cc) => {
              return {
                email: cc.email,
                name: cc.name
              }
            }),
            this.bcc.map((bcc) => {
              return {
                email: bcc.email,
                name: bcc.name
              }
            }),
            this.confidentialEmail
          ).then(async (response) => {
            if (this.sendMailData.isDraft) {
              await this.deleteDrafts([this.sendMailData.id])
            }
            Vue.toasted.show(this.$t('message.successMessages.mailSend'), { type: 'success' })
            this.$v.$reset()
            this.addSentMail(response.mail)
            this.hide()
            if (this.sendMailData.contactsWithoutEmail.length > 0) {
              setTimeout(() => {
                this.$bvModal.show('listContactsWithoutEmailModal')
              }, 200)
            }
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
          }).finally(() => {
            this.loading = false
          })
        }
      }
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    async forwardMail() {
      await this.applyCSSToAllElements()
      this.lastMailboxId = this.from.id
      if (this.sendMailData.useBcc || this.bodyHtml.search('#anrede#') !== -1 || this.subject.search('#anrede#') !== -1 || this.bodyHtml.search('#obj') !== -1 || this.subject.search('#obj') !== -1) {
        const promises = []
        for (let index = 0; index < this.to.length; index++) {
          // Find contact to render the template with
          let contactId = ''
          contactId = this.to[index].id || this.to[index].objectID
          if (!contactId) {
            const contact = this.sendMailData.contacts.find(c => c.email === this.to[0].email)
            if (contact) {
              contactId = contact.id || contact.objectID
            }
          }

          let bodyHtmlUpdated = this.bodyHtml
          let titleUpdated = this.subject
          if (this.contactsWithObject.length > 1) {
            for (let j = 0; j < this.contactsWithObject.length; j++) {
              if (this.contactsWithObject[j].contacts.find(x => x === this.to[index].id)) {
                const renderTextResponse = await MessageApi.renderText(this.to[index].id, this.contactsWithObject[j].objectId, this.bodyHtml, this.subject)
                bodyHtmlUpdated = renderTextResponse.text
                titleUpdated = renderTextResponse.title
              }
            }
          } else {
            const renderTextResponse = await MessageApi.renderText(this.to[index].id || this.to[index].objectID, '', this.bodyHtml, this.subject)
            bodyHtmlUpdated = renderTextResponse.text
            titleUpdated = renderTextResponse.title
          }
          promises.push(MessageApi.forwardMail(
            this.convertToPlainWithBreakingLines(bodyHtmlUpdated),
            bodyHtmlUpdated,
            {
              email: this.from.outboundEmail,
              name: this.from.name
            },
            titleUpdated,
            [{
              email: this.to[index].workEmail && this.to[index].workEmail.use === true && this.to[index].workEmail.email ? this.to[index].workEmail.email : this.to[index].email,
              name: this.to[index].name
            }],
            [this.to[index].id || this.to[index].objectID].concat(this.sendMailData.bccReferenceIdsByContacts[this.to[index].id || this.to[index].objectID]).concat(this.cc.map((cc) => cc.objectID)).concat(this.bcc.map((bcc) => bcc.objectID)),
            this.medias,
            this.accountId,
            this.replyToMailId,
            this.cc.map((cc) => {
              return {
                email: cc.email,
                name: cc.name
              }
            }),
            this.bcc.map((bcc) => {
              return {
                email: bcc.email,
                name: bcc.name
              }
            }),
            this.confidentialEmail
          ))
        }
        Promise.all(promises)
          .then(mails => {
            Vue.toasted.show(this.$t('message.successMessages.mailSend'), { type: 'success' })
            this.$v.$reset()
            mails.forEach(element => {
              this.addSentMail(element.mail)
            })
            this.hide()
            if (this.sendMailData.contactsWithoutEmail.length > 0) {
              setTimeout(() => {
                this.$bvModal.show('listContactsWithoutEmailModal')
              }, 200)
            }
          }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      } else {
        MessageApi.forwardMail(
          this.convertToPlainWithBreakingLines(this.bodyHtml),
          this.bodyHtml,
          {
            email: this.from.outboundEmail,
            name: this.from.name
          },
          this.subject,
          this.to.map((to) => {
            return {
              email: to.workEmail && to.workEmail.use === true && to.workEmail.email ? to.workEmail.email : to.email,
              name: to.name
            }
          }),
          this.to.filter(to => to.id).map(to => to.id)
            .concat(this.to.filter(to => to.objectID).map(to => to.objectID))
            .concat(this.sendMailData.referenceIds)
            .concat(this.sendMailData.emailToReply ? this.sendMailData.emailToReply.referenceIds : []),
          this.medias,
          this.accountId,
          this.replyToMailId,
          this.cc.map((cc) => {
            return {
              email: cc.email,
              name: cc.name
            }
          }),
          this.bcc.map((bcc) => {
            return {
              email: bcc.email,
              name: bcc.name
            }
          }),
          this.confidentialEmail
        ).then(response => {
          Vue.toasted.show(this.$t('message.successMessages.mailSend'), { type: 'success' })
          this.$v.$reset()
          this.addSentMail(response.mail)
          this.hide()
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.mailSend'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    async deleteDrafts(ids) {
      try {
        return await MessageApi.deleteDrafts(ids)
      } catch (e) {
        console.log(e)
      }
    },
    indicesToSuggestions(hits) {
      const contactPersons = [].concat.apply([], hits.map(x => x.contactPersons ? x.contactPersons.filter(y => y.email) : []))
      const list = hits.filter(x => x.email).concat(contactPersons.length > 0 ? contactPersons : [])
      list.forEach(element => {
        const obj2 = JSON.parse(JSON.stringify(element))
        if (obj2.workEmail && obj2.workEmail.email !== '') {
          obj2.email = obj2.workEmail.email
          list.push(obj2)
        }
      })
      return list
    },
    async sendFile() {
      this.uploading = true
      const response = await this.uploadMedia(this.attachment)
      if (response.length > 0) {
        for (const media of response) {
          this.mediasList.push(media)
          this.medias.push(media.id)
        }

        this.$nextTick(() => {
          this.attachment = []
          this.uploading = false
        })
      }
      this.$nextTick(() => {
        this.uploading = false
        if (this.$refs['coozzy-file-input']) {
          this.$refs['coozzy-file-input'].resetPlaceholder()
        }
      })
    },
    checkAttachment(asDraft = false) {
      if (asDraft) {
        this.sendMail(asDraft)
      } else {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.loading = true
          if (this.isForward === true && this.isReplay === false) {
            this.forwardMail()
          } else {
            this.sendMail(asDraft)
          }
        }
      }
    },
    see(refine, value) {
      refine(value)
    },
    deleteMedia(id) {
      this.medias = this.medias.filter(e => e !== id)
      this.mediasList = this.mediasList.filter(el => el.id !== id)
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    changeMinimized() {
      this.minus = false
      this.minimized = !this.minimized
      if (this.minimized || this.minus) {
        document.getElementsByClassName('body')[0].style.overflow = 'auto'
      } else {
        document.getElementsByClassName('body')[0].style.overflow = 'hidden'
      }
    },
    minusEvent() {
      this.minus = !this.minus
      if (this.minimized || this.minus) {
        document.getElementsByClassName('body')[0].style.overflow = 'auto'
      } else {
        document.getElementsByClassName('body')[0].style.overflow = 'hidden'
      }
    },
    async addFile(event) {
      let fileLimitExceeded = false
      let listFilesLimitExceeded = []
      if (event && event.dataTransfer && event.dataTransfer.getData('itemId')) {
        MediaApi.getMediaByID(event.dataTransfer.getData('itemId'))
          .then(response => {
            const media = response.media
            const newTotalFilesSize = this.totalFilesSize + media.size
            if (media.size > this.maxFilesSize || newTotalFilesSize > this.maxFilesSize) {
              fileLimitExceeded = true
            }
            // this.attachment.push(response.media)
            if (!fileLimitExceeded) {
              this.mediasList.push(media)
              this.medias.push(media.id)
              this.totalFilesSize += media.size
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      } else {
        const files = await this.getFilesFromEvent(event)
        if (files.length > 0) {
          for (let index = 0; index < files.length; index++) {
            const newTotalFilesSize = this.totalFilesSize + files[index].size
            if (files[index].size > this.maxFilesSize || newTotalFilesSize > this.maxFilesSize) {
              listFilesLimitExceeded.push(index)
            }
            if (listFilesLimitExceeded.filter(x => x === index).length === 0) {
              const element = files[index]
              this.attachment.push(element)
              this.totalFilesSize += files[index].size
            }
          }
        }
      }
      if (this.attachment.length > 0) {
        await this.sendFile()
      }
      if (fileLimitExceeded || listFilesLimitExceeded.length > 0) {
        Vue.toasted.show(this.$tc('message.uploadingErrors.maxTotalFilesSize25MB'), { type: 'error' })
      }
      // nextTick is not working here, so we need to use setTimeout
      setTimeout(() => {
        if (this.$refs['coozzy-file-input']) {
          this.$refs['coozzy-file-input'].resetPlaceholder()
        }
      }, 100)
    },
    changeColorsToTags() {
      setTimeout(() => {
        this.showRedInfoBar = false
        if (!this.hasDedicated) {
          const current = this
          let tagElements = []
          if (document.getElementById('to-multiselect')) {
            tagElements = document.getElementById('to-multiselect').querySelectorAll('.multiselect__tag')
          }
          if (tagElements) {
            tagElements.forEach(function (element) {
              const domainPart = element.firstChild.innerHTML.split('@')[1]
              if (domainPart === 't-online.de' || domainPart === 't-monline.de') {
                element.classList.add('red-tag')
                current.showRedInfoBar = true
              }
            })
          }
        } else {
          const redTagElements = document.getElementsByClassName('red-tag') || []
          if (redTagElements.length > 0) {
            redTagElements.forEach(function (element) {
              element.classList.remove('red-tag')
            })
          }
        }
      }, 100)
    }
  },
  validations: {
    subject: {
      required
    },
    from: {
      required
    },
    to: {
      required
    },
    cc: {
      isValidEmail
    },
    bcc: {
      isValidEmail
    }
  }
}
</script>
<style lang="scss" scoped>
.media-block {
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    border-style: solid;
    border-bottom-color: rgb(206, 212, 218);
    border-width: 0 0 1px 0;

    a {
      overflow-wrap: break-word;
    }
  }

  .delete-icon {
    position: absolute;
    right: 1rem;
  }

  .delete-icon :deep(svg) {
    vertical-align: text-top;
  }
}

:deep(#modal-send-mail:not(.minimized-block) .modal-dialog) {
  max-width: 1200px !important;
}

:deep(.multiselect .multiselect__tags) {
  font-size: .76562rem;
}

:deep(.multiselect__tags .multiselect__single) {
  font-size: .76562rem;
}

:deep(.multiselect .multiselect__input) {
  font-size: .76562rem;
  top: -1px;
}

.secondary-title {
  display: inline;
  font-size: 0.8rem;
  color: initial;
  font-weight: normal;
}

:deep(.red-tag) {
  background-color: $color-red;

  .multiselect__tag-icon:hover {
    background-color: $color-red;;
  }
}

.red-error-bar {
  color: white;
  background: $color-red;
  border-radius: 5px;
  padding: 5px;
}

.width-only-content {
  padding-right: 2rem;
  vertical-align: middle;
}

.confidential-checkbox {
  padding: 0.25rem 0.5rem;
}

:deep(.minimized-block) {
  z-index: 1;
  width: auto;
  height: auto;

  .modal-dialog {
    max-height: 40vh !important;
    position: fixed;
    right: 25px;
    bottom: 125px;
    max-width: 600px !important;
  }

  .modal-body {
    max-height: 40vh !important;
    overflow-y: scroll;
  }
}

:deep(.minus-block) {
  z-index: 1;
  width: auto;
  height: auto;

  .modal-header {
    background-color: #eeeeee;
    padding: 0.5rem;
    width: 25vw;

    h5 {
      line-height: 2;
    }
  }

  .modal-dialog {
    bottom: 0;
    margin-bottom: 0 !important;
    max-height: 40vh !important;
    position: fixed;
    right: 25px;
    max-width: 600px !important;
  }

  .modal-body {
    display: none;
  }

  .modal-footer {
    display: none;
  }
}

:deep(#modal-send-mail.minus-block + .modal-backdrop[id^='modal-send-mail']) {
  opacity: 0;
  width: auto;
  height: auto;
}

:deep(#modal-send-mail.minimized-block + .modal-backdrop[id^='modal-send-mail']) {
  opacity: 0;
  width: auto;
  height: auto;
}
</style>
